import _ from "lodash";
import { CustomPackagingsDocument, SelectedPackagingsDocument } from "./CustomTypes";
import { CAPSULES_TAB, CUSTOM_TAB, LIQUID_TAB, POWDER_TAB, SOFTGELS_TAB, TABLETS_TAB } from "./ConfiguratorTabs";
import configuratorUtils from "../../utils/configuratorUtils";

export class AdvancedPackagingFilter {
  private _packaging: Array<CustomPackagingsDocument>;
  private _packagingByType: any;

  constructor(packaging: Array<CustomPackagingsDocument>) {
    this._packaging = this.packagingCopy(packaging);
    this._packagingByType = this.getPackagingByTypes();
  }

  public set packaging(packaging: Array<CustomPackagingsDocument>) {
    this._packaging = this.packagingCopy(packaging);
    this._packagingByType = this.getPackagingByTypes();
  }

  /**
   * Creates a map with packaging divided by their type
   * @private
   * @returns a map with packaging type as key mapped to all packaging for that key
   */
  private getPackagingByTypes() {
    let packagingMap: any = {};
    for (let i = 0; i < this._packaging.length; i++) {
      const packaging = this._packaging[i];
      if (packaging.packaging_type in packagingMap) {
        packagingMap[packaging.packaging_type].push(packaging);
      } else {
        packagingMap[packaging.packaging_type] = [packaging];
      }
    }
    return packagingMap;
  }

  /**
   * Gets all packaging of a given type
   * @param type the packaging type
   * @private
   * @returns array with  all packaging of the given type
   */
  private getPackagingByType(type: string): Array<CustomPackagingsDocument> {
    return this._packagingByType[type] || [];
  }

  /**
   * Copies the objects of an array
   * @param packaging packaging array to copy
   * @private
   * @returns array with packaging copies
   */
  private packagingCopy(packaging: Array<CustomPackagingsDocument>) {
    return _.cloneDeep(packaging);
  }

  /**
   * Get filtered torso packaging based on active tab and preferences
   * @param activeTab the currently active tab
   * @param torsoSelection currently possible torso selection
   * @param preferences preferences object
   * @param recipeVolume (optional) object with volume info for the recipe selection
   * @returns filtered torso packaging
   */
  public getFilteredTorsoPackaging(
    activeTab: string,
    torsoSelection: Array<string>,
    preferences: any,
    recipeVolume?: { value: number; noDefault: boolean }
  ) {
    const { selectedCapsule, selectedTablet, amountPerUnit } = preferences;
    let torsoPackaging: Array<CustomPackagingsDocument> = [];
    for (let i = 0; i < torsoSelection.length; i++) {
      torsoPackaging = torsoPackaging.concat(this.getPackagingByType(torsoSelection[i]));
    }
    let filteredTorsoPackaging: Array<CustomPackagingsDocument> = torsoPackaging;
    if (recipeVolume && [POWDER_TAB, LIQUID_TAB].includes(activeTab))
      filteredTorsoPackaging = this.getTorsoPackagingPowderLiquid(torsoPackaging, recipeVolume);
    else if (activeTab === CAPSULES_TAB)
      filteredTorsoPackaging = this.getTorsoPackaging(torsoPackaging, selectedCapsule.capsule_volume, amountPerUnit);
    else if (activeTab === TABLETS_TAB)
      filteredTorsoPackaging = this.getTorsoPackaging(torsoPackaging, +selectedTablet.volume, amountPerUnit);
    return this.preferBiodegradable(filteredTorsoPackaging, activeTab);
  }

  /**
   * Prefer biodegradable bottles
   * @param packaging list of packaging
   * @param tab the active tab
   * @private
   * @returns packaging with biodegradable bottles moved to the front
   */
  private preferBiodegradable(packaging: Array<CustomPackagingsDocument>, tab: string) {
    // Get recommended bottles
    const recommendedPackaging = packaging
      .slice()
      .filter((x) => x.packaging_type === "bottle" && (x.recommended || [CUSTOM_TAB, SOFTGELS_TAB].includes(tab)));
    if (recommendedPackaging.length < 2) return packaging;
    for (let i = 0; i < recommendedPackaging.length; i++) {
      const pack = recommendedPackaging[i];
      if (pack.packaging_material === "recycleable") {
        const oldIndex = packaging.findIndex((p) => p._id.toString() === pack._id.toString());
        // Move packaging to the front
        packaging.splice(0, 0, packaging.splice(oldIndex, 1)[0]);
      }
    }
    return packaging;
  }

  /**
   * Filter torso based on the recipe volume of a liquid or powder product
   * @param torsoPackaging all possible torso packaging
   * @param recipeVolume object with volume and flag if density was known while computing the volume
   * @private
   * @returns filtered torso packaging
   */
  private getTorsoPackagingPowderLiquid(
    torsoPackaging: Array<CustomPackagingsDocument>,
    recipeVolume: { value: number; noDefault: boolean }
  ) {
    let filteredPackaging: Array<CustomPackagingsDocument> = [];
    // For bottle, liquidbottle and bag recommend packaging with smallest volume
    let recommendedMap: any = {
      bottle: Number.POSITIVE_INFINITY,
      liquidbottle: Number.POSITIVE_INFINITY,
      bag: Number.POSITIVE_INFINITY,
    };
    for (let i = 0; i < torsoPackaging.length; i++) {
      const torso = torsoPackaging[i];
      // tuple of boolean and number, 5% margin if every density was known otherwise 50% margin of error
      const comparison = configuratorUtils.compareTorsoAndVolumeWithMargin(
        torso,
        recipeVolume.value,
        recipeVolume.noDefault ? 0.05 : 0.5
      );
      if (comparison[0]) {
        const type = torso.packaging_type;
        filteredPackaging.push(torso);
        // Make sure that packaging that are within the lower limit but not higher than the actual value are shown, but not recommended
        if (recipeVolume.noDefault && recommendedMap[type] > comparison[1] && comparison[1] >= recipeVolume.value)
          recommendedMap[type] = comparison[1];
      }
    }
    // No recommendation if volume is not exact
    if (!recipeVolume.noDefault) return filteredPackaging;
    return this.setRecommended(filteredPackaging, (torso) => this.isTorsoRecommended(recommendedMap, torso));
  }

  /**
   * Filter torso packaging based on volume of selected capsule or tablet
   * @param torsoPackaging all possible torso packaging
   * @param volume volume of the capsule or tablet
   * @param amountPerUnit the amount of capsules
   * @private
   * @returns filtered torso packaging
   */
  private getTorsoPackaging(torsoPackaging: Array<CustomPackagingsDocument>, volume: number, amountPerUnit: string) {
    let filteredPackaging: Array<CustomPackagingsDocument> = [];
    // For bottle, liquidbottle and bag recommend packaging with smallest volume, for blister with highest capsule count
    let recommendedMap: any = {
      bottle: Number.POSITIVE_INFINITY,
      liquidbottle: Number.POSITIVE_INFINITY,
      bag: Number.POSITIVE_INFINITY,
      blister: 0,
    };
    for (let i = 0; i < torsoPackaging.length; i++) {
      const torso = torsoPackaging[i];
      // tuple of boolean and number
      const comparison = configuratorUtils.compareTorsoAndVolume(torso, volume, +amountPerUnit);
      if (comparison[0]) {
        const type = torso.packaging_type;
        filteredPackaging.push(torso);
        if (type !== "blister" && recommendedMap[type] > comparison[1]) recommendedMap[type] = comparison[1];
        else if (type === "blister" && recommendedMap[type] < comparison[1]) recommendedMap[type] = comparison[1];
      }
    }
    return this.setRecommended(filteredPackaging, (torso) => this.isTorsoRecommended(recommendedMap, torso));
  }

  /**
   * Get all suitable packaging for the next step including recommended ones
   * @param currentSelection the already selected packaging
   * @param nextSelection the packaging type to be selected next
   * @returns Array with all suitable packaging
   */
  public getFilteredPackaging(
    currentSelection: Array<SelectedPackagingsDocument>,
    nextSelection: string | Array<string>
  ) {
    if (!nextSelection) return [];
    // nextSelection may also be an array I guess
    if (Array.isArray(nextSelection)) {
      if (nextSelection.includes("lid") && nextSelection.includes("pipette")) {
        const lBottle = this.getSelectedByType(currentSelection, "liquidbottle");
        if (!lBottle) return [];
        return this.getLidPipetteForLiquidbottle(lBottle);
      }
      if (nextSelection.includes("label") && nextSelection.includes("multilayer_label"))
        return this.getLabels(currentSelection, true);
      return [];
    } else {
      switch (nextSelection) {
        case "lid":
          return this.getLids(currentSelection);
        case "label":
          return this.getLabels(currentSelection);
        case "box":
          return this.getBoxes(currentSelection);
        case "sleeve":
          return this.getSleeves(currentSelection);
        default:
          return [];
      }
    }
  }

  /**
   * Gets all suitable lids
   * @param currentSelection already selected packaging
   * @private
   * @return Array of lids
   */
  private getLids(currentSelection: Array<SelectedPackagingsDocument>) {
    const bottle = this.getSelectedByType(currentSelection, "bottle");
    if (bottle) return this.getLidForBottle(bottle);
    return [];
  }

  /**
   * Gets all suitable labels
   * @param currentSelection already selected packaging
   * @param includeMultilayer flag if multilayer labels should be included
   * @private
   * @return Array of labels
   */
  private getLabels(currentSelection: Array<SelectedPackagingsDocument>, includeMultilayer?: boolean) {
    // Types are exclusive
    const bottle = this.getSelectedByType(currentSelection, "bottle");
    if (bottle) return this.getLabelForBottle(bottle, includeMultilayer);
    const liquidbottle = this.getSelectedByType(currentSelection, "liquidbottle");
    if (liquidbottle) return this.getLabelForBottle(liquidbottle, includeMultilayer);
    const bag = this.getSelectedByType(currentSelection, "bag");
    if (bag) return this.getLabelForBag(bag, includeMultilayer);
    return [];
  }

  /**
   * Gets all suitable boxes
   * @param currentSelection already selected packaging
   * @private
   * @return Array of boxes
   */
  private getBoxes(currentSelection: Array<SelectedPackagingsDocument>) {
    // Types are exclusive
    const bottle = this.getSelectedByType(currentSelection, "bottle");
    if (bottle) return this.getBoxForBottle(bottle);
    const liquidbottle = this.getSelectedByType(currentSelection, "liquidbottle");
    if (liquidbottle) return this.getBoxForBottle(liquidbottle);
    const blister = this.getSelectedByType(currentSelection, "blister");
    if (blister) return this.getBoxForBlister(blister);
    return [];
  }

  /**
   * Gets all suitable sleeves
   * @param currentSelection already selected packaging
   * @private
   * @return Array of sleeves
   */
  private getSleeves(currentSelection: Array<SelectedPackagingsDocument>) {
    const lid = this.getSelectedByType(currentSelection, "lid");
    if (lid) return this.getSleeveForLid(lid);
    const pipette = this.getSelectedByType(currentSelection, "pipette");
    if (pipette) return this.getSleeveForPipette(pipette);
    return [];
  }

  /**
   * Get a packaging object of the given type from selected packaging
   * @param selectedPackaging already selected packaging
   * @param type the desired packaging type
   * @private
   * @returns found packaging object
   */
  private getSelectedByType(selectedPackaging: Array<SelectedPackagingsDocument>, type: string) {
    return selectedPackaging.find((pack) => pack.packaging_type === type);
  }

  /**
   * Get all lids that fit for the given bottle
   * @param bottle the bottle packaging to find matching lids for
   * @private
   * @returns Array of lids with recommended flag set for best suitable lids
   */
  private getLidForBottle(bottle: SelectedPackagingsDocument) {
    const lidPackaging = this.getPackagingByType("lid");
    const filteredLids = lidPackaging.filter((lid) =>
      configuratorUtils.compareNeckSize(lid.lid_size, bottle.packaging_neck)
    );
    return this.setRecommended(filteredLids, (lid) => this.isLidRecommended(bottle, lid));
  }

  /**
   * Get all sleeves that fit for the given lid
   * @param lid the lid packaging to find matching sleeves for
   * @private
   * @returns Array of sleeves with recommended flag set for best suitable sleeves
   */
  private getSleeveForLid(lid: SelectedPackagingsDocument) {
    const sleevePackaging = this.getPackagingByType("sleeve");
    const filteredSleeves = sleevePackaging.filter((sleeve) =>
      configuratorUtils.compareSleeveAndLid(sleeve.sleeve_size, lid.lid_size)
    );
    return this.setRecommended(filteredSleeves, () => true);
  }

  /**
   * Get all sleeves that fit for the given pipette
   * @param pipette the pipette packaging to find matching sleeves for
   * @private
   * @returns Array of sleeves with recommended flag set for best suitable sleeves
   */
  private getSleeveForPipette(pipette: SelectedPackagingsDocument) {
    const sleevePackaging = this.getPackagingByType("sleeve");
    const filteredSleeves = sleevePackaging.filter((sleeve) =>
      configuratorUtils.compareSleeveAndLid(sleeve.sleeve_size, pipette.packaging_neck)
    );
    return this.setRecommended(filteredSleeves, () => true);
  }

  /**
   * Get all labels that fit for the given bottle
   * @param bottle the bottle packaging to find matching labels for
   * @param includeMultilayer flag if multilayer labels should be included
   * @private
   * @returns Array of labels with recommended flag set for best suitable labels
   */
  private getLabelForBottle(bottle: SelectedPackagingsDocument, includeMultilayer?: boolean) {
    let labelPackaging = this.getPackagingByType("label");
    if (includeMultilayer) labelPackaging = labelPackaging.concat(this.getPackagingByType("multilayer_label"));
    const maxLabelHeight = bottle.packaging_label_height;
    const diameter = bottle.packaging_width;
    let filteredLabels: Array<CustomPackagingsDocument> = [];
    let biggestArea = 0;
    for (let i = 0; i < labelPackaging.length; i++) {
      const label = labelPackaging[i];
      if (configuratorUtils.compareLabelAndBottle(label.label_width, label.label_height, maxLabelHeight, diameter)) {
        filteredLabels.push(label);
        // compute area
        const area = configuratorUtils.getLabelArea(label);
        if (area > biggestArea) biggestArea = area;
      }
    }
    // Set recommended according to biggest area
    return this.setRecommended(filteredLabels, (label) => configuratorUtils.getLabelArea(label) === biggestArea);
  }

  /**
   * Get all boxes that fit the given bottle
   * @param bottle the bottle packaging to find matching boxes for
   * @private
   * @returns Array of boxes with recommended flag set for best suitable boxes
   */
  private getBoxForBottle(bottle: SelectedPackagingsDocument) {
    const boxPackaging = this.getPackagingByType("box");
    const bottleDimension = [bottle.packaging_width, bottle.packaging_height];
    let filteredBoxes = [];
    let smallestVolume = Number.POSITIVE_INFINITY;
    for (let i = 0; i < boxPackaging.length; i++) {
      const box = boxPackaging[i];
      const boxDimension = [box.box_width, box.box_height, box.box_depth];
      if (configuratorUtils.compareBottleAndBox(boxDimension, bottleDimension)) {
        filteredBoxes.push(box);
        // compute volume
        const volume = configuratorUtils.getBoxVolume(box);
        if (volume < smallestVolume) smallestVolume = volume;
      }
    }
    // Recommend smallest volume box
    return this.setRecommended(filteredBoxes, (box) => configuratorUtils.getBoxVolume(box) === smallestVolume);
  }

  /**
   * Get all lids and pipettes that fit the given liquidbottle
   * @param liquidbottle the liquid bottle packaging to find matching lids and pipettes for
   * @private
   * @returns Array of lids/pipettes with recommended flag set for best suitable lids and pipettes
   */
  private getLidPipetteForLiquidbottle(liquidbottle: SelectedPackagingsDocument) {
    let packaging: Array<CustomPackagingsDocument> = this.getLidForBottle(liquidbottle);
    const pipettePackaging = this.getPackagingByType("pipette");
    let filteredPipettes = [];
    let longestHeight = "0";
    for (let i = 0; i < pipettePackaging.length; i++) {
      const pipette = pipettePackaging[i];
      if (
        configuratorUtils.comparePipetteAndBottle(
          pipette.packaging_neck,
          pipette.packaging_height,
          liquidbottle.packaging_neck,
          liquidbottle.packaging_height
        )
      ) {
        filteredPipettes.push(pipette);
        if (pipette.packaging_height && +pipette.packaging_height > +longestHeight)
          longestHeight = pipette.packaging_height;
      }
    }
    //TODO Recommendation for pipettes and additional checks: SC-164
    return packaging
      .concat(this.setRecommended(filteredPipettes, (pipette) => pipette.packaging_height === longestHeight))
      .sort((a, b) => +!!b.recommended - +!!a.recommended);
  }

  /**
   * Get all box that fit for the given blister
   * @param blister the blister packaging to find matching boxes for
   * @private
   * @returns Array of boxes with recommended flag set for best suitable boxes
   */
  private getBoxForBlister(blister: SelectedPackagingsDocument) {
    const boxPackaging = this.getPackagingByType("box");
    const amount = blister.amount ? Number(blister.amount) : 1;
    const blisterDimension = [blister.blister_width, blister.blister_height, blister.blister_depth];
    let filteredBoxes = [];
    let smallestVolume = Number.POSITIVE_INFINITY;
    for (let i = 0; i < boxPackaging.length; i++) {
      const box = boxPackaging[i];
      const boxDimension = [box.box_width, box.box_height, box.box_depth];
      if (configuratorUtils.compareBlisterAndBox(amount, boxDimension, blisterDimension)) {
        filteredBoxes.push(box);
        // compute volume
        const volume = configuratorUtils.getBoxVolume(box);
        if (volume < smallestVolume) smallestVolume = volume;
      }
    }
    // Recommend smallest volume box
    return this.setRecommended(filteredBoxes, (box) => configuratorUtils.getBoxVolume(box) === smallestVolume);
  }

  /**
   * Get all labels that fit for the given bag
   * @param bag the bag packaging to find matching labels for
   * @param includeMultilayer flag if multilayer labels should be included
   * @private
   * @returns Array of labels with recommended flag set for best suitable labels
   */
  private getLabelForBag(bag: SelectedPackagingsDocument, includeMultilayer?: boolean) {
    let labelPackaging = this.getPackagingByType("label");
    if (includeMultilayer) labelPackaging = labelPackaging.concat(this.getPackagingByType("multilayer_label"));
    let filteredLabels: Array<CustomPackagingsDocument> = [];
    let biggestArea = 0;
    for (let i = 0; i < labelPackaging.length; i++) {
      const label = labelPackaging[i];
      if (configuratorUtils.compareLabelAndBag(label.label_width, label.label_height, bag.bag_width, bag.bag_height)) {
        filteredLabels.push(label);
        // compute area
        const area = configuratorUtils.getLabelArea(label);
        if (area > biggestArea) biggestArea = area;
      }
    }
    // Set recommended according to biggest area
    return this.setRecommended(filteredLabels, (label) => configuratorUtils.getLabelArea(label) === biggestArea);
  }

  /**
   * Set the recommended flag if suitable for packaging objects
   * @param filteredPackaging the filtered packaging to check for recommendations
   * @param conditionFunction a function to determine whether a packaging is recommended or not
   * @private
   * @returns Array with same packaging as filteredPackaging but with recommended flag set
   */
  private setRecommended(
    filteredPackaging: Array<CustomPackagingsDocument>,
    conditionFunction: (packaging: CustomPackagingsDocument) => boolean
  ) {
    let packaging = [];
    for (let i = 0; i < filteredPackaging.length; i++) {
      const copy = { ...filteredPackaging[i] };
      if (conditionFunction(copy)) {
        copy.recommended = true;
        // Add recommended up front
        packaging.unshift(copy);
      } else packaging.push(copy);
    }
    return packaging;
  }

  /**
   * Checks if torso is recommend
   * @param recommendedMap map containing the best value for each torso packaging type
   * @param torso the torso packaging object to check
   * @private
   * @returns True if torso is recommended, else False
   */
  private isTorsoRecommended(recommendedMap: any, torso: CustomPackagingsDocument) {
    const type = torso.packaging_type;
    const recommendedValue = recommendedMap[type];
    let comparisonValue;
    if (["bottle", "liquidbottle"].includes(type)) comparisonValue = torso.packaging_volume;
    else if (type === "bag") comparisonValue = torso.bag_volume;
    else if (type === "blister") comparisonValue = torso.blister_capsules;
    if (!comparisonValue) return false;
    return comparisonValue.toString() === recommendedValue.toString();
  }

  /**
   * Checks if lid is recommended for the bottle
   * @param bottle the bottle packaging object
   * @param lid the lid packaging object
   * @private
   * @returns True if lid is recommended, else False
   */
  private isLidRecommended(bottle: SelectedPackagingsDocument, lid: CustomPackagingsDocument) {
    // Add additional cases if required
    return bottle.packaging_color === lid.lid_color;
  }

  private checkAllSelection() {
    // TODO function to check if all selection makes sense and return errors if not SC-167
  }
}
