import React from "react";

interface CustomPreferencesProps {
  t: (key: string, options?: any) => string;
  preferences: any;
  onPreferenceChange: (path: string, item: any) => void;
}

const CustomPreferences: React.FunctionComponent<CustomPreferencesProps> = React.memo(props => {
  const { t, preferences, onPreferenceChange } = props;

  return (
    <>
      <span className="text-dark-75 font-size-h3 font-weight-bold mr-3">{t("preferences")}</span>
      <div className="form-group row mt-10" id="customPreferences">
        <div className="col-lg-4">
          <label><b>{t("perUnit")}</b></label>
          <div className="input-group">
            <input
              type="number"
              min="0"
              className="form-control"
              id="perUnit"
              value={preferences.amountPerUnit}
              onChange={e => {
                onPreferenceChange("amountPerUnit", Number(parseInt(e.target.value) || "0").toString());
              }}
            />
            <div className="input-group-append">
              <span className="input-group-text">{t("pieces")}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default CustomPreferences;
