import React, { Component, useState } from "react";
import { withTranslation } from "react-i18next";
import Map, { Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from 'mapbox-gl';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

class MapSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePopup: null,
      zoom: 5,
      mapVisible: false,
    };
  }
  showPopup = (pin) => {
    console.log("showing......");
    this.setState({ activePopup: pin });
  };

  hidePopup = async () => {
    await this.sleep(2000)
    this.setState({ activePopup: null });
  };
  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    const darkSection = document.getElementById("map");

    if (darkSection) {
      const darkTop = darkSection.getBoundingClientRect().top;
      const darkBottom = darkSection.getBoundingClientRect().bottom;
      const windowHeight = window.outerHeight;

      if (darkTop < windowHeight && darkBottom > 0) {
        const zoom = 4.5 + darkTop * 0.0007;

        this.setState({ zoom, mapVisible: true });
      } else {
        this.setState({ mapVisible: false });
      }
    }
  };

  render() {
    const { t } = this.props;
    const { activePopup, viewPort } = this.state;

    const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

    const pinStyle = {
      cursor: "pointer",
      fill: "#d00",
      stroke: "none",
    };

    const icon = (
      <svg height={20} viewBox="0 0 24 24" style={pinStyle}>
        <path d={ICON} />
      </svg>
    );

    const pins = [
      {
        longitude: 9.99661,
        latitude: 48.39962,
        title:  t("update22"),
        text: t("update23"),
        country: "de",
      },
      {
        longitude: 7.197945,
        latitude: 51.614063,
        title:  t("update24"),
        text:  t("update25"),
        country: "de",
      },
      {
        longitude: 8.80708,
        latitude: 53.074982,
        title: t("update26"),
        text: t("update27"),
        country: "de",
      },
      {
        longitude: 14.1317985,
        latitude: 50.5340795,
        title: t("update28"),
        text: t("update29"),
        country: "cs",
      },
      {
        longitude: 7.6087847,
        latitude: 49.1996961,
        title: t("update30"),
        text: t("update31"),
        country: "de",
      },
      {
        longitude: 18.0710935,
        latitude: 59.3251172,
        title: t("update32"),
        text: t("update33"),
        country: "sv",
      },
      {
        longitude: 15.1435463,
        latitude: 59.1272047,
        title:t("update34"),
        text: t("update35"),
        country: "sv",
      },
      {
        longitude: 6.0230325,
        latitude: 51.9470218,
        title: t("update36"),
        text: t("update37"),
        country: "nl",
      },
      {
        longitude: 9.1896346,
        latitude: 45.4641943,
        title: t("update38"),
        text: t("update39"),
        country: "it",
      },
    ];

    return (
      <React.Fragment>
        <section
          className="p-0 bg-extra-dark-gray wow animate__fadeIn"
          style={{ visibility: "visible", animationName: "fadeIn" }}
          id="map"
        >
          <Map
            mapLib={import("mapbox-gl")}
            initialViewState={{
              bearing: 0,
              pitch: 50,
              longitude: 9.9077112,
              latitude: 50,
              zoom: 5,
            }}
            viewState={{ zoom: this.state.zoom }}
            scrollZoom={false}
            boxZoom={false}
            dragRotate={false}
            dragPan={false}
            keyboard={false}
            doubleClickZoom={false}
            touchZoomRotate={false}
            touchPitch={false}
            style={{ width: "100vw", height: 800 }}
            mapStyle="mapbox://styles/mapbox/dark-v9"
            mapboxAccessToken="pk.eyJ1IjoianVsaWFuaGFhZyIsImEiOiJjbHFmOTdtMHMwcnIwMmtvNjdjZWZoNW90In0.BODjUSsV1xwc9jva6dBr3g"
          >
            {pins.map((pin) => (
              <Marker
                key={pin.title} // Sie sollten einen eindeutigen Schlüssel hier hinzufügen
                longitude={pin.longitude}
                latitude={pin.latitude}
              >
                <svg
                  height={20}
                  viewBox="0 0 24 24"
                  style={pinStyle}
                  onMouseEnter={() => this.showPopup(pin)}
                  onMouseLeave={() => this.hidePopup()}
                >
                  <path d={ICON} />
                </svg>
              </Marker>
            ))}

            {activePopup && (
              <Popup
                latitude={activePopup.latitude}
                longitude={activePopup.longitude}
                closeButton={false}
                closeOnClick={false}
                offsetTop={-30}
              >
                <h6 className="text-extra-medium text-extra-dark-gray font-weight-500 mb-0 d-block w-100">
                  {activePopup.title}{" "}
                  <img
                    src={"/images/countries_128/"+activePopup.country + ".png"}
                    className="fab mr-2 float-right"
                    style={{ width: 20 }}
                    data-toggle="dropdown"
                    aria-hidden="true"
                    alt=""
                  ></img>
                </h6>

                <span className="text-medium-gray">{activePopup.text}</span>
              </Popup>
            )}
          </Map>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(MapSection);
