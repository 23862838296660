import countryList from "i18n-iso-countries";
import { Types } from "mongoose";
import languageUtils, { language } from "./languageUtils";
import { CapsulesDocument } from "../model/capsules.types";
import { CustomPackagingsDocument, SelectedPackagingsDocument } from "../components/configurator/CustomTypes";
import { TabletsDocument } from "../model/tablets.types";
import {
  CAPSULES_TAB,
  CUSTOM_TAB,
  LIQUID_TAB,
  POWDER_TAB,
  SOFTGELS_TAB,
  TABLETS_TAB,
} from "../components/configurator/ConfiguratorTabs";
import _ from "lodash";
import { PACKAGINGCOMBINATIONS, TORSOSELECTION } from "../components/configurator/configuratorDataFilter";
// register languages
countryList.registerLocale(require("i18n-iso-countries/langs/de.json"));
countryList.registerLocale(require("i18n-iso-countries/langs/en.json"));
// averages of current values
const DENSITY_DEFAULTS: any = {
  powder: 0.5,
  oil: 0.93,
  beadlet: 0.85,
  concentrates: 0.8,
  granules: 0.8,
};

// safety distance 2 mm
const LABELSAFETYDISTANCE = 2;

/**
 * Concat all useful information about the given packaging
 * @param packaging the packaging object
 * @param t i18n translation function
 * @param separator for join function
 * @returns string with useful information concatenated
 */
function concatPackagingInfo(
  packaging: CustomPackagingsDocument,
  t: (key: string, options?: any) => string,
  separator = " "
): string {
  let info: Array<string | undefined> = [];
  // Wrapper function for translation with namespace packaging
  const pt = (key: string) => t("packaging:" + key);
  switch (packaging.packaging_type) {
    case "bottle":
      info = [
        pt(packaging.packaging_shape!),
        packaging.packaging_volume + " ml",
        packaging.packaging_neck,
        pt(packaging.packaging_material!),
        pt(packaging.packaging_color!),
      ];
      break;
    case "liquidbottle":
      info = [`${packaging.packaging_volume} ml`, pt(packaging.packaging_material!), pt(packaging.packaging_color!)];
      break;
    case "box":
      info = [
        `${packaging.box_width} x ${packaging.box_height} x ${packaging.box_depth} mm (${t("configurator:wxhxd")})`,
        pt(packaging.box_quality!),
      ];
      break;
    case "lid":
      info = [
        pt(packaging.lid_shape!),
        Array.from(new Set([pt(packaging.lid_material!), pt(packaging.lid_color!)])).join(separator),
        packaging.lid_size,
      ];
      break;
    case "bag":
      info = [
        pt(packaging.bag_shape!),
        pt(packaging.bag_material!),
        pt(packaging.bag_color!),
        `${packaging.bag_width} x ${packaging.bag_height} mm (${t("configurator:wxh")}), ${packaging.bag_volume} ml`,
        pt(packaging.bag_zipper!),
      ];
      break;
    case "blister":
      info = [
        `${packaging.blister_width} x ${packaging.blister_height} x ${packaging.blister_depth} mm (${t(
          "configurator:wxhxd"
        )})`,
        packaging.blister_capsules,
      ];
      break;
    case "label":
    case "multilayer_label":
      info = [
        pt(packaging.label_quality!),
        `${packaging.label_width} x ${packaging.label_height} mm (${t("configurator:wxh")})`,
      ];
      break;
    case "sleeve":
      info = [`${packaging.sleeve_size} mm`, packaging.packaging_material, packaging.packaging_color];
      break;
    case "pipette":
      info = [packaging.packaging_height + " mm", packaging.packaging_neck, pt(packaging.packaging_color!)];
      break;
  }
  // filter undefined entries
  info = info.filter((entry) => {
    return entry !== undefined;
  });
  info.unshift(pt(packaging.packaging_type));
  // return concatenated string
  return info.join(separator);
}

/**
 * Get the correct settings type for the given tab
 * @param tab currently active tab
 * @returns correct order settings type
 */
function getTypeForTab(tab: string) {
  switch (tab) {
    case CAPSULES_TAB:
      return "Capsule";
    case TABLETS_TAB:
      return "Tablet";
    case POWDER_TAB:
      return "Powder";
    case LIQUID_TAB:
      return "Liquid";
    case CUSTOM_TAB:
      return "Custom";
    case SOFTGELS_TAB:
      return "Softgel";
  }
}

/**
 * Get the correct tab for given type
 * @param type the order settings type
 * @returns the corresponding tab
 */
function getTabForType(type: string) {
  switch (type) {
    case "capsule":
      return CAPSULES_TAB;
    case "tablet":
      return TABLETS_TAB;
    case "powder":
      return POWDER_TAB;
    case "liquid":
      return LIQUID_TAB;
    case "custom":
      return CUSTOM_TAB;
    case "softgel":
      return SOFTGELS_TAB;
    default:
      return CAPSULES_TAB;
  }
}

/**
 * Builds a preference description
 * @param preferences the preferences object
 * @param tab currently active tab
 * @param t translation function
 * @returns a proper description of the preference data
 */
function getPreferencesDescription(
  preferences: any,
  tab: string,
  t: (key: string, options?: any) => string
): [string, string, string] {
  let ret: [string, string, string] = [preferences.title, preferences.subtitle, ""];
  const perUnit = preferences.amountPerUnit;
  switch (tab) {
    case CAPSULES_TAB:
      const capsule = preferences.selectedCapsule;
      ret[2] = `${perUnit} x ${buildCapsuleString(capsule)}`;
      break;
    case TABLETS_TAB:
      const tablet = preferences.selectedTablet;
      ret[2] = `${perUnit} x ${buildTabletString(tablet, t)}`;
      break;
    case CUSTOM_TAB:
    case SOFTGELS_TAB:
      ret[2] = `${perUnit} ${t("piecesPerUnit")}`;
      break;
  }
  return ret;
}

/**
 * Build a string to display capsule information
 * @param capsule the capsule object
 * @returns a formatted string with capsule data
 */
function buildCapsuleString(capsule: CapsulesDocument) {
  return `${capsule.capsule_size} - ${languageUtils.resolveTranslation(
    capsule.capsule_material
  )} (${languageUtils.resolveTranslation(capsule.capsule_color)})`;
}

/**
 * Build a string to display tablet information
 * @param tablet the tablet object
 * @param t i18n translate function
 * @returns a formatted string with tablet data
 */
function buildTabletString(tablet: TabletsDocument, t: (key: string, options?: any) => string) {
  return `${t(tablet.shape)} - ${t("volume")}: ${tablet.volume}ml`;
}

/**
 * Generates a random internal id
 * @param length: desired length of id
 * @returns random string
 */
function generateInternalId(length: number) {
  return Math.random().toString(36).substr(2, length);
}

/**
 * Converts the amount given as a string from one unit to another
 * @param amount the amount to convert as a string
 * @param from the current unit
 * @param to the target unit
 * @returns the converted amount
 */
function convertAmount(amount: string, from: string, to: string) {
  // Don't do any conversion if parsing would result in 0, e.g. 0,0... to not lose the decimals
  if (+amount === 0) return amount;
  const conc = from + to;
  switch (conc) {
    case "ugmg":
    case "mgg":
    case "gkg":
      return (+amount / 1000).toString();
    case "mgug":
    case "gmg":
    case "kgg":
      return (+amount * 1000).toString();
    case "kgmg":
    case "gug":
      return (+amount * 1000 * 1000).toString();
    case "mgkg":
    case "ugg":
      return (+amount / (1000 * 1000)).toString();
    case "ugkg":
      return (+amount / (1000 * 1000 * 1000)).toString();
    case "kgug":
      return (+amount * 1000 * 1000 * 1000).toString();
    default:
      return amount;
  }
}

/**
 * Searches and filters the given commodities with the given search query
 * @param commodities Array of commodities to filter
 * @param searchQuery Search query
 * @returns filtered array of commodities
 */
function doCommoditySearch(commodities: Array<any>, searchQuery: string) {
  return commodities.filter((entry) => {
    return (
      languageUtils.resolveTranslation(entry.title)?.toLowerCase().includes(searchQuery) ||
      languageUtils.resolveTranslation(entry.subtitle)?.toLowerCase().includes(searchQuery) ||
      languageUtils.resolveTranslation(entry.category)?.toLowerCase().includes(searchQuery) ||
      entry.properties.some((prop: any) =>
        languageUtils.resolveTranslation(prop)?.toLowerCase().includes(searchQuery)
      ) ||
      (entry.country && countryList.getName(entry.country, language())?.toLowerCase().includes(searchQuery))
    );
  });
}

/**
 * Builds a string with the amount and the biggest suitable unit
 * @param amountInMg: The amount in milligrams
 * @param toFixed: Number of digits after the decimal point
 * @returns string with the amount and the best suitable unit
 */
function formatAmount(amountInMg: string, toFixed?: number) {
  const amountNum = Number(amountInMg);
  let tuple = [amountInMg, "mg"];
  if (amountNum >= 1000 * 1000) tuple = [convertAmount(amountInMg, "mg", "kg"), "kg"];
  else if (amountNum >= 1000) tuple = [convertAmount(amountInMg, "mg", "g"), "g"];
  else if (amountNum < 1) tuple = [convertAmount(amountInMg, "mg", "ug"), "\u00b5g"];

  if (toFixed) tuple[0] = parseFloat((+tuple[0]).toFixed(toFixed)).toString();
  return tuple.join(" ");
}

/**
 * Converts the amount to mg and formats it to the biggest suitable unit
 * @param amount: The amount to convert as a string
 * @param from: The unit the amount currently is
 * @param toFixed: Number of digits after the decimal point to be displayed
 * @returns string with converted and formatted amount and the best suitable unit
 */
function convertAndFormatAmount(amount: string, from: string, toFixed?: number) {
  const convertedAmount = convertAmount(amount, from, "mg");
  return formatAmount(convertedAmount, toFixed);
}

/**
 * Gets a document from a given collection
 * @param collection the collection to search
 * @param id the objectId to search for
 * @returns found document or null
 */
function getDocFromCollection(collection: Array<any>, id: Types.ObjectId) {
  for (let doc of collection) {
    if (doc._id.toString() === id.toString()) return doc;
  }
  return null;
}

/**
 * Gets possible torso types for current product selection (tab + preferences)
 * @param tab the current tab
 * @param preferences preferences object
 * @returns Array with packaging type that are valid for the given product
 */
function getTorsoTypeForProduct(tab: string, preferences: any) {
  const torsoFilter = _.get(TORSOSELECTION, tab);
  let types = [...torsoFilter.baseTypes];
  // Resolve additional types
  if (torsoFilter.additionalTypes) {
    for (let i = 0; i < torsoFilter.additionalTypes.length; i++) {
      const additionalType = torsoFilter.additionalTypes[i];
      if (additionalType.preference && additionalType.preference.length === 2) {
        let fulfilled = additionalType.preference[1].includes(_.get(preferences, additionalType.preference[0])!);
        if (fulfilled) types = types.concat(additionalType.types);
      }
    }
  }
  return types;
}

/**
 * Get the mandatory types for a given torso type
 * @param packagingType packaging type of torso
 * @returns Array with mandatory types for the given torso
 */
function getMandatoryTypesForTorso(packagingType: string) {
  return _.get(PACKAGINGCOMBINATIONS, packagingType).mandatory;
}

/**
 * Checks if all mandatory types are within the selected packaging
 * @param mandatoryTypes the mandatory packaging types
 * @param selectedPackaging the selected packaging to check
 * @returns True if all mandatory types are already selected, else False
 */
function isMandatorySelected(
  mandatoryTypes: Array<string | Array<string>>,
  selectedPackaging: Array<SelectedPackagingsDocument>
) {
  let result = true;
  for (let type of mandatoryTypes) {
    if (Array.isArray(type)) result = result && selectedPackaging.some((entry) => type.includes(entry.packaging_type));
    else result = result && selectedPackaging.some((entry) => entry.packaging_type === type);
  }
  return result;
}

/**
 * Gets default density for a given composition
 * @param form the composition of the commodity
 * @returns default density
 */
function getDefaultDensity(form: string) {
  return form in DENSITY_DEFAULTS ? DENSITY_DEFAULTS[form] : 0.5;
}

/**
 * Computes the volume for a given extended commodity
 * @param commodity extended commodity object with amount and transformed form property
 * @returns tuple with the computed volume and flag if a density default was used or not
 */
function getVolume(commodity: any): [number, boolean] {
  //default values for each commodity type may differ
  const densityExists = !!commodity.density;
  const density = densityExists ? commodity.density : getDefaultDensity(commodity.form.en);
  // unit of density is g/ccm unit of amount is mg
  return [commodity.amount / 1000 / density, densityExists];
}

/**
 * Computes the volume for a given recipe selection
 * @param commodities List of extended commodity objects representing a recipe
 * @returns object with volume and flag if no defaults were used or not
 */
function getRecipeVolume(commodities: Array<any>) {
  let volume = 0;
  let noDefault = true;
  for (let i = 0; i < commodities.length; i++) {
    let [tmpVolume, tmpFlag] = getVolume(commodities[i]);
    volume += tmpVolume;
    noDefault = noDefault && tmpFlag;
  }
  return { value: volume, noDefault };
}

/**
 * Checks if a torso packaging fits for current volume or amount per unit
 * @param packaging the torso packaging object to check
 * @param volume volume of capsule or tablet
 * @param amountPerUnit number of capsules or tablets
 * @return tuple with True/False if packaging fits and the capacity of the packaging
 */
function compareTorsoAndVolume(
  packaging: CustomPackagingsDocument,
  volume: number,
  amountPerUnit: number
): [boolean, number] {
  const requiredVolume = volume * +amountPerUnit * 2;
  if (["bottle", "liquidbottle"].includes(packaging.packaging_type))
    return [+packaging.packaging_volume! >= requiredVolume, +packaging.packaging_volume!];
  else if (packaging.packaging_type === "bag")
    return [+packaging.bag_volume! >= requiredVolume, +packaging.bag_volume!];
  else if (packaging.packaging_type === "blister")
    return [amountPerUnit % +packaging.blister_capsules! === 0, +packaging.blister_capsules!];
  else return [false, -1];
}

/**
 * Checks if a torso packaging fits for the given volume with respect to a given margin
 * @param packaging the torso packaging object to check
 * @param volume volume of the recipe
 * @param margin margin of error to use for a lower limit
 * @returns tuple with True/False if packaging fits and the capacity of the packaging
 */
function compareTorsoAndVolumeWithMargin(
  packaging: CustomPackagingsDocument,
  volume: number,
  margin: number
): [boolean, number] {
  const lowerLimit = volume * (1 - margin);
  if (["bottle", "liquidbottle"].includes(packaging.packaging_type))
    return [+packaging.packaging_volume! >= lowerLimit, +packaging.packaging_volume!];
  else if (packaging.packaging_type === "bag") return [+packaging.bag_volume! >= lowerLimit, +packaging.bag_volume!];
  else return [false, -1];
}

/**
 * Checks if a bottle fits inside a box
 * @param boxDimension Triple of Width, Height, Depth
 * @param bottleDimension Tuple of Width, Height
 * @returns True if bottle fits, else False
 */
function compareBottleAndBox(boxDimension: Array<string | undefined>, bottleDimension: Array<string | undefined>) {
  if (boxDimension.some((entry) => entry === undefined) || bottleDimension.some((entry) => entry === undefined))
    return false;
  const [boxWidth, boxHeight, boxDepth] = boxDimension.map((x) => Number(x));
  const [bottleWidth, bottleHeight] = bottleDimension.map((x) => Number(x));
  // No safety distance for now
  return boxWidth >= bottleWidth && boxHeight >= bottleHeight && boxDepth >= bottleWidth;
}

/**
 * Checks if selected blister fits inside box
 * @param amount the amount of the blister
 * @param boxDimension Triple of Width, Height, Depth
 * @param blisterDimension Triple of Width, Height, Depth
 * @function
 * @returns True if blisters fit inside the box
 */
function compareBlisterAndBox(
  amount: number,
  boxDimension: Array<string | undefined>,
  blisterDimension: Array<string | undefined>
) {
  if (boxDimension.some((entry) => entry === undefined) || blisterDimension.some((entry) => entry === undefined))
    return false;
  const [boxWidth, boxHeight, boxDepth] = boxDimension.map((x) => Number(x));
  const [blisterWidth, blisterHeight, blisterDepth] = blisterDimension.map((x) => Number(x));
  // Use amount to multiply with depth, e.g. blister with amount 3 and 10 depth obv. needs at least box of 30 depth
  return boxWidth >= blisterWidth && boxHeight >= blisterHeight && boxDepth >= blisterDepth * amount;
}

/**
 * Checks if pipette fits on top of the bottle
 * @param pipetteNeck pipette neck size
 * @param pipetteHeight pipette length
 * @param bottleNeck bottle neck size
 * @param bottleHeight bottle height
 * @returns True if pipette fits as a closure for the bottle
 */
function comparePipetteAndBottle(
  pipetteNeck?: string,
  pipetteHeight?: string,
  bottleNeck?: string,
  bottleHeight?: string
) {
  if (!pipetteNeck || !pipetteHeight || !bottleNeck || !bottleHeight) return false;
  const pHeight = Number(pipetteHeight);
  const bHeight = Number(bottleHeight);
  return compareNeckSize(pipetteNeck, bottleNeck) && pHeight <= bHeight;
}

/**
 * Checks if label fits onto the bag
 * @param labelWidth label width
 * @param labelHeight label height
 * @param bagWidth bag width
 * @param bagHeight bag height
 * @returns True if label fits, else False
 */
function compareLabelAndBag(labelWidth?: string, labelHeight?: string, bagWidth?: string, bagHeight?: string) {
  if (!labelWidth || !labelHeight || !bagHeight || !bagWidth) return false;
  const lWidth = Number(labelWidth);
  const lHeight = Number(labelHeight);
  const bWidth = Number(bagWidth);
  const bHeight = Number(bagHeight);
  // check if it fits either horizontally or vertically
  return (bWidth > lWidth && bHeight > lHeight) || (bWidth > lHeight && bHeight > lWidth);
}

/**
 * Checks if label fits onto bottle
 * @param labelWidth label width
 * @param labelHeight label height
 * @param maxLabelHeight max allowed label height of bottle
 * @param diameter diameter of bottle
 * @returns True if label fits onto bottle, else False
 */
function compareLabelAndBottle(labelWidth?: string, labelHeight?: string, maxLabelHeight?: string, diameter?: string) {
  if (!labelWidth || !labelHeight || !maxLabelHeight || !diameter) return false;
  // parse values
  const lWidth = Number(labelWidth);
  const lHeight = Number(labelHeight);
  const maxLHeight = Number(maxLabelHeight);
  const dia = Number(diameter);
  return dia * Math.PI - LABELSAFETYDISTANCE > lWidth && lHeight <= maxLHeight;
}

/**
 * Checks if sleeve fits for lit
 * @param sleeveSize sleeve size
 * @param lidSize lid neck size
 * @returns True if sleeve fits for label, else False
 */
function compareSleeveAndLid(sleeveSize?: string, lidSize?: string) {
  if (!sleeveSize || !lidSize) return false;
  // check if diameter matches
  return lidSize.split("-")[0].trim() === sleeveSize.trim();
}

/**
 * Checks if two neck sizes fit together
 * @param sizeA first neck size
 * @param sizeB second neck size
 * @returns True if necks fit together, else False
 */
function compareNeckSize(sizeA?: string, sizeB?: string) {
  if (!sizeA || !sizeB) return false;
  const splitA = sizeA.split("-");
  const splitB = sizeB.split("-");
  // compare diameter and thread
  return splitA[0].trim() === splitB[0].trim() && splitA[1].trim() === splitB[1].trim();
}

/**
 * Computes the area of a label
 * @param label the label packaging object
 * @returns computed area of the label
 */
function getLabelArea(label: CustomPackagingsDocument) {
  return Number(label.label_width) * Number(label.label_height);
}

/**
 * Compute the volume of a box
 * @param box the box packaging object
 * @returns computed volume of the box
 */
function getBoxVolume(box: CustomPackagingsDocument) {
  return Number(box.box_height) * Number(box.box_width) * Number(box.box_depth);
}

// eslint-disable-next-line
export default {
  buildCapsuleString,
  buildTabletString,
  compareBlisterAndBox,
  compareBottleAndBox,
  compareLabelAndBag,
  compareLabelAndBottle,
  compareNeckSize,
  comparePipetteAndBottle,
  compareSleeveAndLid,
  compareTorsoAndVolume,
  compareTorsoAndVolumeWithMargin,
  concatPackagingInfo,
  convertAmount,
  convertAndFormatAmount,
  doCommoditySearch,
  formatAmount,
  generateInternalId,
  getBoxVolume,
  getDocFromCollection,
  getLabelArea,
  getMandatoryTypesForTorso,
  getPreferencesDescription,
  getRecipeVolume,
  getTabForType,
  getTorsoTypeForProduct,
  getTypeForTab,
  getVolume,
  isMandatorySelected,
};
