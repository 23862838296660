import { CAPSULES_TAB, CUSTOM_TAB, LIQUID_TAB, POWDER_TAB, SOFTGELS_TAB, TABLETS_TAB } from "./ConfiguratorTabs";

/**
 * PACKAGING FILTER
 */
export const PACKAGINGTYPES = [
  "bottle",
  "bag",
  "blister",
  "box",
  "liquidbottle",
  "pipette",
  "sleeve",
  "multilayer_label",
  "label",
  "lid",
];

export const TORSOSELECTION: any = {
  [CAPSULES_TAB]: {
    baseTypes: ["bottle", "bag"],
    additionalTypes: [
      {
        preference: ["selectedCapsule.capsule_size", ["0", "00", "1"]],
        types: ["blister"],
      },
    ],
  },
  [TABLETS_TAB]: { baseTypes: ["bottle"] },
  [POWDER_TAB]: { baseTypes: ["bottle", "bag"] },
  [LIQUID_TAB]: { baseTypes: ["liquidbottle"] },
  [SOFTGELS_TAB]: { baseTypes: ["bottle", "bag"] },
  [CUSTOM_TAB]: { baseTypes: [] },
};

export const PACKAGINGCOMBINATIONS: any = {
  bottle: { mandatory: ["lid"], optional: ["sleeve", ["label", "multilayer_label"], "box"] },
  bag: { mandatory: [], optional: ["label"] },
  blister: { mandatory: ["box"], optional: [] },
  // List in list means that it is either or, otherwise it is and
  liquidbottle: { mandatory: [["lid", "pipette"]], optional: ["sleeve", ["label", "multilayer_label"], "box"] },
};

/**
 * COMMODITY FILTER
 */

// Filter object for each tab with filter definitions for which items are included and which are excluded.
// Filter definitions are a tuple with path and an array of values to include or exclude
export const COMMODITIESFILTER: any = {
  [POWDER_TAB]: {
    included: [
      ["form.en", ["powder", "granules"]],
      ["_id", ["5e451a865d15d762e7e76c52"]],
    ],
    excluded: [],
  },
  [LIQUID_TAB]: {
    included: [["form.en", ["oil", "concentrates"]]],
    excluded: [],
  },
  [CAPSULES_TAB]: {
    included: [
      ["form.en", ["powder", "granules", "beadlet"]],
      ["_id", ["5e451a865d15d762e7e76c52"]],
    ],
    excluded: [],
  },
  [TABLETS_TAB]: {
    included: [
      ["form.en", ["powder", "granules"]],
      ["_id", ["5e451a865d15d762e7e76c52"]],
    ],
    excluded: [],
  },
};
