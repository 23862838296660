import React, { Component } from "react";
import Section404 from "../sections/404";

class Error404 extends Component {
  state = {};
  render() {
    return <Section404 />;
  }
}

export default Error404;
