import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class locationDescriptionGelsenkirchen extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <section className="position-relative overflow-visible p-0">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-12 col-xl-6 col-lg-6 bg-light-gray padding-10-rem-tb padding-nine-lr xl-padding-six-all md-padding-ten-all sm-padding-ten-lr sm-padding-fifteen-tb wow animate__fadeIn"
              style={{ visibility: "visible", animationName: "fadeIn" }}
            >
              {/*  <span
                className="alt-font font-weight-500 text-extra-dark-gray letter-spacing-2px text-uppercase d-block mb-3 wow animate__fadeIn"
                data-wow-delay="0.4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeIn",
                }}
              >
                Produktionsstandort
              </span> */}
              <h3
                className="alt-font font-weight-700 text-extra-dark-gray w-80 margin-25px-bottom letter-spacing-minus-1-half lg-w-90 md-margin-35px-bottom wow animate__fadeInRight"
                data-wow-delay="0.1s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.1s",
                  animationName: "fadeInRight",
                }}
              >
                Recklinghausen
              </h3>

              <p
                className="text-extra-dark-gray line-height-38px w-90 lg-w-100 md-w-80 sm-w-100 wow animate__fadeIn"
                data-wow-delay="0.4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeIn",
                }}
              >
                {" "}
                {t("text53")}
              </p>
              <div className="w-100 h-1px bg-white margin-2-half-rem-top opacity-1 mb-1" />
              <span
                className="alt-font font-weight-500 text-extra-dark-gray letter-spacing-2px  d-block mb-3 wow animate__fadeIn text-uppercase"
                data-wow-delay="0.4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeIn",
                }}
              >
                {t("title65")}
              </span>
              <div className="feature-box feature-box-left-icon-middle">
                <div className="feature-box-icon margin-10px-right">
                  <img
                    alt=""
                    src="images/people/fg.jpg"
                    className="rounded-circle w-70px h-70px sm-w-50px sm-h-50px"
                    data-no-retina
                  />
                </div>
                <div className="feature-box-content">
                  <span className="alt-font font-weight-500 text-extra-medium text-extra-dark-gray d-inline-block line-height-26px text-neon-orange-hover">
                    Fabian Geisler
                  </span>
                  {/* <span className="alt-font text-small d-block margin-5px-bottom text-uppercase font-weight-500">
                    Lorem Ipsum Dolor
                  </span> */}
                </div>
              </div>

              {/*  <div className="feature-box feature-box-left-icon-middle mt-3">
                <div className="feature-box-icon margin-10px-right">
                  <img
                    alt=""
                    src="https://ca.slack-edge.com/TAX5JE3D4-U01NU0H1MQ8-6715d54629d2-512"
                    className="rounded-circle w-70px h-70px sm-w-50px sm-h-50px"
                    data-no-retina
                  />
                </div>
                <div className="feature-box-content">
                  <a
                    href="blog-standard-post.html"
                    className="alt-font font-weight-500 text-extra-medium text-extra-dark-gray d-inline-block line-height-26px text-neon-orange-hover"
                  >
                    Jasmin König
                  </a>
                  <span className="alt-font text-small d-block margin-5px-bottom text-uppercase font-weight-500">
                    Lorem Ipsum Dolor
                  </span>
                </div>
              </div> */}
            </div>
            <div
              className="col-12 col-xl-6 col-lg-6 cover-background md-h-500px sm-h-350px wow animate__fadeIn vid-re"
              data-wow-delay="0.1s"
              style={{

                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeIn",
              }}
            >

            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation("general")(locationDescriptionGelsenkirchen);
