import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class Stats extends Component {
  state = { capsules: 0, tablets: 0, powder: 0, coatedTablets: 0, counting: true };

  startCounting = async () => {
    while (this.state.counting) {
      await this.sleep(100);
      const state = this.state;
      let change = false;
      if (state.capsules < 2.5) {
        state.capsules += (2.5/50);
        change = true;
      }
      if (state.tablets < 1) {
        state.tablets += (1/60);
        change = true;
      }
      if (state.powder < 2500) {
        state.powder += (2500/70);
        change = true;
      }
      if (state.coatedTablets < 1) {
        state.coatedTablets += (1/80);
        change = true;
      }
      if (!change) {
        state.counting = false;
      }
      this.setState({
        state,
      });
    }
  };

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  componentDidMount() {
    this.startCounting();
  }
  render() {
    const { t } = this.props;
    return (
        <div className="text-center text-sm-left">
          <div className="container mobile-padding-0" >
            <div className="row align-items-center">

              <div className="col-6 col-lg-3 wow animate__fadeInRight text-center sm-mb-10" style={{ visibility: "visible", animationName: "fadeInRight" }}>
                <h4 className="d-inline-flex text-extra-dark-gray alt-font font-weight-600 letter-spacing-minus-2px mb-0 mobile-font-smaller">
                  {this.state.capsules.toFixed(1)} {t("update5")}
                </h4>
                <span className="text-extra-medium d-block">{t("title10")}</span>
              </div>
              <div className="col-6 col-lg-3 wow animate__fadeInRight text-center sm-mb-10" style={{ visibility: "visible", animationName: "fadeInRight" }}>
                <h4 className="d-inline-flex text-extra-dark-gray alt-font font-weight-600 letter-spacing-minus-2px mb-0 mobile-font-smaller">
                  {this.state.tablets.toFixed(1)} {t("update5")}
                </h4>
                <span className="text-extra-medium d-block">{t("title11")}</span>
              </div>
              <div className="col-6 col-lg-3 wow animate__fadeInRight text-center" style={{ visibility: "visible", animationName: "fadeInRight" }}>
                <h4 className="d-inline-flex text-extra-dark-gray alt-font font-weight-600 letter-spacing-minus-2px mb-0 mobile-font-smaller">
                  {this.state.powder.toFixed(0)}{t("update6")}
                </h4>
                <span className="text-extra-medium d-block">Pulver</span>
              </div>
              <div className="col-6 col-lg-3 wow animate__fadeInRight text-center" style={{ visibility: "visible", animationName: "fadeInRight" }}>
                <h4 className="d-inline-flex text-extra-dark-gray alt-font font-weight-600 letter-spacing-minus-2px mb-0 mobile-font-smaller">
                  {this.state.coatedTablets.toFixed(1)} {t("update5")}
                </h4>
                <span className="text-extra-medium d-block">Coated Tablets</span>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withTranslation("general")(Stats);
