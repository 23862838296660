import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Carousel } from "react-bootstrap";

class Dark extends Component {
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <section
          className="p-0 bg-extra-dark-gray wow animate__fadeIn"
          style={{ visibility: "visible", animationName: "fadeIn" }}
        >
          <Carousel pause={"hover"}>
            {[1, 2, 3, 4, 5, 6].map((val) => (
              <Carousel.Item key={val}>
                <div className="container">
                  <div className="row ">
                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 md-margin-50px-bottom xs-margin-35px-bottom pt-5 pb-5 mt-5 mb-5">
                      <div className="pt-5 pb-5 mt-5 mb-5 mt-5 rmpb-767">
                        <h3 className="alt-font font-weight-600 text-white margin-2-rem-bottom">
                          {val === 1 ? t("title40") : null}
                          {val === 2 ? t("title41") : null}
                          {val === 3 ? t("title42") : null}
                          {val === 4 ? t("title43") : null}
                          {val === 5 ? t("title44") : null}
                          {val === 6 ? t("title45") : null}
                        </h3>
                      </div>
                    </div>
                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 md-margin-50px-bottom xs-margin-35px-bottom pt-5 pb-5 mt-5 mb-5">
                      <div className="pt-5 pb-5 mt-5 mb-5  rmpb-767">
                        <p className="text-white margin-2-rem-bottom">
                          {val === 1 ? t("text39") : null}
                          {val === 2 ? t("text40") : null}
                          {val === 3 ? t("text41") : null}
                          {val === 4 ? t("text42") : null}
                          {val === 5 ? t("text43") : null}
                          {val === 6 ? <img src="images/wachstum.png" height={500} /> : null}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(Dark);
