import userService from "../services/userService";
import { CustomCommoditiesDocument, CustomPackagingsDocument } from "../components/configurator/CustomTypes";
import { CompositionsDocument } from "../model/compositions.types";
import { ActivesubstancesDocument } from "../model/activesubstances.types";
import { CommoditycategoriesDocument } from "../model/commoditycategories.types";
import { CommoditypropertiesDocument } from "../model/commodityproperties.types";
import { ColorsDocument } from "../model/colors.types";
import { CapsulesDocument } from "../model/capsules.types";
import { TabletsDocument } from "../model/tablets.types";

const version = 3;

async function getPackaging(): Promise<Array<CustomPackagingsDocument>> {
  return await userService.getUser()?.callFunction("getPackaging", process.env.NODE_ENV);
}

async function getCommodities(): Promise<Array<CustomCommoditiesDocument>> {
  return await userService.getUser()?.callFunction("getCommodities", process.env.NODE_ENV);
}

async function getCommodityProperties(): Promise<Array<CommoditypropertiesDocument>> {
  return await userService.getUser()?.callFunction("getCommodityProperties", process.env.NODE_ENV);
}

async function getCommodityCategories(): Promise<Array<CommoditycategoriesDocument>> {
  return await userService.getUser()?.callFunction("getCommodityCategories", process.env.NODE_ENV);
}

async function getCompositions(): Promise<Array<CompositionsDocument>> {
  return await userService.getUser()?.callFunction("getCompositions", process.env.NODE_ENV);
}

async function getActiveSubstances(): Promise<Array<ActivesubstancesDocument>> {
  return await userService.getUser()?.callFunction("getActiveSubstances", process.env.NODE_ENV);
}

async function getColors(): Promise<Array<ColorsDocument>> {
  return await userService.getUser()?.callFunction("getColors", process.env.NODE_ENV);
}

async function getCapsules(): Promise<Array<CapsulesDocument>> {
  return await userService.getUser()?.callFunction("getCapsules", process.env.NODE_ENV);
}

async function getTablets(): Promise<Array<TabletsDocument>> {
  return await userService.getUser()?.callFunction("getTablets", process.env.NODE_ENV);
}

async function getTrackingInformation(trackingId: string) {
  return userService.getUser()?.callFunction("getTrackingInformation", process.env.NODE_ENV, trackingId, version);
}

/**
 * Calls the createNewRequest custom function to create and insert a new request
 * @param request The order to process
 * @returns result of insert or false if something failed
 */
async function createNewRequest(request: any) {
  return await userService.getUser()?.callFunction("sendRequest", [process.env.NODE_ENV, request]);
}

// eslint-disable-next-line
export default {
  createNewRequest,
  getCommodities,
  getCommodityCategories,
  getCommodityProperties,
  getCompositions,
  getColors,
  getCapsules,
  getTablets,
  getTrackingInformation,
  getActiveSubstances,
  getPackaging,
};
