import React from "react";
import _ from "lodash";
import { Link } from "@material-ui/core";
import { Translation } from "react-i18next";

interface paginationProps {
  itemsCount: number;
  pageSize: number;
  onPageChange: (page: number) => any;
  currentPage: number;
  onPageSizeChange?: (size: number) => any;
  baseSize?: number;
}

const Pagination: React.FunctionComponent<paginationProps> = ({
  itemsCount,
  pageSize,
  onPageChange,
  currentPage,
  onPageSizeChange,
  baseSize
}) => {
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth
  });

  // Handle resizing of the window
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth
      });
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (pagesCount === 1 || itemsCount === 0) return null;

  // Show less options on smaller screens
  const availWidth = dimensions.width;
  let rangePerSide = 6;
  if (availWidth < 400) {
    rangePerSide = 1;
  } else if (availWidth < 600) {
    rangePerSide = 2;
  } else if (availWidth < 800) {
    rangePerSide = 4;
  }
  let pages = _.range(1, pagesCount + 1);
  if (pagesCount > rangePerSide * 2 - 1) {
    const defaultMax = Math.max(1, currentPage - rangePerSide);
    const defaultMin = Math.min(currentPage + rangePerSide, pagesCount);
    const range = defaultMax - defaultMin + rangePerSide * 2;
    if (range > 0 && currentPage < rangePerSide * 2) {
      pages = _.range(defaultMax, Math.min(currentPage + rangePerSide + range, pagesCount + 1));
    } else if (range > 0) {
      pages = _.range(Math.max(1, currentPage - rangePerSide - range), defaultMin);
    } else {
      pages = _.range(defaultMax, defaultMin);
    }

    // Check that there are always at least 2 additional pages shown on first page
    if (currentPage === 1 && pages[pages.length - 1] === currentPage + 1 && pagesCount > currentPage + 1) {
      pages = pages.concat([currentPage + 2]);
      // Check that when not on last page the next page is always shown
    } else if (pages[pages.length - 1] === currentPage && currentPage !== pagesCount) {
      pages = pages.concat([currentPage + 1]);
      // Check that when on last page this page is always shown
    } else if (pages[pages.length - 1] === currentPage - 1 && currentPage === pagesCount) {
      pages = pages.concat([currentPage]);
    }
  }

  if (!baseSize) {
    baseSize = 10;
  }

  return (
    <div className="d-flex justify-content-center align-items-center flex-wrap">
      <div className="d-flex flex-wrap py-2 mr-3">
        <Link
          onClick={() => onPageChange(1)}
          className="btn btn-circle btn-icon btn-sm btn-light-primary mr-1 mr-sm-2 my-1"
        >
          <i className="ki ki-bold-double-arrow-back icon-xs" />
        </Link>
        <Link
          onClick={() => onPageChange(currentPage === 1 ? 1 : currentPage - 1)}
          className="btn btn-circle btn-icon btn-sm btn-light-primary mr-1 mr-sm-2 my-1"
        >
          <i className="ki ki-bold-arrow-back icon-xs" />
        </Link>
        {pagesCount > 10 && !pages.find(e => e === 2) && (
          <Link
            className="btn btn-circle btn-icon btn-sm border-0 btn-hover-primary mr-1 mr-sm-2 my-1"
            onClick={() => onPageChange(2)}
          >
            ...
          </Link>
        )}
        {pages.map(page => (
          <Link
            key={page}
            className={
              "btn btn-circle btn-icon btn-sm border-0 btn-hover-primary mr-1 mr-sm-2 my-1 " +
              (page === currentPage ? "active" : "")
            }
            onClick={() => onPageChange(page)}
          >
            {page}
          </Link>
        ))}
        {pagesCount > 10 && !pages.find(e => e === pagesCount) && (
          <>
            {!pages.find(e => e === pagesCount - 1) && (
              <Link
                className="btn btn-circle btn-icon btn-sm border-0 btn-hover-primary mr-1 mr-sm-2 my-1"
                onClick={() => onPageChange(pagesCount - 1)}
              >
                ...
              </Link>
            )}
            <Link
              className={
                "btn btn-circle btn-icon btn-sm border-0 btn-hover-primary mr-1 mr-sm-2 my-1 " +
                (pagesCount === currentPage ? "active" : "")
              }
              onClick={() => onPageChange(pagesCount)}
            >
              {pagesCount}
            </Link>
          </>
        )}
        <Link
          onClick={() => onPageChange(currentPage === pagesCount ? pagesCount : currentPage + 1)}
          className="btn btn-circle btn-icon btn-sm btn-light-primary mr-1 mr-sm-2 my-1"
        >
          <i className="ki ki-bold-arrow-next icon-xs" />
        </Link>
        <Link
          onClick={() => onPageChange(pagesCount)}
          className="btn btn-circle btn-icon btn-sm btn-light-primary mr-1 mr-sm-2 my-1"
        >
          <i className="ki ki-bold-double-arrow-next icon-xs" />
        </Link>
      </div>
      {onPageSizeChange && (
        <div className="d-flex align-items-center py-3">
          <select
            className="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-primary"
            style={{ width: "75px" }}
            onChange={value => onPageSizeChange(Number(value.target.value))}
          >
            <option value={baseSize}>{baseSize}</option>
            <option value={baseSize * 2}>{baseSize * 2}</option>
            <option value={baseSize * 3}>{baseSize * 3}</option>
            <option value={baseSize * 5}>{baseSize * 5}</option>
            <option value={baseSize * 10}>{baseSize * 10}</option>
          </select>
          <span className="text-muted">
            <Translation ns="utils">{t => t("displayRecords", { size: pageSize, count: itemsCount })}</Translation>
          </span>
        </div>
      )}
    </div>
  );
};

export function paginate(items: Array<any>, pageNumber: number, pageSize: number) {
  const startIndex = (pageNumber - 1) * pageSize;
  return _(items)
    .slice(startIndex)
    .take(pageSize)
    .value();
}

export default Pagination;
