import React from "react";
import { TabletsDocument } from "../../model/tablets.types";

interface TabletSelectionProps {
  tablets: Array<TabletsDocument>;
  t: (key: string, options?: any) => string;
  preferences: any;
  onPreferenceChange: (path: string, item: any) => void;
}

const areEqual = (prevProps: Readonly<TabletSelectionProps>, nextProps: Readonly<TabletSelectionProps>) => {
  return JSON.stringify(prevProps.preferences) === JSON.stringify(nextProps.preferences);
};

const TabletSelection: React.FunctionComponent<TabletSelectionProps> = React.memo(props => {
  const { tablets, t, preferences, onPreferenceChange } = props;
  return (
    <>
      <span className="text-dark-75  font-size-h3 font-weight-bold mr-3">{t("preferences")}</span>
      <div className="form-group row mt-10" id="tabletSelection">
        <div className="col-lg-4">
          <label><b>{t("tabletType")}</b></label>
          <select
            className="form-control"
            style={{backgroundColor: "white"}}
            id="selectTablet"
            value={preferences.selectedTablet ? preferences.selectedTablet._id : ""}
            onChange={e => {
              onPreferenceChange(
                "selectedTablet",
                tablets.find(tab => tab._id.toString() === e.target.value.toString())
              );
            }}
          >
            {tablets.map(tablet => {
              return (
                <option key={tablet._id} value={tablet._id}>
                  {`${t(tablet.shape)} - ${t("volume")}: ${tablet.volume}ml`}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-lg-4">
          <label><b>{t("perUnit")}</b></label>
          <div className="input-group">
            <input
              type="number"
              min="0"
              className="form-control"
              id="perUnit"
              value={preferences.amountPerUnit}
              onChange={e => {
                onPreferenceChange("amountPerUnit", Number(parseInt(e.target.value) || "0").toString());
              }}
            />
            <div className="input-group-append">
              <span className="input-group-text">{t("tablets")}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}, areEqual);

export default TabletSelection;
