import React, { Component } from "react";
import Configurator from "../sections/configurator";
import { withTranslation } from "react-i18next";

class Portfolio extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <section className="section">
          <div className="container">
            <div className="row">
              <div
                className="col-12  col-lg-6 col-md-12 margin-4-rem-top margin-4-rem-bottom  wow animate__fadeIn"
                data-wow-delay="0.1s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.1s",
                  animationName: "fadeIn",
                }}
              >
                <h3 className="alt-font font-weight-700 text-extra-dark-gray w-90 margin-30px-bottom lg-w-100 sm-margin-15px-bottom">
                  {t("title6")}
                </h3>

                <a href="/configurator" className="btn btn-fancy btn-small btn-black">
                  {t("button1")}
                  <i className="feather icon-feather-arrow-right right-icon" />
                </a>
              </div>{" "}
              <div className="col-12 col-lg-6 col-md-12 d-flex justify-content-end">
                <figure className="position-relative m-0">
                  <img src="images/goodhands.jpg" alt="" />
                </figure>
              </div>
              {/* <div
                className="col-12 col-md-6 offset-lg-1 margin-30px-bottom margin-8-rem-top md-no-margin-tb wow animate__fadeIn"
                data-wow-delay="0.2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeIn",
                }}
              >
               
                <div className="progress progress-step-style-01 margin-5-rem-bottom sm-margin-6-rem-bottom">
                  <span className="progress-title alt-font text-medium text-extra-dark-gray text-uppercase font-weight-500">
                    asdf
                  </span>
                  <div
                    className="progress-bar bg-fast-yellow appear"
                    role="progressbar"
                    aria-valuenow={80}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: "80%" }}
                  >
                    <span className="alt-font text-small text-extra-dark-gray font-weight-500">
                      80%
                    </span>
                  </div>
                </div>
               
                <div className="progress progress-step-style-01 margin-5-rem-bottom sm-margin-6-rem-bottom">
                  <span className="progress-title alt-font text-medium text-extra-dark-gray text-uppercase font-weight-500">
                    Digital Delivery
                  </span>
                  <div
                    className="progress-bar bg-fast-yellow appear"
                    role="progressbar"
                    aria-valuenow={95}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: "95%" }}
                  >
                    <span className="alt-font text-small text-extra-dark-gray font-weight-500">
                      95%
                    </span>
                  </div>
                </div>
               
                <div className="progress progress-step-style-01">
                  <span className="progress-title alt-font text-medium text-extra-dark-gray text-uppercase font-weight-500">
                    Global Communications
                  </span>
                  <div
                    className="progress-bar bg-fast-yellow appear"
                    role="progressbar"
                    aria-valuenow={75}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: "75%" }}
                  >
                    <span className="alt-font text-small text-extra-dark-gray font-weight-500">
                      75%
                    </span>
                  </div>
                </div>
               
              </div> */}
            </div>
          </div>
        </section>
        <section
          className="p-0 bg-extra-dark-gray wow animate__fadeIn"
          style={{ visibility: "visible", animationName: "fadeIn" }}
        >
          <div className="container">
            <div className="row ">
              <div className="col-12 col-xl-6 col-lg-6 col-md-12 md-margin-50px-bottom xs-margin-35px-bottom wow animate__fadeIn pt-5 pb-5 mt-5 mb-5 rmp-400">
                <div className="pt-5 pb-5 mt-5 mb-5">
                  <div className="row">
                    <div className="col-6">
                      <h4 className=" alt-font font-weight-600 text-extra-dark-gray mb-0 appear text-white ">
                        2.5 {t("update5")}
                      </h4>
                      <span className="alt-font text-light-greenish-gray text-uppercase d-block letter-spacing-1px text-white ">
                        {t("title10")}
                      </span>
                    </div>{" "}
                    <div className="col-6">
                      <h4 className="alt-font font-weight-600 text-extra-dark-gray mb-0 appear text-white">
                        1 {t("update5")}
                      </h4>
                      <span className="alt-font text-light-greenish-gray text-uppercase d-block letter-spacing-1px text-white">
                        {t("title11")}
                      </span>
                    </div>
                  </div>{" "}
                  <div className="row mt-5">
                    <div className="col-6">
                      <h4 className="alt-font font-weight-600 text-extra-dark-gray mb-0 appear text-white">
                        2.500 {t("update6")}
                      </h4>
                      <span className="alt-font text-light-greenish-gray text-uppercase d-block letter-spacing-1px text-white">
                        {t("title12")}
                      </span>
                    </div>{" "}
                    <div className="col-6">
                      <h4 className="alt-font font-weight-600 text-extra-dark-gray mb-0 appear text-white">
                        1 {t("update5")}
                      </h4>
                      <span className="alt-font text-light-greenish-gray text-uppercase d-block letter-spacing-1px text-white">
                        {t("title13")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-xl-6 col-lg-6 col-md-12 md-margin-50px-bottom xs-margin-35px-bottom wow animate__fadeIn pt-5 pb-5 mt-5 mb-5 rmp-400"
                data-wow-delay="0.2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeIn",
                }}
              >
                <div className="pt-5 pb-5 mt-5 mb-5">
                  <p className="text-white margin-2-rem-bottom">{t("text10")}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section id="down-section ">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <h3 className="alt-font font-weight-500 text-extra-dark-gray w-90 text-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt.
                </h3>
                <p className="w-85 lg-w-90">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit do
                  eiusmod tempor incididunt ut labore et dolore magna Ut enim ad
                  minim veniam, nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat.
                </p>
                <div className="btn-dual margin-15px-top d-inline-block">
                  <a
                    href="who-we-are.html"
                    className="btn btn-medium btn-dark-gray btn-slide-right-bg"
                  >
                    Discover Litho
                    <span className="bg-white" />
                  </a>
                  <a
                    href="about-us.html"
                    className="btn btn-medium btn-transparent-dark-gray btn-slide-right-bg xs-no-margin"
                  >
                    Read More
                    <span className="bg-extra-dark-gray" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section
          className="wow animate__fadeIn bg-light-gray"
          style={{ visibility: "visible", animationName: "fadeIn" }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6 sm-margin-30px-bottom">
                <h3 className="alt-font font-weight-500 text-extra-dark-gray mb-0">
                  Produktportfolio
                </h3>
              
              </div>
              <div className="col-12 col-lg-6 col-md-6">
                <div className="row row-cols-1 row-cols-sm-2">
          
                  <div className="col counter-style-01 last-paragraph-no-margin xs-margin-30px-bottom">
                    <h5
                      className="counter counter-number text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-1px margin-5px-bottom"
                      data-to={2530}
                      data-speed={7000}
                    >
                      1.25 Mrd.
                    </h5>
                    <span className="alt-font font-weight-500 text-extra-dark-gray d-block margin-5px-bottom">
                      Kapseln jährlich
                    </span>
           
                  </div>
      
                  <div className="col counter-style-01 last-paragraph-no-margin">
                    <h5
                      className="counter counter-number text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-1px margin-5px-bottom"
                      data-to={2000}
                      data-speed={7000}
                    >
                      7 Mio.
                    </h5>
                    <span className="alt-font font-weight-500 text-extra-dark-gray d-block margin-5px-bottom">
                      Dosen jährlich
                    </span>
                 
                  </div>
             
                </div>
              </div>
            </div>
          </div>
        </section>{" "} */}{" "}
        <section className="">
          <div className="container">
            <div className="row justify-content-center wow animate__fadeIn">
              <div className="col-12 col-lg-6 col-md-10">
                <div
                  className="panel-group accordion-event accordion-style-02"
                  id="accordion2"
                  data-active-icon="icon-feather-minus"
                  data-inactive-icon="icon-feather-plus"
                >
                  {/* start accordion item */}
                  <div className="panel bg-transparent">
                    <div className="panel-heading border-color-white-transparent active-accordion">
                      <a
                        className="accordion-toggle"
                        data-toggle="collapse"
                        data-parent="#accordion2"
                        href="#accordion-style-02-01"
                      >
                        <div className="panel-title">
                          <span
                            className="alt-font text-extra-dark-gray d-inline-block font-weight-700"
                            style={{ fontSize: 20 }}
                          >
                            {t("title14")}
                          </span>
                          <i className="text-extra-dark-gray feather icon-feather-minus" />
                        </div>
                      </a>
                    </div>
                    <div id="accordion-style-02-01" className="panel-collapse collapse show" data-parent="#accordion2">
                      <div className="panel-body">{t("text11")}</div>
                    </div>
                  </div>
                  {/* end accordion item */}
                  {/* start accordion item */}
                  <div className="panel bg-transparent">
                    <div className="panel-heading border-color-white-transparent">
                      <a
                        className="accordion-toggle"
                        data-toggle="collapse"
                        data-parent="#accordion2"
                        href="#accordion-style-02-02"
                      >
                        <div className="panel-title">
                          <span
                            className="alt-font text-extra-dark-gray d-inline-block font-weight-700"
                            style={{ fontSize: 20 }}
                          >
                            {t("title15")}
                          </span>
                          <i className="indicator text-extra-dark-gray feather icon-feather-plus" />
                        </div>
                      </a>
                    </div>
                    <div id="accordion-style-02-02" className="panel-collapse collapse" data-parent="#accordion2">
                      <div className="panel-body">{t("text12")}</div>
                    </div>
                  </div>
                  {/* end accordion item */}
                  {/* start accordion item */}
                  <div className="panel bg-transparent">
                    <div className="panel-heading border-color-white-transparent">
                      <a
                        className="accordion-toggle"
                        data-toggle="collapse"
                        data-parent="#accordion2"
                        href="#accordion-style-02-03"
                      >
                        <div className="panel-title">
                          <span
                            className="alt-font text-extra-dark-gray d-inline-block font-weight-700"
                            style={{ fontSize: 20 }}
                          >
                            {t("title13")}
                          </span>
                          <i className="indicator text-extra-dark-gray feather icon-feather-plus" />
                        </div>
                      </a>
                    </div>
                    <div id="accordion-style-02-03" className="panel-collapse collapse" data-parent="#accordion2">
                      <div className="panel-body">{t("text13")}</div>
                    </div>
                  </div>
                  {/* end accordion item */}
                  {/* start accordion item */}
                  <div className="panel bg-transparent">
                    <div className="panel-heading border-color-white-transparent">
                      <a
                        className="accordion-toggle"
                        data-toggle="collapse"
                        data-parent="#accordion2"
                        href="#accordion-style-02-04"
                      >
                        <div className="panel-title">
                          <span
                            className="alt-font text-extra-dark-gray d-inline-block font-weight-700"
                            style={{ fontSize: 20 }}
                          >
                            {t("title16")}
                          </span>
                          <i className="indicator text-extra-dark-gray feather icon-feather-plus" />
                        </div>
                      </a>
                    </div>
                    <div id="accordion-style-02-04" className="panel-collapse collapse" data-parent="#accordion2">
                      <div className="panel-body"> {t("text14")}</div>
                    </div>
                  </div>
                  {/* end accordion item */}
                  {/* start accordion item */}
                  <div className="panel bg-transparent">
                    <div className="panel-heading border-color-white-transparent">
                      <a
                        className="accordion-toggle"
                        data-toggle="collapse"
                        data-parent="#accordion2"
                        href="#accordion-style-02-05"
                      >
                        <div className="panel-title">
                          <span
                            className="alt-font text-extra-dark-gray d-inline-block font-weight-700"
                            style={{ fontSize: 20 }}
                          >
                            {t("title17")}
                          </span>
                          <i className="indicator text-extra-dark-gray feather icon-feather-plus" />
                        </div>
                      </a>
                    </div>
                    <div id="accordion-style-02-05" className="panel-collapse collapse" data-parent="#accordion2">
                      <div className="panel-body"> {t("text15")}</div>
                    </div>
                  </div>
                  {/* end accordion item */}
                  {/* start accordion item */}
                  <div className="panel bg-transparent">
                    <div className="panel-heading border-color-white-transparent">
                      <a
                        className="accordion-toggle"
                        data-toggle="collapse"
                        data-parent="#accordion2"
                        href="#accordion-style-02-06"
                      >
                        <div className="panel-title">
                          <span
                            className="alt-font text-extra-dark-gray d-inline-block font-weight-700"
                            style={{ fontSize: 20 }}
                          >
                            {t("title18")}
                          </span>
                          <i className="indicator text-extra-dark-gray feather icon-feather-plus" />
                        </div>
                      </a>
                    </div>
                    <div id="accordion-style-02-06" className="panel-collapse collapse" data-parent="#accordion2">
                      <div className="panel-body">{t("text16")}</div>
                    </div>
                  </div>
                  {/* end accordion item */}
                </div>
              </div>
              <div className="col-12 col-lg-6 col-sm-9 text-center">
                <img className="p-5" src="images/floating_still_2.jpg"></img>
              </div>
            </div>
          </div>
        </section>{" "}
        <section className="bg-light-gray">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div
                className="col-12 col-lg-12 col-md-12 md-margin-7-rem-bottom wow animate__fadeInLeft"
                data-wow-delay="0.5s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.5s",
                  animationName: "fadeInLeft",
                }}
              >
                <h5 className="alt-font font-weight-600 text-extra-dark-gray w-95">
                  {t("text17")} <br></br>
                  {t("text18")}
                </h5>
              </div>
            </div>
          </div>
        </section>
        <Configurator />
        <span className="scroll-top-arrow">
          <i className="feather icon-feather-arrow-up text-extra-dark-gray" />
        </span>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(Portfolio);
