import auth from "../services/authService";
import { UserdataDocument } from "../model/userdata.types";

function getUser() {
  return auth.getUser();
}
function getUserMail() {
  return auth.getUser()?.profile.email;
}

function getUserData(): UserdataDocument {
  return auth.getUser()?.customData;
}

function getCompanyId() {
  return auth.getUser()?.customData.company_id;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getUser,
  getUserMail,
  getUserData,
  getCompanyId
};
