export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname;

export const getCW = (date: Date) => {
  const tdt = new Date(date.valueOf());
  const dayn = (date.getDay() + 6) % 7;
  tdt.setDate(tdt.getDate() - dayn + 3);
  const firstThursday = tdt.valueOf();
  tdt.setMonth(0, 1);
  if (tdt.getDay() !== 4) {
    tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
  }
  // @ts-ignore
  return 1 + Math.ceil((firstThursday - tdt) / 604800000);
};
