import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import orderUtils, {
  DECLINED,
  FULFILLMENT,
  OFFER,
  ORDERED,
  PRODUCTION,
  REQUESTAPPROVED,
  REQUESTPENDING,
  INVOICE,
  REMINDER,
  WAITING,
  RETURNPRODUCTIONQUEUE,
  REQUESTREVOKED,
  REQUESTED,
  REQUESTUPDATED,
  REORDER,
  TARGETDATEUPDATED,
} from "../../utils/orderUtils";
import { getCW } from "../../utils/helper";

interface OrderTimelineEntryProps extends WithTranslation {
  timelineEntry: { type: string; date: Date; oldDate?: Date | null; newDate?: Date };
}

interface OrderTimelineEntryState {}

class OrderTimelineEntry extends PureComponent<OrderTimelineEntryProps, OrderTimelineEntryState> {
  _isMounted = false;

  async componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Return the additional description for the given state
   * @returns Additional description
   */
  resolveAdditionalDescription() {
    const { t, timelineEntry } = this.props;
    if (timelineEntry.type === OFFER) {
      return t("offerCreated");
    }
    if (timelineEntry.type === ORDERED) {
      return t("orderAccepted");
    }
    if (timelineEntry.type === REQUESTAPPROVED) {
      return t("waitingForOfferAnswer");
    }
    if (timelineEntry.type === REQUESTPENDING) {
      return t("waitingForSales");
    }
    if (timelineEntry.type === PRODUCTION) {
      return t("productionStarted");
    }
    if (timelineEntry.type === FULFILLMENT) {
      return t("waitingForFulfillment");
    }
    if (timelineEntry.type === DECLINED) {
      return t("orderDeclined");
    }
    if (timelineEntry.type === INVOICE) {
      return t("invoice");
    }
    if (timelineEntry.type === REMINDER) {
      return t("reminder");
    }
    if (timelineEntry.type === WAITING) {
      return t("waiting");
    }
    if (timelineEntry.type === RETURNPRODUCTIONQUEUE) {
      return t("returnProductionQueue");
    }
    if (timelineEntry.type === REQUESTREVOKED) {
      return t("requestRevoked");
    }
    if (timelineEntry.type === REQUESTED) {
      return t("requestCreated");
    }
    if (timelineEntry.type === REQUESTUPDATED) {
      return t("requestUpdated");
    }
    if (timelineEntry.type === REORDER) {
      return t("reordered");
    }
    if (timelineEntry.type === TARGETDATEUPDATED) {
      return t("targetDateUpdated", {
        week1: timelineEntry.oldDate ? getCW(timelineEntry.oldDate) : "-",
        week2: timelineEntry.newDate ? getCW(timelineEntry.newDate) : "-",
      });
    }
    return t("loading");
  }

  /**
   * Resolve the type of a timeline entry. Normally the type is used but in special cases, like labels,
   * we want to display other things.
   * @returns Text that should be shown as type
   */
  resolveType() {
    const { t, timelineEntry } = this.props;
    return t(timelineEntry.type);
  }

  render() {
    const { t, timelineEntry } = this.props;
    return (
      <div className="timeline-item">
        <div className={"timeline-badge bg-dark"} />
        <div className="timeline-content">
          <div className="row">
            <div className="col d-block">
              <div className="font-weight-bold text-dark" style={{ fontSize: "16px" }}>
                {this.resolveAdditionalDescription()}
              </div>
            </div>
            <div className="col-auto ml-auto">
              <span className="text-muted text-right font-italic">
                {t("timeAgo", {
                  time: orderUtils.getLatestUpdate(t, timelineEntry.date),
                })}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation("timelineEntry")(OrderTimelineEntry);
