import React, { Component, createRef } from "react";

import HeroImage from "../sections/heroImage";

import FinalSummary from "../sections/finalSummary";
import Contact from "../sections/contact";
import Summary from "../sections/summary";
import Compostable2 from "../sections/compostable2";
import Coins from "../sections/coins";
import Coins2 from "../sections/coins2";
import Stats from "../sections/stats";
import MapSection from "../sections/map";
import Dark from "../sections/dark";
import ImageSlider from "../sections/imageSlider";
import Configurator from "../sections/configurator";
import Bubbles from "../sections/bubbles";
class HomePage extends Component {


    state = {};

  render() {
    return (
      <React.Fragment>
        <HeroImage />

          <Coins2 />
        <Summary />
          <Coins />
        <MapSection />
        {/*<Dark />*/}
        <ImageSlider />
        <Configurator />
        <Compostable2 />
        <FinalSummary />
        <Contact />
        <span className="scroll-top-arrow">
          <i className="feather icon-feather-arrow-up text-extra-dark-gray" />
        </span>
      </React.Fragment>
    );
  }
}

export default HomePage;
