import React, { PureComponent } from "react";
import Configurator from "./Configurator";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import authService from "../../services/authService";
import userService from "../../services/userService";

interface ConfiguratorWrapperProps extends WithTranslation, RouteComponentProps<{}, {}, {}> {}

class ConfiguratorWrapper extends PureComponent<ConfiguratorWrapperProps> {
  async componentDidMount() {
    if (!userService.getUser()) {
      await authService.login();
    }
    this.forceUpdate();
  }

  render() {
    return (
      <div className="mt-10 mb-5 configurator-wrapper" style={!userService.getUser() ? { height: "100%" } : {}}>
        {!userService.getUser() ? (
          <div className="card container gutter-b">
            <div className="card-custom">Loading...</div>
          </div>
        ) : (
          <Configurator {...this.props} />
        )}
        <span className="scroll-top-arrow">
          <i className="feather icon-feather-arrow-up text-extra-dark-gray" />
        </span>
      </div>
    );
  }
}

export default withTranslation(["configurator", "error"])(ConfiguratorWrapper);
