import React, { Component  } from "react";
import { withTranslation } from "react-i18next";

class Coins extends Component {
  state = {};

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <section id="about" className="bg-light-gray" >
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6" style={{ minHeight: "500px" }}>
                <video
                  style={{ height: "500px", zIndex: 99 }}
                  loop=""
                  autoplay=""
                  controls=""
                  muted
                  class="html-video"
                  poster="image path"
                >
                  <source type="video/mp4" src="images/floating_objects_3.mp4" />
                </video>
              </div>{" "}
              <div class="col-12 col-lg-6">
                <div>
                  <h3
                    className="alt-font font-weight-700 text-extra-dark-gray w-80 margin-45px-bottom letter-spacing-minus-1-half lg-w-90 md-margin-35px-bottom wow animate__fadeInRight"
                    data-wow-delay="0.1s"
                  >
                    {t("title38")}
                    <br></br>
                    {t("title39")}
                  </h3>
                  <ul className="list-style-06">
                    <li
                      className="margin-35px-bottom last-paragraph-no-margin md-margin-25px-bottom wow animate__fadeInRight"
                      data-wow-delay="0.2s"
                    >
                      <div>
                        <span className="w-25px h-25px text-center bg-dark rounded-circle text-white margin-25px-right margin-5px-top d-flex flex-column">
                          <i className="fas fa-check" />
                        </span>
                      </div>
                      <div>
                        <span className="text-extra-medium text-extra-dark-gray font-weight-500 margin-5px-bottom d-block">
                          {t("text29")}
                        </span>
                        <p className="">{t("text34")}</p>
                      </div>
                    </li>
                    <li
                      className="margin-35px-bottom last-paragraph-no-margin md-margin-25px-bottom wow animate__fadeInRight"
                      data-wow-delay="0.3s"
                    >
                      <div>
                        <span className="w-25px h-25px text-center bg-dark rounded-circle text-white margin-25px-right margin-5px-top d-flex flex-column">
                          <i className="fas fa-check" />
                        </span>
                      </div>
                      <div>
                        <span className="text-extra-medium text-extra-dark-gray font-weight-500 margin-5px-bottom d-block">
                          {t("text35")}
                        </span>
                        <p className="">{t("text36")}</p>
                      </div>
                    </li>
                    <li
                      className="margin-35px-bottom last-paragraph-no-margin md-margin-25px-bottom wow animate__fadeInRight"
                      data-wow-delay="0.3s"
                    >
                      <div>
                        <span className="w-25px h-25px text-center bg-dark rounded-circle text-white margin-25px-right margin-5px-top d-flex flex-column">
                          <i className="fas fa-check" />
                        </span>
                      </div>
                      <div>
                        <span className="text-extra-medium text-extra-dark-gray font-weight-500 margin-5px-bottom d-block">
                          {t("text37")}
                        </span>
                        <p className="">{t("text38")}</p>
                      </div>
                    </li>
                  </ul>
                  <div className="margin-15px-top d-inline-block wow animate__fadeInRight" data-wow-delay="0.4s">
                    <a
                      href="/portfolio"
                      className="btn btn-fancy btn-small btn-dark-gray margin-35px-right sm-margin-15px-right"
                    >
                      {t("button3")}
                    </a>
                  </div>
                </div>

                {/* <div className="col-12 col-lg-4 col-md-6 pl-lg-0 d-flex sm-margin-30px-bottom">
              <div className="justify-content-center w-100 d-flex flex-column bg-fast-blue padding-5-half-rem-lr lg-padding-3-rem-lr md-padding-4-rem-all">
                <span className="text-extra-large alt-font font-weight-500 text-white margin-20px-bottom d-block">We started this company 10 years ago out of a desire to design and build amazing things.</span>
                <p className="text-white opacity-7">Lorem ipsum is simply dummy text of the printing and typeing industry. Lorem ipsum has been the industry's standard dummy text ever since text.</p>
                <a href="our-services.html" className="btn btn-large btn-link text-white text-white-hover align-self-start">Company overview</a>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-md-6">
              <img src="https://placehold.it/800x600" alt="" />
              <div className="bg-white padding-3-half-rem-lr padding-3-rem-tb lg-padding-2-rem-all md-padding-2-half-rem-all sm-padding-4-rem-all last-paragraph-no-margin">
                <span className="alt-font text-extra-dark-gray font-weight-500 margin-10px-bottom d-block">About digital agency</span>
                <p>Lorem ipsum is simply dummy text of the printing and typesetting industry. Lorem ipsum has been the industry's standard dummy text.</p>
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(Coins);
