import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class UTAlert extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="container">
        <div className="row" style={{ textAlign: "center" }}>
          <p style={{ display: "block", margin: "15px auto" }}>* {t("text68")}</p>
        </div>
      </div>
    );
  }
}

export default withTranslation("general")(UTAlert);
