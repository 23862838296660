import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CustomPackagingsDocument, SelectedPackagingsDocument } from "./CustomTypes";
import { toAbsoluteUrl } from "../../utils/helper";

interface BaseProps {
  packaging: any;
  t: (key: string, options?: any) => string;
}

export class BasePackagingItem<T> extends React.PureComponent<T & BaseProps, {}> {
  resolvePackaging() {
    const { packaging, t } = this.props;
    const { packaging_type: type } = packaging;
    let packagingProperties: any = this.resolvePackagingProperties() || {};
    packagingProperties.type = t("packaging:" + type);
    return packagingProperties;
  }

  resolveUrl(images: Array<any>, defaultImage: string) {
    return `url(${images.length > 0 ? images[0] : toAbsoluteUrl("/media/img/" + defaultImage)})`;
  }

  resolvePackagingProperties() {
    const { packaging, t } = this.props;
    const { packaging_type: type } = packaging;
    // Wrapper function for translation with namespace packaging
    const pt = (key: string) => t("packaging:" + key);
    let properties = {};
    switch (type) {
      case "bottle":
        properties = {
          url: this.resolveUrl(packaging.images, "no_bottle.jpg"),
          size: packaging.packaging_volume + " ml",
          shape: pt(packaging.packaging_shape!),
          neck: packaging.packaging_neck,
          labelHeight: packaging.packaging_label_height + " mm",
          material: `${pt(packaging.packaging_material!)}, ${pt(packaging.packaging_color!)}`,
        };
        return properties;
      case "liquidbottle":
        properties = {
          url: this.resolveUrl(packaging.images, "no_liquidbottle.jpg"),
          size: packaging.packaging_volume + " ml",
          material: `${pt(packaging.packaging_material!)}, ${pt(packaging.packaging_color!)}`,
        };
        return properties;
      case "box":
        properties = {
          url: this.resolveUrl(packaging.images, "no_box.jpg"),
          size: `${packaging.box_width} x ${packaging.box_height} x ${packaging.box_depth} mm (${t(
            "configurator:wxhxd"
          )})`,
          quality: pt(packaging.box_quality!),
        };
        return properties;
      case "lid":
        properties = {
          url: this.resolveUrl(packaging.images, "no_lid.jpg"),
          size: packaging.lid_size,
          shape: pt(packaging.lid_shape!),
          material: Array.from(new Set([pt(packaging.lid_material!), pt(packaging.lid_color!)])).join(", "),
        };
        return properties;
      case "bag":
        properties = {
          url: this.resolveUrl(packaging.images, "no_bag.jpg"),
          size: `${packaging.bag_width} x ${packaging.bag_height} mm (${t("configurator:wxh")}), ${
            packaging.bag_volume
          } ml`,
          shape: pt(packaging.bag_shape!),
          material: `${pt(packaging.bag_material!)}, ${pt(packaging.bag_color!)}`,
          zipper: pt(packaging.bag_zipper!),
        };
        return properties;
      case "blister":
        properties = {
          url: this.resolveUrl(packaging.images, "no_blister.jpg"),
          size: `${packaging.blister_width} x ${packaging.blister_height} x ${packaging.blister_depth} mm (${t(
            "configurator:wxhxd"
          )})`,
          capsules: packaging.blister_capsules,
        };
        return properties;
      case "label":
      case "multilayer_label":
        properties = {
          url: this.resolveUrl(packaging.images, "no_label.jpg"),
          size: `${packaging.label_width} x ${packaging.label_height} mm (${t("configurator:wxh")})`,
          quality: pt(packaging.label_quality!),
        };
        return properties;
      case "sleeve":
        properties = {
          url: this.resolveUrl(packaging.images, "sleeve.jpg"),
          size: `${packaging.sleeve_size} mm`,
          print: t("packaging:" + (packaging.sleeve_print ? "yes" : "no")),
        };
        return properties;
      case "pipette":
        properties = {
          url: this.resolveUrl(packaging.images, "no_pipette.jpg"),
          length: packaging.packaging_height + " mm",
          neck: packaging.packaging_neck,
          color: pt(packaging.packaging_color!),
        };
        return properties;
    }
  }

  renderProperties(packagingProperties: any) {
    const { t } = this.props;
    return (
      <>
        {Object.entries(packagingProperties).map(([key, value]: [string, any]) => {
          if (key === "type" || key === "price" || key === "url") return;
          return (
            <div className="m-0 py-1" key={key}>
              <span className="font-size-sm text-muted font-weight-bold">{t("configurator:" + key)}:</span>{" "}
              <span>{value}</span>
            </div>
          );
        })}
      </>
    );
  }

  render() {
    return <div />;
  }
}

interface PackagingItemProps {
  packaging: CustomPackagingsDocument;
  onPackagingSelect: (packaging: CustomPackagingsDocument) => void;
}

export default class PackagingItem extends BasePackagingItem<PackagingItemProps> {
  /**
   *
   * returns dict with image, type, size, material, shape and price
   */

  render() {
    const { packaging, t, onPackagingSelect } = this.props;
    const packagingProperties: any = this.resolvePackaging();
    return (
      <div
        id={packaging._id.toString()}
        className="card card-custom card-round gutter-b card-stretch"
        style={{
          boxShadow: "0px 0px 13px 0px rgba(82, 63, 105, 0.1)",
          minHeight: "310px",
        }}
      >
        <div
          className="card-header border-0 bgi-position-center bgi-size-cover p-0"
          style={{
            height: "100px",
            minHeight: "100px",
            borderRadius: "1rem 1rem 0rem 0rem",
            backgroundImage: packagingProperties.url,
            alignItems: "flex-end",
          }}
        >
          {packaging.recommended && (
            <span
              className="d-block badge badge-pill badge-success mx-auto py-0"
              style={{ backgroundColor: "#0abb87" }}
            >
              {t("configurator:recommended")}
            </span>
          )}
        </div>
        <div className="card-body d-flex flex-column p-0 pb-1">
          <div className="py-1 px-3 text-center my-auto">
            <div className="m-0 py-1">
              <span className="font-size-sm text-muted font-weight-bold">{t("configurator:type")}:</span>{" "}
              <span className="font-weight-bolder">{packagingProperties.type}</span>
            </div>
            {this.renderProperties(packagingProperties)}
          </div>
        </div>
        <div className="card-footer m-0 py-0 text-center" style={{ borderTop: "None", padding: "2rem 1.30rem" }}>
          <button
            type="button"
            name="select"
            className="btn btn-primary btn-sm mb-3"
            style={{ width: "80%" }}
            onClick={() => {
              onPackagingSelect(packaging);
            }}
          >
            {t("configurator:select")}
          </button>
        </div>
      </div>
    );
  }
}

interface SelectedPackagingItemProps {
  packaging?: SelectedPackagingsDocument;
  placeholderType?: string;
  onPackagingAmount: (packaging: SelectedPackagingsDocument, add: boolean) => void;
}

export class SelectedPackagingItem extends BasePackagingItem<SelectedPackagingItemProps> {
  render() {
    const { packaging, t, onPackagingAmount } = this.props;
    const packagingProperties: any = this.resolvePackaging();
    // Disable + button at 99
    const disabled = packaging.amount! >= 99 || packaging.packaging_type !== "blister";
    return (
      <div
        id={packaging._id.toString()}
        className="card card-custom card-round gutter-b card-stretch"
        style={{
          boxShadow: "0px 0px 13px 0px rgba(82, 63, 105, 0.1)",
          minHeight: "210px",
        }}
      >
        <div className="card-body d-flex flex-column p-0 pb-1">
          <div className="py-1 px-3 text-center my-auto">
            <div className="m-0 py-1 ">
              <span className="font-size-sm text-muted font-weight-bold">{t("configurator:type")}:</span>{" "}
              <span className="font-weight-bolder">{packagingProperties.type}</span>
            </div>
            {this.renderProperties(packagingProperties)}
          </div>
        </div>
        <div className="card-footer m-0 py-0 text-center" style={{ borderTop: "None", padding: "2rem 1.30rem" }}>
          <div className="my-auto">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="decreaseAmount">
                  {packaging.amount && packaging.amount === 1
                    ? t("configurator:removePackaging")
                    : t("configurator:decreaseAmount")}
                </Tooltip>
              }
            >
              <button
                type="button"
                className="btn btn-icon btn-xs btn-danger my-3"
                name="decrease"
                onClick={() => {
                  onPackagingAmount(packaging, false);
                }}
              >
                <i className="fa fa-minus icon-sm" />
              </button>
            </OverlayTrigger>
            <span
              className="mx-1 font-weight-boldest align-middle d-inline-block"
              style={{ fontSize: "18px", width: "25px" }}
            >
              {packaging.amount}
            </span>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="increaseAmount">{t("configurator:increaseAmount")}</Tooltip>}
            >
              <button
                type="button"
                className="btn btn-icon btn-xs btn-success my-3"
                name="increase"
                onClick={() => {
                  onPackagingAmount(packaging, true);
                }}
                disabled={disabled}
                style={disabled ? { cursor: "not-allowed" } : {}}
              >
                <i className="fa fa-plus icon-sm" />
              </button>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    );
  }
}

interface SelectedPlaceholderProps {
  placeholderType: string;
  onPlaceholderClick: () => void;
  optional: boolean;
  disabled: boolean;
  t: (key: string, options?: any) => string;
}

export class SelectedPlaceholder extends React.PureComponent<SelectedPlaceholderProps> {
  render() {
    const { placeholderType, onPlaceholderClick, t, disabled, optional } = this.props;
    return (
      <div
        id={(Array.isArray(placeholderType) ? placeholderType.join("") : placeholderType) + "Placeholder"}
        className="card card-custom card-round gutter-b card-stretch"
        style={{
          boxShadow: "0px 0px 13px 0px rgba(82, 63, 105, 0.1)",
          minHeight: "210px",
          opacity: disabled ? "0.1" : "0.3",
          cursor: disabled ? "not-allowed" : "pointer",
          borderWidth: "2px",
          borderStyle: "dashed",
        }}
        onClick={onPlaceholderClick}
      >
        <div className="card-body d-flex flex-column p-0">
          <h3 className="text-center my-auto">
            {Array.isArray(placeholderType)
              ? placeholderType.map((item, index) => {
                  return (
                    <React.Fragment key={index.toString()}>
                      {t("packaging:" + item)}
                      {index < placeholderType.length - 1 && (
                        <>
                          <br />
                          {t("packaging:or")}
                          <br />
                        </>
                      )}
                    </React.Fragment>
                  );
                })
              : t("packaging:" + placeholderType!)}
            <br />
            {optional && t("optional")}
          </h3>
        </div>
      </div>
    );
  }
}
