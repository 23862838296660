import _ from "lodash";
import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import { RecipeItem } from "./RecipeItem";
import SearchBox from "../common/SearchBox";
import configuratorUtils from "../../utils/configuratorUtils";
import { COMMODITIESFILTER } from "./configuratorDataFilter";
import { CAPSULES_TAB } from "./ConfiguratorTabs";
import { ColorsDocument } from "../../model/colors.types";

interface RecipeSelectionProps {
  activeTab: string;
  onRecipeAdd: any;
  onRecipeDelete: any;
  onRecipeAmountChange: any;
  commodities: Array<any>;
  recipe: Array<any>;
  t: (key: string, options?: any) => string;
  currentVolume: number;
  maxVolume?: number;
  showInfo: boolean;
  colors: Array<ColorsDocument>;
}

interface RecipeSelectionState {
  commoditiesShown: number;
  searchQuery: string;
}

class RecipeSelection extends Component<RecipeSelectionProps, RecipeSelectionState> {
  _filteredCommodities: Array<any> = [];
  constructor(props: RecipeSelectionProps) {
    super(props);
    this._filteredCommodities = this.filteredCommodities();
    this.state = { commoditiesShown: 20, searchQuery: "" };
  }

  handleShowMore = () => {
    const { commoditiesShown } = this.state;
    this.setState({ commoditiesShown: commoditiesShown + 20 });
  };

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      searchQuery: e.target.value.toLocaleLowerCase(),
    });
  };

  handleRecipeFill = () => {
    const { currentVolume, maxVolume, commodities, t, onRecipeAdd, onRecipeAmountChange, recipe } = this.props;
    const filler = commodities.find((commodity) => commodity._id.toString() === "5e6934b3aabd7e11ed06e7a2");
    if (!filler) toast.error(t("noFiller"));
    else {
      const existingFiller = recipe.find((com) => com._id.toString() === filler._id.toString());
      const remainingVolume = maxVolume! - currentVolume;
      const fillerDensity = filler.density ? filler.density : 0.5;
      // m = p * V in gram
      const amount = fillerDensity * remainingVolume;
      // Update filler amount
      if (existingFiller) onRecipeAmountChange(filler._id.toString(), amount * 1000 + existingFiller.amount);
      // Add commodity with amount in mg
      else onRecipeAdd(filler, amount * 1000);
    }
  };

  doCommoditySearch = () => {
    const { searchQuery } = this.state;
    if (searchQuery === "") {
      return this._filteredCommodities;
    }
    return configuratorUtils.doCommoditySearch(this._filteredCommodities, searchQuery);
  };

  filteredCommodities = () => {
    const { activeTab, commodities } = this.props;
    const filter = _.get(COMMODITIESFILTER, activeTab);
    // Filter custom products and disabled commodities
    const relevantCommodities: Array<any> = commodities.filter((com) => !(com.type || com.disabled));
    let filterCommodities: Array<any> = [];
    // included filter
    for (let i = 0; i < filter.included.length; i++) {
      let fil = filter.included[i];
      filterCommodities = filterCommodities.concat(
        relevantCommodities.filter(
          (com) =>
            // Abort early if commodity is already in filtered
            filterCommodities.indexOf(com) < 0 && fil[1].includes(_.get(com, fil[0])?.toString())
        )
      );
    }
    // excluded filter
    for (let i = 0; i < filter.excluded.length; i++) {
      let fil = filter.excluded[i];
      filterCommodities = filterCommodities.filter((com) => !fil[1].includes(_.get(com, fil[0])?.toString()));
    }

    return filterCommodities;
  };

  shouldComponentUpdate(
    nextProps: Readonly<RecipeSelectionProps>,
    nextState: Readonly<RecipeSelectionState>,
    nextContext: any
  ): boolean {
    return (
      nextProps.commodities.length !== this.props.commodities.length ||
      nextProps.recipe.length !== this.props.recipe.length ||
      nextProps.recipe !== this.props.recipe ||
      nextState.searchQuery !== this.state.searchQuery ||
      nextState.commoditiesShown !== this.state.commoditiesShown ||
      nextProps.currentVolume !== this.props.currentVolume ||
      nextProps.maxVolume !== this.props.maxVolume ||
      nextProps.showInfo !== this.props.showInfo
    );
  }

  render() {
    const {
      activeTab,
      colors,
      t,
      onRecipeAdd,
      onRecipeDelete,
      onRecipeAmountChange,
      recipe,
      currentVolume,
      maxVolume,
      showInfo,
    } = this.props;
    const { commoditiesShown, searchQuery } = this.state;
    const volumeInfoText =
      maxVolume && currentVolume > maxVolume && showInfo
        ? t("estimatedVolumeExceedsMax") + t("estimatedVolumeInformation")
        : showInfo
        ? t("estimatedVolumeInformation")
        : maxVolume && currentVolume > maxVolume
        ? t("estimatedVolumeExceedsMax")
        : "";
    const volumeTextClass =
      maxVolume && currentVolume > maxVolume ? "text-danger" : showInfo ? "text-warning" : "text-muted";
    const commodities = this.doCommoditySearch();
    return (
      <>
        <span className="text-dark-75  font-size-h3 font-weight-bold mr-3 pt-5">{t("recipe")}</span>
        <div className="container-fluid ml-0 mr-0 pr-0 pl-0 mt-10" id="recipeSelection">
          <div className="row mt-5">
            <div className=" col-md-6 mb-2">
              <div
                className="custom-board card-stretch"
                id="commoditiesCard"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "white",
                }}
              >
                <header className="custom-board-header" style={{ minHeight: "70px" }}>
                  <div className="d-flex justify-content-between flex-wrap mt-1">
                    <div className="d-flex mr-0 ml-2 mb-2">
                      <div className="custom-title-board">
                        {`${t("commodities")} (${commodities.length} ${t("found")})`}
                      </div>
                    </div>
                    <div className="ml-2">
                      <SearchBox
                        title={t("search")}
                        onSearch={this.handleSearch}
                        additionalClasses={"rounded-lg border-0 form-control-sm"}
                        placeholder={t("searchPlaceholder")}
                        idSuffix="Commodities"
                        value={searchQuery}
                      />
                    </div>
                  </div>
                </header>
                <main className="custom-drag overflow-auto" style={{ minHeight: "800px", maxHeight: "800px" }}>
                  {commodities.slice(0, commoditiesShown).map((commodity) => {
                    return (
                      <div key={commodity._id.toString()}>
                        <RecipeItem
                          type={"add"}
                          color={
                            commodity.color && colors.find((c) => commodity.color.toHexString() === c._id.toHexString())
                          }
                          commodity={commodity}
                          onRecipeItem={onRecipeAdd}
                          t={t}
                          disabled={recipe.some((entry) => entry._id.toString() === commodity._id.toString())}
                        />
                      </div>
                    );
                  })}
                  {commodities.length > commoditiesShown && (
                    <div key="showMore">
                      <div className="text-center ">
                        <button
                          className="m-0 p-0 font-size-lg font-weight-bolder button-link"
                          onClick={this.handleShowMore}
                        >
                          {t("showMore")}
                        </button>
                      </div>
                    </div>
                  )}
                </main>
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div
                className="custom-board card-stretch"
                id="recipeCard"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "white",
                }}
              >
                <header className="custom-board-header" style={{ minHeight: "70px" }}>
                  <div className="d-flex justify-content-between flex-wrap mt-1">
                    <div className="d-flex mr-0 ml-2">
                      <div className="custom-title-board">{t("recipe")}</div>
                      {activeTab === CAPSULES_TAB && (
                        <div className="ml-2">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="fillerInfo">{t("fillerInfo")}</Tooltip>}
                          >
                            <button className="btn btn-icon btn-sm pb-2" onClick={this.handleRecipeFill}>
                              <i id="fillCapsule" className="fa fa-magic text-primary" />
                            </button>
                          </OverlayTrigger>
                        </div>
                      )}
                    </div>
                    <div className="d-block text-right ml-2">
                      <span id="estimatedVolume" className={volumeTextClass} style={{ fontSize: "1rem" }}>
                        {t("estimatedVolume")} {+currentVolume.toFixed(2)}
                        {!maxVolume ? "ml" : ` / ${+maxVolume.toFixed(2)} ml`}
                      </span>
                      {volumeInfoText && (
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="estimatedVolumeInfo">{volumeInfoText}</Tooltip>}
                        >
                          <i className={`fa fa-info-circle ml-2 ${volumeTextClass}`} />
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                </header>
                <main className="custom-drag overflow-auto" style={{ maxHeight: "800px" }}>
                  {recipe.map((commodity, index) => {
                    return (
                      <div key={index.toString()}>
                        <RecipeItem
                          type={"delete"}
                          color={
                            commodity.color && colors.find((c) => commodity.color.toHexString() === c._id.toHexString())
                          }
                          commodity={commodity}
                          onRecipeItem={onRecipeDelete}
                          onRecipeAmountChange={onRecipeAmountChange}
                          t={t}
                        />
                      </div>
                    );
                  })}
                </main>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RecipeSelection;
