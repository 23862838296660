import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class People extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <section
        id="down-section"
        className="padding-100px-top md-padding-70px-top md-padding-40px-bottom sm-padding-50px-top xs-padding-20px-top sm-padding-25px-bottom"
      >
        <div className="container-fluid padding-seven-lr xl-padding-three-lr md-padding-2-half-rem-lr xs-padding-15px-lr">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 text-center margin-4-half-rem-bottom sm-margin-3-rem-bottom">
              <span className="alt-font text-extra-medium">{t("title78")}</span>
              <h3 className="alt-font font-weight-600 text-extra-dark-gray">{t("title79")}</h3>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 justify-content-center">
            {/* start team item */}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/1.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Fabian Geisler
                  </div>
                  <span className="text-small text-uppercase">{t("title80")}</span>
                </figcaption>
              </figure>
            </div>
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/2.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Burak Gelir
                  </div>
                  <span className="text-small text-uppercase">{t("title80")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/3.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Julian Haag
                  </div>
                  <span className="text-small text-uppercase">{t("title80")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            {/*<div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/4.jpg" />
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Jana Papke
                  </div>
                  <span className="text-small text-uppercase">{t("title81")}</span>
                </figcaption>
              </figure>
            </div>{" "}*/}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/5.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Christoph Stachelek
                  </div>
                  <span className="text-small text-uppercase">{t("title81")} (CEO)</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/6.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Tayfun Bati
                  </div>
                  <span className="text-small text-uppercase">{t("title81")} (COO)</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/daniel.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Marius Holzapfel
                  </div>
                  <span className="text-small text-uppercase">Senior Sales Manager</span>
                </figcaption>
              </figure>
            </div>
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/8.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Janina-Magdalena Norden
                  </div>
                  <span className="text-small text-uppercase">{t("title82")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/9.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Robert Weiss
                  </div>
                  <span className="text-small text-uppercase">{t("title82")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/7.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Pierre Sönnichsen
                  </div>
                  <span className="text-small text-uppercase">{t("title83")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/16.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Václava Revajová
                  </div>
                  <span className="text-small text-uppercase">{t("title84")} Leitmeritz</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/13.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Michal Revaj
                  </div>
                  <span className="text-small text-uppercase">{t("title85")} Leitmeritz</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/14.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Martina Hartmanová
                  </div>
                  <span className="text-small text-uppercase">{t("title86")} Leitmeritz</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/10.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Laura Schneider
                  </div>
                  <span className="text-small text-uppercase">
                    {t("title88")} Pirmasens<sup>*</sup>
                  </span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/11.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Jasmin König
                  </div>
                  <span className="text-small text-uppercase">
                    {t("title86")} Pirmasens<sup>*</sup>
                  </span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/12.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Bärbel Stucky
                  </div>
                  <span className="text-small text-uppercase">
                    {t("title87")} Pirmasens<sup>*</sup>
                  </span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/28.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Robert Dienelt
                  </div>
                  <span className="text-small text-uppercase">{t("title89")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/17.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Jarmila Tetřevová
                  </div>
                  <span className="text-small text-uppercase">{t("title90")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/18.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Jana Somolíková
                  </div>
                  <span className="text-small text-uppercase">{t("title90")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/19.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Veronika Jersáková
                  </div>
                  <span className="text-small text-uppercase">{t("title90")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/20.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Jaroslava Dicková
                  </div>
                  <span className="text-small text-uppercase">{t("title90")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/21.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Janka Brázdová
                  </div>
                  <span className="text-small text-uppercase">{t("title90")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/22.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Kristýna Fialová
                  </div>
                  <span className="text-small text-uppercase">{t("title90")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/23.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Petra Ševčíková
                  </div>
                  <span className="text-small text-uppercase">{t("title90")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/24.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Michaela Dufková
                  </div>
                  <span className="text-small text-uppercase">{t("title90")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/25.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Andrea Soldátová
                  </div>
                  <span className="text-small text-uppercase">{t("title90")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/26.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Martina Šandová
                  </div>
                  <span className="text-small text-uppercase">{t("title90")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/27.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Olena Ardilan
                  </div>
                  <span className="text-small text-uppercase">{t("title90")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/29.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Tomáš Hubálek
                  </div>
                  <span className="text-small text-uppercase">{t("title90")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/30.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Jakub Bušek
                  </div>
                  <span className="text-small text-uppercase">{t("title90")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/31.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Michal Jersák
                  </div>
                  <span className="text-small text-uppercase">{t("title90")}</span>
                </figcaption>
              </figure>
            </div>
            {" "}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/32.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Marc Kunz
                  </div>
                  <span className="text-small text-uppercase">
                    {t("title89")} Pirmasens<sup>*</sup>
                  </span>
                </figcaption>
              </figure>
            </div>
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/33.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Stefanie Hosseus
                  </div>
                  <span className="text-small text-uppercase">
                    {t("title90")}
                    <sup>*</sup>
                  </span>
                </figcaption>
              </figure>
            </div>
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/34.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Felix Zapp
                  </div>
                  <span className="text-small text-uppercase">
                    {t("title90")}
                    <sup>*</sup>
                  </span>
                </figcaption>
              </figure>
            </div>
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/35.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Christian Polony
                  </div>
                  <span className="text-small text-uppercase">
                    {t("title90")}
                    <sup>*</sup>
                  </span>
                </figcaption>
              </figure>
            </div>
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/36.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Marcel Steiner
                  </div>
                  <span className="text-small text-uppercase">
                    {t("title90")}
                    <sup>*</sup>
                  </span>
                </figcaption>
              </figure>
            </div>
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/37.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Patricia Dahl
                  </div>
                  <span className="text-small text-uppercase">
                    {t("title90")}
                    <sup>*</sup>
                  </span>
                </figcaption>
              </figure>
            </div>
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/38.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Stefanie Jennewein
                  </div>
                  <span className="text-small text-uppercase">
                    {t("title90")}
                    <sup>*</sup>
                  </span>
                </figcaption>
              </figure>
            </div>
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/39.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Dirk Bossert
                  </div>
                  <span className="text-small text-uppercase">
                    {t("title90")}
                    <sup>*</sup>
                  </span>
                </figcaption>
              </figure>
            </div>
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/40.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    André Balistreri
                  </div>
                  <span className="text-small text-uppercase">
                    {t("title90")}
                    <sup>*</sup>
                  </span>
                </figcaption>
              </figure>
            </div>
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/daniel.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Mika Rollenmiller
                  </div>
                  <span className="text-small text-uppercase">{t("title91")}</span>
                </figcaption>
              </figure>
            </div>
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/daniel.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Daniel Robin
                  </div>
                  <span className="text-small text-uppercase">{t("title91")}</span>
                </figcaption>
              </figure>
            </div>
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/daniel.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Amelie Kassner
                  </div>
                  <span className="text-small text-uppercase">{t("title91")}</span>
                </figcaption>
              </figure>
            </div>
            {/*<div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/daniel.jpg" />
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    André Moehrke
                  </div>
                  <span className="text-small text-uppercase">{t("title91")}</span>
                </figcaption>
              </figure>
            </div>*/}
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/99.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Elliot Schneider
                  </div>
                  <span className="text-small text-uppercase">
                    {t("title92")}
                    <sup>*</sup>
                  </span>
                </figcaption>
              </figure>
            </div>
            <div className="col team-style-03 text-center">
              <figure>
                <div className="team-member-image border-radius-5px overflow-hidden">
                  <img alt="" src="images/people/100.jpg"/>
                </div>
                <figcaption className="team-member-position text-center padding-35px-tb sm-padding-25px-tb">
                  <div
                      className="text-extra-dark-gray alt-font line-height-18px text-medium text-uppercase font-weight-500">
                    Bolek Stachelek
                  </div>
                  <span className="text-small text-uppercase">{t("title93")}</span>
                </figcaption>
              </figure>
            </div>
          </div>
          {" "}
        </div>
      </section>
    );
  }
}

export default withTranslation("general")(People);
