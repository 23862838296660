import _ from "lodash";
import { CustomPackagingsDocument, SelectedPackagingsDocument } from "./CustomTypes";
import { CAPSULES_TAB, CUSTOM_TAB, LIQUID_TAB, POWDER_TAB, SOFTGELS_TAB, TABLETS_TAB } from "./ConfiguratorTabs";
import configuratorUtils from "../../utils/configuratorUtils";
import { COMMODITIESFILTER, PACKAGINGCOMBINATIONS } from "./configuratorDataFilter";
import languageUtils from "../../utils/languageUtils";

/**
 * Validate the current configuration
 * @param activeTab the current tab
 * @param preferences configuration preferences
 * @param recipe configuration recipe
 * @param packaging selected packaging
 * @param t translation function
 * @returns Object with errors, warnings and information/hints
 */
function validateConfiguration(
  activeTab: string,
  preferences: any,
  recipe: Array<any>,
  packaging: Array<SelectedPackagingsDocument>,
  t: (key: string, options?: any) => string
) {
  let result: any;
  switch (activeTab) {
    case CAPSULES_TAB:
      result = validateCapsuleConfiguration(preferences, recipe, packaging, t);
      break;
    case TABLETS_TAB:
      result = validateTabletConfiguration(preferences, recipe, packaging, t);
      break;
    case POWDER_TAB:
      result = validatePowderConfiguration(preferences, recipe, packaging, t);
      break;
    case LIQUID_TAB:
      result = validateLiquidConfiguration(preferences, recipe, packaging, t);
      break;
    case SOFTGELS_TAB:
      result = validateSoftgelConfiguration(preferences, recipe, packaging, t);
      break;
    case CUSTOM_TAB:
      result = validateOtherConfiguration(preferences, recipe, t);
      break;
  }
  if (result.error.length === 0 && result.warn.length === 0 && result.info.length === 0) return null;
  return result;
}

/**
 * Validate a capsule configuration
 * @param preferences configuration preferences
 * @param recipe configuration recipe
 * @param packaging selected packaging
 * @param t translation function
 * @returns Object with errors, warnings and information/hints
 */
function validateCapsuleConfiguration(
  preferences: any,
  recipe: Array<any>,
  packaging: Array<SelectedPackagingsDocument>,
  t: (key: string, options?: any) => string
) {
  const { selectedCapsule, amountPerUnit } = preferences;
  let [torsoType, result] = validateAllSelectedPackaging(CAPSULES_TAB, preferences, packaging, t);
  // Recipe validation
  concat(result, validateRecipe(CAPSULES_TAB, recipe, t));
  // Capsule volume validation
  const volume = +selectedCapsule.capsule_volume * +amountPerUnit * 2;
  const { value: recipeVolume, noDefault } = configuratorUtils.getRecipeVolume(recipe);
  if (recipeVolume > +selectedCapsule.capsule_volume) {
    const factor = noDefault ? 1.02 : 1.5;
    if (recipeVolume > +selectedCapsule.capsule_volume * factor)
      result.warn.push(
        t("estimatedVolumeTooLarge", { volume: +recipeVolume.toFixed(2) }) +
          t("forCapsule", { capsuleVolume: selectedCapsule.capsule_volume })
      );
    else
      result.info.push(
        t("estimatedVolumeSlightlyTooLarge", { volume: +recipeVolume.toFixed(2) }) +
          t("forCapsule", { capsuleVolume: selectedCapsule.capsule_volume })
      );
  }
  if (recipeVolume < +selectedCapsule.capsule_volume) {
    const factor = noDefault ? 0.6 : 0.4;
    if (recipeVolume < +selectedCapsule.capsule_volume * factor)
      result.warn.push(
        t("estimatedVolumeTooSmall", { volume: +recipeVolume.toFixed(2) }) +
          t("forCapsule", { capsuleVolume: selectedCapsule.capsule_volume }) +
          "." +
          t("useFiller")
      );
    else
      result.info.push(
        t("estimatedVolumeSlightlyTooSmall", { volume: +recipeVolume.toFixed(2) }) +
          t("forCapsule", { capsuleVolume: selectedCapsule.capsule_volume }) +
          "." +
          t("useFiller")
      );
  }
  // Extended packaging validation
  let packagingResult: any;
  if (torsoType)
    switch (torsoType) {
      case "blister":
        packagingResult = validateBlisterPackaging(packaging, +amountPerUnit, t);
        break;
      case "bottle":
        packagingResult = validateBottlePackaging(packaging, volume, false, t);
        break;
      case "bag":
        packagingResult = validateBagPackaging(packaging, volume, false, t);
        break;
    }
  concat(result, packagingResult);
  return result;
}

/**
 * Validate a tablet configuration
 * @param preferences configuration preferences
 * @param recipe configuration recipe
 * @param packaging selected packaging
 * @param t translation function
 * @returns Object with errors, warnings and information/hints
 */
function validateTabletConfiguration(
  preferences: any,
  recipe: Array<any>,
  packaging: Array<SelectedPackagingsDocument>,
  t: (key: string, options?: any) => string
) {
  const { selectedTablet, amountPerUnit } = preferences;
  let [torsoType, result] = validateAllSelectedPackaging(TABLETS_TAB, preferences, packaging, t);
  // Recipe validation
  concat(result, validateRecipe(TABLETS_TAB, recipe, t));
  // Tablet volume
  const volume = +selectedTablet.volume * +amountPerUnit * 2;
  const { value: recipeVolume, noDefault } = configuratorUtils.getRecipeVolume(recipe);
  if (recipeVolume > +selectedTablet.volume) {
    const factor = noDefault ? 1.02 : 1.5;
    if (recipeVolume > +selectedTablet.volume * factor)
      result.warn.push(
        t("estimatedVolumeTooLarge", { volume: +recipeVolume.toFixed(2) }) +
          t("forTablet", { tabletVolume: selectedTablet.volume })
      );
    else
      result.info.push(
        t("estimatedVolumeSlightlyTooLarge", { volume: +recipeVolume.toFixed(2) }) +
          t("forTablet", { tabletVolume: selectedTablet.volume })
      );
  }
  // Extended packaging validation
  let packagingResult: any;
  if (torsoType)
    switch (torsoType) {
      case "bottle":
        packagingResult = validateBottlePackaging(packaging, volume, false, t);
        break;
      case "bag":
        packagingResult = validateBagPackaging(packaging, volume, false, t);
        break;
    }
  concat(result, packagingResult);
  return result;
}

/**
 * Validate a powder configuration
 * @param preferences configuration preferences
 * @param recipe configuration recipe
 * @param packaging selected packaging
 * @param t translation function
 * @returns Object with errors, warnings and information/hints
 */
function validatePowderConfiguration(
  preferences: any,
  recipe: Array<any>,
  packaging: Array<SelectedPackagingsDocument>,
  t: (key: string, options?: any) => string
) {
  let [torsoType, result] = validateAllSelectedPackaging(POWDER_TAB, preferences, packaging, t);
  // Recipe validation
  concat(result, validateRecipe(POWDER_TAB, recipe, t));
  // Recipe volume
  const { value: volume, noDefault } = configuratorUtils.getRecipeVolume(recipe);
  // Extended packaging validation
  let packagingResult: any;
  if (torsoType)
    switch (torsoType) {
      case "bottle":
        packagingResult = validateBottlePackaging(packaging, volume, !noDefault, t);
        break;
      case "bag":
        packagingResult = validateBagPackaging(packaging, volume, !noDefault, t);
        break;
    }
  concat(result, packagingResult);
  return result;
}

/**
 * Validate a liquid configuration
 * @param preferences configuration preferences
 * @param recipe configuration recipe
 * @param packaging selected packaging
 * @param t translation function
 * @returns Object with errors, warnings and information/hints
 */
function validateLiquidConfiguration(
  preferences: any,
  recipe: Array<any>,
  packaging: Array<SelectedPackagingsDocument>,
  t: (key: string, options?: any) => string
) {
  let [, result] = validateAllSelectedPackaging(LIQUID_TAB, preferences, packaging, t);
  // Recipe validation
  concat(result, validateRecipe(LIQUID_TAB, recipe, t));
  // Recipe volume
  const { value: volume, noDefault } = configuratorUtils.getRecipeVolume(recipe);
  // Extended packaging validation
  let packagingResult = validateBottlePackaging(packaging, volume, !noDefault, t, true);
  concat(result, packagingResult);
  return result;
}

/**
 * Validate a softgel configuration
 * @param preferences configuration preferences
 * @param recipe configuration recipe
 * @param packaging selected packaging
 * @param t translation function
 * @returns Object with errors, warnings and information/hints
 */
function validateSoftgelConfiguration(
  preferences: any,
  recipe: Array<any>,
  packaging: Array<SelectedPackagingsDocument>,
  t: (key: string, options?: any) => string
) {
  let [, result] = validateAllSelectedPackaging(SOFTGELS_TAB, preferences, packaging, t);
  // Recipe validation
  concat(result, validateCustomRecipe("softgel", recipe, t));
  // No extended packaging validation
  return result;
}

/**
 * Validate a custom product configuration
 * @param preferences configuration preferences
 * @param recipe configuration recipe
 * @param t translation function
 * @returns Object with errors, warnings and information/hints
 */
function validateOtherConfiguration(preferences: any, recipe: Array<any>, t: (key: string, options?: any) => string) {
  let result = validateCustomRecipe("purchased", recipe, t);
  // No extended packaging validation
  return result;
}

/**
 * Validates bottle packaging for given volume and other packaging
 * @param packaging all selected packaging
 * @param volume the volume to fit inside the bottle
 * @param useMargin flag if a bigger margin should be used or not e.g. due to unknown density values
 * @param t translation function
 * @param liquid flag if it is a liquid bottle or not
 * @returns Object with errors, warnings and information/hints
 */
function validateBottlePackaging(
  packaging: Array<SelectedPackagingsDocument>,
  volume: number,
  useMargin: boolean,
  t: (key: string, options?: any) => string,
  liquid?: boolean
) {
  let result: any = { error: [], warn: [], info: [] };
  // Check bottle and volume
  const bottle = liquid
    ? packaging.find(pack => pack.packaging_type === "liquidbottle")
    : packaging.find(pack => pack.packaging_type === "bottle");
  if (!bottle) return result;
  const bottleVolume = +bottle.packaging_volume!;
  if (bottleVolume < volume) {
    const factor = useMargin ? 1.5 : 1.02;
    if (bottleVolume * factor < volume)
      result.warn.push(
        t("estimatedVolumeTooLarge", { volume: +volume.toFixed(2) }) + t("forBottle", { volume: bottleVolume })
      );
    else
      result.info.push(
        t("estimatedVolumeSlightlyTooLarge", { volume: +volume.toFixed(2) }) + t("forBottle", { volume: bottleVolume })
      );
  }

  // Check lid
  const lid = liquid
    ? packaging.find(pack => ["lid", "pipette"].includes(pack.packaging_type))
    : packaging.find(pack => pack.packaging_type === "lid");
  if (!lid) {
    result.error.push(t("noClosureFound"));
    return result;
  }
  const lidNeck = lid.packaging_type === "lid" ? lid.lid_size : lid.packaging_neck;
  if (lid.packaging_type === "lid" && !configuratorUtils.compareNeckSize(bottle.packaging_neck, lidNeck))
    result.error.push(t("lidNotFit", { size: lidNeck, neckSize: bottle.packaging_neck }));
  if (
    lid.packaging_type === "pipette" &&
    !configuratorUtils.comparePipetteAndBottle(
      lidNeck,
      lid.packaging_height,
      bottle.packaging_neck,
      bottle.packaging_height
    )
  )
    result.error.push(
      t("pipetteNotFit", {
        size1: lidNeck,
        height1: lid.packaging_height,
        size2: bottle.packaging_neck,
        height2: bottle.packaging_height
      })
    );

  // Check label
  const label = packaging.find(pack => pack.packaging_type === "label");
  if (label)
    if (
      !configuratorUtils.compareLabelAndBottle(
        label.label_width,
        label.label_height,
        bottle.packaging_label_height,
        bottle.packaging_width
      )
    )
      result.error.push(
        t("labelNotFitBottle", {
          height: label.label_height,
          width: label.label_width,
          labelHeight: bottle.packaging_label_height,
          diameter: bottle.packaging_width
        })
      );

  // Check sleeve
  const sleeve = packaging.find(pack => pack.packaging_type === "sleeve");
  if (sleeve)
    if (!configuratorUtils.compareSleeveAndLid(sleeve.sleeve_size, lidNeck))
      result.error.push(t("sleeveNotFit", { size: sleeve.sleeve_size, neckSize: lidNeck }));

  // Check box
  const box = packaging.find(pack => pack.packaging_type === "box");
  if (box) {
    const bottleDimension = [bottle.packaging_width, bottle.packaging_height];
    const boxDimension = [box.box_width, box.box_height, box.box_depth];
    if (!configuratorUtils.compareBottleAndBox(boxDimension, bottleDimension))
      result.error.push(
        t("boxNotFit", { dimension1: boxDimension.join(" x "), dimension2: bottleDimension.join(" x ") })
      );
  }

  return result;
}

/**
 * Validates blister packaging for given volume and other packaging
 * @param packaging all selected packaging
 * @param amountPerUnit the preferences amount per unit
 * @param t translation function
 * @returns Object with errors, warnings and information/hints
 */
function validateBlisterPackaging(
  packaging: Array<SelectedPackagingsDocument>,
  amountPerUnit: number,
  t: (key: string, options?: any) => string
) {
  let result: any = { error: [], warn: [], info: [] };
  // Check blister and amount
  const blister = packaging.find(pack => pack.packaging_type === "blister");
  if (!blister) return result;
  if (blister && amountPerUnit % +blister.blister_capsules! !== 0)
    result.error.push(t("blisterNotFit", { amount: amountPerUnit, amountBlister: blister.blister_capsules }));
  else if (blister && amountPerUnit / +blister.blister_capsules! !== blister.amount)
    result.error.push(
      t("blisterSelectedNotFitRequired", {
        amount1: blister.amount,
        amount2: amountPerUnit / +blister.blister_capsules!
      })
    );

  // Check box
  const box = packaging.find(pack => pack.packaging_type === "box");
  if (box) {
    const blisterDimension = [blister.blister_width, blister.blister_height, blister.blister_depth];
    const boxDimension = [box.box_width, box.box_height, box.box_depth];
    if (!configuratorUtils.compareBlisterAndBox(+blister.amount!, boxDimension, blisterDimension))
      result.error.push(
        t("boxNotFitBlister", {
          amount: blister.amount,
          dimension1: blisterDimension.join(" x "),
          dimension2: boxDimension.join(" x ")
        })
      );
  }
  return result;
}

/**
 * Validates bag packaging for given volume and other packaging
 * @param packaging all selected packaging
 * @param volume the volume to fit inside the bottle
 * @param useMargin flag if a bigger margin should be used or not e.g. due to unknown density values
 * @param t translation function
 * @returns Object with errors, warnings and information/hints
 */
function validateBagPackaging(
  packaging: Array<SelectedPackagingsDocument>,
  volume: number,
  useMargin: boolean,
  t: (key: string, options?: any) => string
) {
  let result: any = { error: [], warn: [], info: [] };
  // Check bag and volume
  const bag = packaging.find(pack => pack.packaging_type === "bag");
  if (!bag) return result;
  const bagVolume = +bag.bag_volume!;
  if (bag && bagVolume < volume) {
    const factor = useMargin ? 1.5 : 1.02;
    if (bagVolume * factor < volume)
      result.warn.push(
        t("estimatedVolumeTooLarge", { volume: +volume.toFixed(2) }) + t("forBag", { volume: bagVolume })
      );
    else
      result.info.push(
        t("estimatedVolumeSlightlyTooLarge", { volume: +volume.toFixed(2) }) + t("forBag", { volume: bagVolume })
      );
  }

  // Check label
  const label = packaging.find(pack => pack.packaging_type === "label");
  if (label) {
    if (!configuratorUtils.compareLabelAndBag(label.label_width, label.label_height, bag.bag_width, bag.bag_height)) {
      result.error.push(
        t("labelNotFitBag", {
          height1: label.label_height,
          width1: label.label_width,
          height2: bag.bag_height,
          width2: bag.bag_width
        })
      );
    }
  }
  return result;
}

/**
 * Validate a recipe
 * @param tab current tab
 * @param recipe list of all selected commodities
 * @param t translation function
 * @returns Object with errors, warnings and information/hints
 */
function validateRecipe(tab: string, recipe: Array<any>, t: (key: string, options?: any) => string) {
  let result: any = { error: [], warn: [], info: [] };
  const { included, excluded } = COMMODITIESFILTER[tab];
  recipe.forEach(comm => {
    if (
      !included.some((filter: any) => filter[1].includes(_.get(comm, filter[0]))) ||
      excluded.some((filter: any) => filter[1].includes(_.get(comm, filter[0])))
    )
      result.error.push(t("commodityNotAllowed", { title: languageUtils.resolveTranslation(comm.title) }));
    if (comm.toxic_amount && +comm.toxic_amount < +comm.amount)
      result.warn.push(
        t("commodityExceedsToxic", {
          title: languageUtils.resolveTranslation(comm.title),
          amount: configuratorUtils.formatAmount(comm.amount.toString()).replace(" ", ""),
          toxic: comm.toxic_amount
        })
      );
  });
  return result;
}

/**
 * Validate a recipe of custom products
 * @param type softgel or custom
 * @param recipe list of all selected commodities
 * @param t translation function
 * @returns Object with errors, warnings and information/hints
 */
function validateCustomRecipe(type: string, recipe: Array<any>, t: (key: string, options?: any) => string) {
  let result: any = { error: [], warn: [], info: [] };
  if (recipe.length !== 1) result.error.push(t("onlyOneProduct"));
  if (recipe[0].type !== type)
    result.error.push(t("productNotAllowed", { title: languageUtils.resolveTranslation(recipe[0].title) }));
  return result;
}

/**
 * Validate selected packaging
 * @param tab current tab / product
 * @param preferences object with preferences
 * @param packaging all selected packaging
 * @param t translation function
 * @returns Object with errors, warnings and information/hints
 */
function validateAllSelectedPackaging(
  tab: string,
  preferences: any,
  packaging: Array<SelectedPackagingsDocument>,
  t: (key: string, options?: any) => string
): [string | null, { error: Array<string>; warn: Array<string>; info: Array<string> }] {
  let result: any = { error: [], warn: [], info: [] };
  const torsoTypes = configuratorUtils.getTorsoTypeForProduct(tab, preferences);
  let torsoType = packaging.find(pack => torsoTypes.includes(pack.packaging_type))?.packaging_type;
  if (!torsoType) {
    result.error.push(t("noTorsoFound"));
    return [null, result];
  }
  let torsoCount = 0;
  packaging.forEach(pack => torsoTypes.includes(pack.packaging_type) && torsoCount++);
  if (torsoCount > 1) {
    result.error.push(t("multipleTorsoSelected"));
    return [null, result];
  }
  // Check only allowed and all mandatory types exist
  const mandatoryPackaging = [...PACKAGINGCOMBINATIONS[torsoType].mandatory];
  const possibleSelection: Array<string> = mandatoryPackaging.concat(PACKAGINGCOMBINATIONS[torsoType].optional);

  if (!configuratorUtils.isMandatorySelected(mandatoryPackaging, packaging)) result.error.push(t("mandatoryMissing"));

  packaging.forEach(pack => {
    if (!isPackagingValid(possibleSelection, pack) && pack.packaging_type !== torsoType)
      result.error.push(
        t("packagingNotAllowedTorso", {
          type: t("packaging:" + pack.packaging_type),
          torso: t("packaging" + torsoType)
        })
      );
    if (pack.packaging_type !== "blister" && pack.amount! > 1)
      result.info.push(
        t("unexpectedPackagingAmount", { amount: pack.amount, type: t("packaging:" + pack.packaging_type) })
      );
  });
  return [torsoType, result];
}

/**
 * Check if packaging is valid
 * @param possibleSelection the possible/allowed packaging selection
 * @param packaging selected packaging object
 * @returns True if packaging is allowed/valid, else False
 */
function isPackagingValid(possibleSelection: Array<string>, packaging: CustomPackagingsDocument) {
  if (possibleSelection.includes(packaging.packaging_type)) {
    possibleSelection.splice(possibleSelection.indexOf(packaging.packaging_type), 1);
    return true;
  }
  for (let i = 0; i < possibleSelection.length; i++) {
    let type = possibleSelection[i];
    if (Array.isArray(type) && type.includes(packaging.packaging_type)) {
      possibleSelection.splice(i, 1);
      return true;
    }
  }
  return false;
}

/**
 * Concats two objects with error, warn and info arrays
 * @param object1 object to be mutated with error, warn and info arrays
 * @param object2 object with error, warn and info arrays
 */
function concat(object1: any, object2: any) {
  object1.error = object1.error.concat(object2.error);
  object1.warn = object1.warn.concat(object2.warn);
  object1.info = object1.info.concat(object2.info);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { validateConfiguration };
