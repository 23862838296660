import * as Realm from "realm-web";
import config from "../configs/config.json";

const application = config.application;
const app: Realm.App = new Realm.App({ id: application });

async function login() {
  const credentials = Realm.Credentials.anonymous();
  await app.logIn(credentials);
}

function getUser() {
  return app.currentUser;
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  getUser
};
