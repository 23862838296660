import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class JoinTeam extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <div className="container pb-5 mb-5">
        <div className="row align-items-center justify-content-center">
          <div
            className="col-11 col-xl-5 col-md-6 sm-margin-30px-bottom wow animate__fadeIn"
            data-wow-delay="0.1s"
            style={{
              visibility: "visible",
              animationDelay: "0.1s",
              animationName: "fadeIn",
            }}
          >
            <h3 className="alt-font font-weight-600 text-extra-dark-gray mb-0">{t("title64")}</h3>
          </div>

          <div
            className="col-12 col-xl-5 col-md-4 text-center text-md-right wow animate__fadeIn"
            data-wow-delay="0.2s"
            style={{
              visibility: "visible",
              animationDelay: "0.2s",
              animationName: "fadeIn",
            }}
          >
            <a href="mailto:info@private-label-factory.com" className="btn btn-small btn-dark-gray btn-fancy ">
              {t("button5")}
              <span className="bg-fast-blue" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("general")(JoinTeam);
