import React, { Component } from "react";
import { withTranslation } from "react-i18next";
class Configurator extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <section
        className={"wow animate__fadeIn " + this.props.class}
        style={{ visibility: "visible", animationName: "fadeIn" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-12 col-xl-6 col-lg-6 md-margin-8-rem-bottom xs-margin-10-rem-bottom wow animate__fadeIn"
              data-wow-delay="0.2s"
              style={{
                visibility: "visible",
                animationDelay: "0.2s",
                animationName: "fadeIn",
              }}
            >
              <div className="" data-parallax-layout-ratio="1.1">
                <img src="images/mockup.png" alt="" data-no-retina />
              </div>
            </div>
            <div
              className="col-12 col-xl-6  col-lg-6 wow animate__fadeInRight"
              data-wow-delay="0.4s"
              style={{
                visibility: "visible",
                animationDelay: "0.4s",
                animationName: "fadeInRight",
              }}
            >
              <h3 className="alt-font font-weight-700 text-slate-blue ">{t("title21")}</h3>
              <p className="text-dark font-size-lg">{t("text30")}</p>
              <a href="/configurator" className="btn btn-large btn-slate-blue margin-20px-top">
                {t("button1")}
                <i className="feather icon-feather-arrow-right right-icon" />
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation("general")(Configurator);
