import React, { Component } from "react";

class Line extends Component {
  state = {};
  render() {
    return (
      <div className="container-fluid">
        <div className="bg-medium-light-gray margin-6-rem-bottom margin-3-rem-top w-100 h-1px" />
      </div>
    );
  }
}

export default Line;
