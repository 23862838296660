import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import { withTranslation } from "react-i18next";
class Imprint extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <section>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-lg-12 col-md-12 md-margin-7-rem-bottom">
                <h5 className="alt-font font-weight-600 text-extra-dark-gray w-95">
                  {t("title5")}
                  <br />
                </h5> <br></br>
                <br></br>
                <span className="text-extra-dark-gray text-large">
                  {t("text4")}

                  <br></br>
                  <br></br>
                  <b>Private Label Factory Deutschland GmbH</b> | Philipp-Reis-Straße 7 | 45659 Recklinghausen<br></br>
                  {t("text6")}: AG Recklinghausen | {t("text7")}: HRB 9376
                  <br></br>
                  {t("text8")}
                  <br></br>
                  info@private-label-factory.com | +49 (0)209 51956620 <br></br>
                  <br></br> {t("text9")}
                  <br></br>
                  <br></br>
                </span>{" "}
                <ReactSVG src="images/logo-dark.svg" style={{ width: "160px" }} />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(Imprint);
