import React, { Component } from "react";
import { Image } from "react-bootstrap";
import {toAbsoluteUrl} from "../../utils/helper";
import { CUSTOM_TAB, SOFTGELS_TAB } from "./ConfiguratorTabs";
import languageUtils from "../../utils/languageUtils";
import configuratorUtils from "../../utils/configuratorUtils";
import orderUtils from "../../utils/orderUtils";
import SearchBox from "../common/SearchBox";
import Pagination, { paginate } from "../common/Pagination";
import ConfiguratorHelpers from "./ConfiguratorHelpers";

interface PurchasedSelectionProps {
  activeTab: string;
  onRecipeAdd: any;
  onRecipeDelete: any;
  commodities: Array<any>;
  recipe: Array<any>;
  t: (key: string, options?: any) => string;
}

interface PurchasedSelectionState {
  currentPage: number;
  searchQuery: string;
}

const PAGESIZE = 8;

class PurchasedSelection extends Component<PurchasedSelectionProps, PurchasedSelectionState> {
  _filteredCommodities: Array<any> = [];
  constructor(props: PurchasedSelectionProps) {
    super(props);
    this.state = {
      currentPage: 1,
      searchQuery: ""
    };
  }

  componentDidMount() {
    // Only filter once initially
    this._filteredCommodities = this.filteredCommodities();
    this.forceUpdate();
  }

  shouldComponentUpdate(
    nextProps: Readonly<PurchasedSelectionProps>,
    nextState: Readonly<PurchasedSelectionState>,
    nextContext: any
  ): boolean {
    return (
      nextProps.commodities.length !== this.props.commodities.length ||
      nextProps.recipe !== this.props.recipe ||
      nextState.searchQuery !== this.state.searchQuery ||
      nextState.currentPage !== this.state.currentPage
    );
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      currentPage: 1,
      searchQuery: e.target.value.toLocaleLowerCase()
    });
  };

  handlePageChange = (page: number) => {
    this.setState({ currentPage: page });
  };

  handleRecipeDelete = (ident: number) => {
    this.props.onRecipeDelete(ident);
    // Reset current page as on delete page count might reduce
    this.setState({ currentPage: 1 });
  };

  handleRecipeAdd = (commodity: any) => {
    // Always amount 1
    this.props.onRecipeAdd(commodity, 1);
    // Reset current page
    this.setState({ currentPage: 1 });
  };

  doProductSearch = () => {
    const { recipe } = this.props;
    const { searchQuery } = this.state;
    let products = this._filteredCommodities;
    // ignore product inside recipe
    products = products.filter(com => !recipe.some(r => r._id.toString() === com._id.toString()));

    if (searchQuery === "") {
      return products;
    }
    return configuratorUtils.doCommoditySearch(products, searchQuery);
  };

  filteredCommodities = () => {
    const { activeTab, commodities } = this.props;
    switch (activeTab) {
      case CUSTOM_TAB:
        return commodities.filter(com => com.type === "purchased");
      case SOFTGELS_TAB:
        return commodities.filter(com => com.type === "softgel");
      default:
        return [];
    }
  };

  render() {
    const { t, recipe } = this.props;
    const { searchQuery, currentPage } = this.state;
    const commodities = this.doProductSearch();
    return (
      <>
        <span className="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3">
          {t("productSelection")}
        </span>

        <div className="mt-10" id="purchasedSelection">
          <div style={{ maxWidth: "500px" }}>
            <SearchBox
              title={t("search")}
              onSearch={this.handleSearch}
              additionalClasses={"rounded-lg form-control-sm"}
              placeholder={t("searchPlaceholder")}
              idSuffix="Products"
              value={searchQuery}
            />
          </div>
          <div className="table-responsive mt-2">
            <table className="table table-head-custom table-vertical-center">
              <thead>
                <tr className="text-left">
                  <th className="pr-0 d-none d-lg-table-cell" style={{ minWidth: "50px" }} />
                  <th style={{ minWidth: "150px" }}>{t("title")}</th>
                  <th style={{ minWidth: "300px" }}>{t("description")}</th>
                  <th style={{ minWidth: "150px" }}>{t("properties")}</th>
                </tr>
              </thead>
              <tbody>
                {/*Always display selected product on top*/}
                {recipe.map(r => {
                  return (
                    <React.Fragment key={r._id.toString()}>
                      <PurchasedSelectionRow
                        t={t}
                        commodity={r}
                        onRecipeItem={this.handleRecipeDelete}
                        selected={true}
                      />
                    </React.Fragment>
                  );
                })}
                {paginate(commodities, currentPage, PAGESIZE - recipe.length).map(com => {
                  return (
                    <React.Fragment key={com._id.toString()}>
                      <PurchasedSelectionRow t={t} commodity={com} onRecipeItem={this.handleRecipeAdd} />
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination
          itemsCount={commodities.length + recipe.length}
          pageSize={PAGESIZE - recipe.length}
          currentPage={this.state.currentPage}
          onPageChange={this.handlePageChange}
        />
      </>
    );
  }
}

/**
 * Table body row definition
 */
interface PurchasedSelectionRowProps {
  t: (key: string, options?: any) => string;
  commodity: any;
  onRecipeItem: any;
  selected?: boolean;
}

const PurchasedSelectionRow: React.FunctionComponent<PurchasedSelectionRowProps> = React.memo(props => {
  const { t, selected, commodity, onRecipeItem } = props;
  const title = languageUtils.resolveTranslation(commodity.title);
  const subtitle = languageUtils
    .resolveTranslation(commodity.subtitle)
    .replaceAll(/<br>|<br\/>/g, "")
    .replaceAll(/[\r\n]+/g, "\n", "\n");
  const onRowEnter = (e: React.MouseEvent<HTMLTableRowElement>) => {
    let background = "#f7f8fa";
    if (selected) background = "#b3f8b3";
    e.currentTarget.style.backgroundColor = background;
    e.currentTarget.style.cursor = "pointer";
  };
  const onRowLeave = (e: React.MouseEvent<HTMLTableRowElement>) => {
    let background = "white";
    if (selected) background = "#bbffb9";
    e.currentTarget.style.backgroundColor = background;
  };
  const rowStyle: any = { minHeight: "100px", height: "90px" };
  if (selected) rowStyle.backgroundColor = "#bbffb9";
  return (
    <tr
      onClick={selected ? () => onRecipeItem(commodity.ident) : () => onRecipeItem(commodity)}
      style={rowStyle}
      onMouseEnter={onRowEnter}
      onMouseLeave={onRowLeave}
      id={commodity._id.toString()}
    >
      <td className="py-1 d-none d-lg-table-cell">
        <Image
          className="max-h-45px max-w-45px"
          src={toAbsoluteUrl(
            "/media/products/" + orderUtils.pictureProductMatching(commodity.type ? commodity.type : commodity.form)
          )}
          alt=""
        />
      </td>
      <td style={{ maxWidth: "200px" }}>
        <span className="font-weight-bolder">{title}</span>
      </td>
      <td style={{ maxWidth: "400px" }}>
        <span
          className="text-muted font-weight-bold font-size-sm overflow-auto d-inline-block"
          style={{
            whiteSpace: "pre-line",
            maxHeight: "80px",
            width: "100%"
          }}
          title={subtitle}
        >
          {subtitle}
        </span>
      </td>
      <td style={{ maxWidth: "200px" }}>
        {commodity.properties.map((prop: any, index: number) => {
          const text = languageUtils.resolveTranslation(prop);
          return (
            <span
              key={index.toString()}
              className={`label label-light-info label-lg font-weight-bolder label-inline mb-1 mr-1 ${
                text.length > 25 ? "py-6" : ""
              }`}
            >
              {text}
            </span>
          );
        })}
        {commodity.stock && (
          <>
            <span className="label label-light-primary label-lg font-weight-bolder label-inline mb-1 mr-1">
              {t("inStock")}
            </span>
          </>
        )}
        {ConfiguratorHelpers.renderPriceEstimation(commodity.pricing, t, "label-lg")}
      </td>
    </tr>
  );
});

export default PurchasedSelection;
