import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Stats from "./stats";
import Slider from "react-slick";

class Coins2 extends Component {
  state = {};

  render() {
    const { t } = this.props;

      const settings = {
          autoplay: true,
          autoplaySpeed: 2000,
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,

          responsive: [

              {
                  breakpoint: 480, // Breite für Mobiltelefone
                  settings: {
                      autoplay: true,
                      autoplaySpeed: 2000,
                      dots: true,
                      infinite: true,
                      speed: 500,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                  }
              }
          ]
      };


    return (
      <React.Fragment>
        <section className="big-section bg-light-gray overlap-height overflow-visible md-no-overlap-section sm-mt-5">
          <div className="container sm-mt-5">
            <div className="overlap-section" style={{ marginTop: "-218.5px" }}>
              <div className=" bg-white border-radius-6px padding-3-rem-tb padding-4-rem-lr lg-padding-3-rem-lr sm-padding-1-rem-lr">
                <Stats />
              </div>
            </div>
            {/*<div className="row margin-8-rem-top overlap-gap-section md-margin-6-rem-top">
              <div className="col-12 col-md-6 wow animate__fadeIn" data-wow-delay="0.1s" style={{visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeIn'}}>
                <h5 className="alt-font font-weight-500 line-height-46px letter-spacing-minus-1px w-95 text-dark-purple m-md-0 lg-w-100 sm-line-height-32px">True meditation is about being fully present with everything that is including discomfort</h5>
              </div>
              <div className="col-12 col-lg-5 col-md-6 offset-lg-1 wow animate__fadeIn" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeIn'}}>
                <p className="line-height-32px w-95">We must experience the truth in a direct, practical and real way. This is only possible in the stillness and silence of the mind and this is achieved by means of meditation.</p>
                <a href="#" className="btn btn-link thin btn-extra-large text-dark-purple font-weight-600">Discover about us</a>
              </div>
            </div>*/}

            <div className="col-12 col-lg-12 d-flex flex-row align-items-center justify-content-center margin-8-rem-top margin-8-rem-bottom sm-d-block">
              <span className="col-auto w-40px h-1px bg-dark-gray opacity-3" />
              <h6 className="text-extra-dark-gray alt-font  letter-spacing-1px wow animate__fadeIn">
                <b> {t("update11")}</b> {t("update12")}
              </h6>
              <span className="col-auto w-40px h-1px bg-dark-gray opacity-3" />
            </div>
          </div>
        </section>

        <section className="pt-lg-0 pb-0 overflow-visible">
          <div className="container" style={{ marginTop: "-207.5px" }}>
            <Slider
              {...settings}
              className="row justify-content-center overlap-section "
              style={{ width: "100%", overflow: "hidden" }}
            >
              {" "}
              {/* start rotate box item */}
              <div className="px-3">
                <div
                  style={{
                    backgroundImage: 'url("images/softgel-preview.jpg")',
                    backgroundSize: "cover",
                      backgroundPosition: "center",
                    borderRadius: "4px",
                    overflow: "hidden",
                    minHeight: "415px",
                    position: "relative" /* Wichtig für die Positionierung des Textes */,
                  }}
                  className="hover-container"
                >
                  <div
                    className="hover-text"
                    style={{
                      position: "absolute",
                      left: 0,
                      bottom: -200,
                      width: "100%",

                      background: "#f7f7f7",
                      transition: " 0.5s ease" /* Animation */,
                      padding: "10px" /* Padding für den Text */,
                      opacity: 1,
                      color: "black",
                    }}
                  >
                    <span className="text-extra-medium text-extra-dark-gray font-weight-500 d-block"> {t("update13")}</span>
                    <span className="text-dark lh-20">
                       {t("update14")}
                    </span>
                  </div>
                </div>
              </div>
              {/* end rotate box item */}
              {/* start rotate box item */}
              <div className="px-3">
                <div
                  style={{
                    backgroundImage: 'url("images/tablets-preview.jpg")',
                    backgroundSize: "cover",
                      backgroundPosition: "center",
                    borderRadius: "4px",
                    overflow: "hidden",
                    minHeight: "415px",
                    position: "relative" /* Wichtig für die Positionierung des Textes */,
                  }}
                  className="hover-container"
                >
                  <div
                    className="hover-text"
                    style={{
                      position: "absolute",
                      left: 0,
                      bottom: -200,
                      width: "100%",

                      background: "#f7f7f7",
                      transition: " 0.5s ease" /* Animation */,
                      padding: "10px" /* Padding für den Text */,
                      opacity: 1,
                      color: "black",
                    }}
                  >
                    <span className="text-extra-medium text-extra-dark-gray font-weight-500 d-block"> {t("update8")}</span>
                    <span className="text-dark lh-20">
                       {t("update15")}
                    </span>
                  </div>
                </div>
              </div>
              {/* end rotate box item */}
              {/* start rotate box item */}
              <div className="px-3">
                <div
                  style={{
                    backgroundImage: 'url("images/capsules-preview.jpg")',
                    backgroundSize: "cover",
                      backgroundPosition: "center",
                    borderRadius: "4px",
                    overflow: "hidden",
                    minHeight: "415px",
                    position: "relative" /* Wichtig für die Positionierung des Textes */,
                  }}
                  className="hover-container"
                >
                  <div
                    className="hover-text"
                    style={{
                      position: "absolute",
                      left: 0,
                      bottom: -200,
                      width: "100%",

                      background: "#f7f7f7",
                      transition: " 0.5s ease" /* Animation */,
                      padding: "10px" /* Padding für den Text */,
                      opacity: 1,
                      color: "black",
                    }}
                  >
                    <span className="text-extra-medium text-extra-dark-gray font-weight-500 d-block"> {t("update7")}</span>
                    <span className="text-dark lh-20">
                       {t("update16")}
                    </span>
                  </div>
                </div>
              </div>
              {/* end rotate box item */}
              {/* start rotate box item */}
              <div className="px-3">
                <div
                  style={{
                    backgroundImage: 'url("images/powder-preview.jpg")',
                    backgroundSize: "cover",
                      backgroundPosition: "center",
                    borderRadius: "4px",
                    overflow: "hidden",
                    minHeight: "415px",
                    position: "relative" /* Wichtig für die Positionierung des Textes */,
                  }}
                  className="hover-container"
                >
                  <div
                    className="hover-text"
                    style={{
                      position: "absolute",
                      left: 0,
                      bottom: -200,
                      width: "100%",

                      background: "#f7f7f7",
                      transition: " 0.5s ease" /* Animation */,
                      padding: "10px" /* Padding für den Text */,
                      opacity: 1,
                      color: "black",
                    }}
                  >
                    <span className="text-extra-medium text-extra-dark-gray font-weight-500 d-block"> {t("update9")}</span>
                    <span className="text-dark lh-20" >
                      {t("update17")}
                    </span>
                  </div>
                </div>
              </div>
              {/* end rotate box item */}
              {/* start rotate box item */}
              <div className="px-3">
                <div
                  style={{
                    backgroundImage: 'url("images/liquids-preview.jpg")',
                    backgroundSize: "cover",
                      backgroundPosition: "center",
                    borderRadius: "4px",
                    overflow: "hidden",
                    minHeight: "415px",
                    position: "relative" /* Wichtig für die Positionierung des Textes */,
                  }}
                  className="hover-container"
                >
                  <div
                    className="hover-text"
                    style={{
                      position: "absolute",
                      left: 0,
                      bottom: -200,
                      width: "100%",

                      background: "#f7f7f7",
                      transition: " 0.5s ease" /* Animation */,
                      padding: "10px" /* Padding für den Text */,
                      opacity: 1,
                      color: "black",
                    }}
                  >
                    <span className="text-extra-medium text-extra-dark-gray font-weight-500 d-block"> {t("update18")}</span>
                    <span className="text-dark lh-20" >
                      {t("update19")}
                    </span>
                  </div>
                </div>
              </div>
              {/* end rotate box item */} {/* start rotate box item */}
              <div className="px-3">
                <div
                  style={{
                    backgroundImage: 'url("images/proteinbar-preview.jpg")',
                    backgroundSize: "cover",
                      backgroundPosition: "center",
                    borderRadius: "4px",
                    overflow: "hidden",
                    minHeight: "415px",
                    position: "relative" /* Wichtig für die Positionierung des Textes */,
                  }}
                  className="hover-container"
                >
                  <div
                    className="hover-text"
                    style={{
                      position: "absolute",
                      left: 0,
                      bottom: -200,
                      width: "100%",

                      background: "#f7f7f7",
                      transition: " 0.5s ease" /* Animation */,
                      padding: "10px" /* Padding für den Text */,
                      opacity: 1,
                      color: "black",
                    }}
                  >
                    <span className="text-extra-medium text-extra-dark-gray font-weight-500 d-block"> {t("update20")}</span>
                    <span className="text-dark lh-20" >
                       {t("update21")}
                    </span>
                  </div>
                </div>
              </div>
              {/* end rotate box item */}
            </Slider>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(Coins2);
