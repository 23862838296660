import React, { Component } from "react";

class Contact extends Component {
  state = {};
  render() {
    return (
      <section className="half-section  bg-light-gray " style={{paddingTop: 20, paddingBottom: 20}}>
        <div className="container">
          <div className="row justify-content-center">
            {/* start feature box item*/}
            <div
              className="col-12 col-sm-auto  wow animate__fadeIn"
              data-wow-delay="0.2s"
              style={{
                visibility: "visible",
                animationDelay: "0.2s",
                animationName: "fadeIn",
              }}
            >
              <div className="d-flex justify-content-center align-items-center padding-15px-lr h-100">
                <i className="feather icon-feather-phone-call align-middle icon-extra-small text-extra-dark-gray margin-10px-right" />
                <span className="text-extra-dark-gray alt-font text-medium">+49 (0)209 51956620</span>
              </div>
            </div>
            {/* end feature box item*/}
            {/* start feature box item*/}
            <div
              className="col-12 col-sm-auto sm-margin-15px-bottom wow animate__fadeIn"
              data-wow-delay="0.4s"
              style={{
                visibility: "visible",
                animationDelay: "0.4s",
                animationName: "fadeIn",
              }}
            >
              <div className="d-flex justify-content-center align-items-center padding-15px-lr h-100">
                <i className="feather icon-feather-mail align-middle icon-extra-small text-extra-dark-gray margin-10px-right" />
                <span className="text-extra-dark-gray alt-font text-medium">
                  <a className="contact-link" href="mailto:info@private-label-factory.com">
                    info@private-label-factory.com
                  </a>
                </span>
              </div>
            </div>
            {/* end feature box item*/}
            {/* start feature box item*/}
            <div
              className="col-12 col-sm-auto wow animate__fadeIn  dnb-400"
              data-wow-delay="0.6s"
              style={{
                visibility: "visible",
                animationDelay: "0.6s",
                animationName: "fadeIn",
              }}
            >
              <div className="d-flex justify-content-center align-items-center padding-15px-lr h-100">
                <i className="feather icon-feather-map-pin align-middle icon-extra-small text-extra-dark-gray margin-10px-right" />
                <span className="text-extra-dark-gray alt-font text-medium">
                  Philipp-Reis-Straße 7, 45659 Recklinghausen, Germany
                </span>
              </div>
            </div>
            {/* end feature box item*/}
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
