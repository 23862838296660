import _ from "lodash";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Pagination, { paginate } from "../common/Pagination";
import SearchBox from "../common/SearchBox";
import { CustomPackagingsDocument, SelectedPackagingsDocument } from "./CustomTypes";
import configuratorUtils from "../../utils/configuratorUtils";
import PackagingItem, { SelectedPackagingItem, SelectedPlaceholder } from "./PackagingItem";
import { PACKAGINGCOMBINATIONS } from "./configuratorDataFilter";
import { CAPSULES_TAB, CUSTOM_TAB, TABLETS_TAB } from "./ConfiguratorTabs";
import { AdvancedPackagingFilter } from "./advancedPackagingFilter";

const PAGESIZE = 6;

interface PackagingConfigurationProps extends WithTranslation {
  activeTab: string;
  preferences: any;
  selectedPackaging: Array<SelectedPackagingsDocument>;
  packaging: Array<CustomPackagingsDocument>;
  onPackagingSelect: (packaging: CustomPackagingsDocument) => void;
  onPackagingAmount: (packaging: SelectedPackagingsDocument, add: boolean) => void;
  onPackagingOverwrite: (packs: Array<SelectedPackagingsDocument>) => void;
  loadedCachedId?: string;
  recipeVolume?: { value: number; noDefault: boolean };
}

interface PackagingConfigurationState {
  currentPage: number;
  searchQuery: string;
  filter: string;
  currentStep: number;
  currentCombination: any;
  filteredPackaging: Array<CustomPackagingsDocument>;
  toSelect: Array<string | Array<string>> | null;
  torsoSelection: Array<string>;
}

class PackagingConfiguration extends React.Component<PackagingConfigurationProps, PackagingConfigurationState> {
  _advancedFilter;
  constructor(props: PackagingConfigurationProps) {
    super(props);
    this._advancedFilter = new AdvancedPackagingFilter(props.packaging);
    const torsoSelection = this.getTorsoSelection();
    const [currentCombination, toSelect] = this.getInitialValues(torsoSelection);
    const currentStep = this.getNextStep(toSelect);
    const filteredPackaging = this.getFilteredPackaging(torsoSelection, toSelect, currentStep);
    this.state = {
      searchQuery: "",
      filter: this.getDefaultFilter(),
      currentPage: 1,
      currentStep: currentStep,
      currentCombination: currentCombination,
      toSelect: toSelect,
      torsoSelection: torsoSelection,
      filteredPackaging: filteredPackaging,
    };
  }

  getTorsoSelection = () => {
    const { activeTab, preferences } = this.props;
    return configuratorUtils.getTorsoTypeForProduct(activeTab, preferences);
  };

  /**
   * Get initial values for currentCombination and toSelect
   * @param torsoSelection the allowed values for torso
   * @returns a tuple with initial values for currentCombination and toSelect state
   */
  getInitialValues = (torsoSelection: Array<string>) => {
    const { activeTab, selectedPackaging } = this.props;
    let currentCombination = null;
    let toSelect = null;
    if (activeTab === CUSTOM_TAB) return [currentCombination, toSelect];
    if (selectedPackaging && selectedPackaging.length > 0) {
      // prettier-ignore
      const selectedTorso = selectedPackaging.find(entry => torsoSelection.includes(entry.packaging_type))
      if (selectedTorso) {
        // prettier-ignore
        currentCombination = _.get(PACKAGINGCOMBINATIONS, selectedTorso.packaging_type)
        toSelect = currentCombination.mandatory.concat(currentCombination.optional);
      }
    }
    return [currentCombination, toSelect];
  };

  /**
   * Gets the next step of the packaging selection for the current selection
   * @param toSelect Array of strings with types to be selected
   * @returns number for the next available step, -1 if everything is already selected
   */
  getNextStep = (toSelect: Array<string> | null) => {
    const { selectedPackaging } = this.props;
    if (!toSelect || !selectedPackaging) return 0;
    let newCurrentStep = -1;
    // Set current step to the next available, if everything is selected set index to invalid index -1
    for (let i = 0; i < toSelect.length; i++) {
      const type = toSelect[i];
      const arrCond = Array.isArray(type) && !selectedPackaging.some((entry) => type.includes(entry.packaging_type));
      const cond = !Array.isArray(type) && !selectedPackaging.some((entry) => entry.packaging_type === type);
      if (arrCond || cond) {
        newCurrentStep = i + 1;
        break;
      }
    }
    return newCurrentStep;
  };

  getIndexForType = (type: string | Array<string>) => {
    const { toSelect } = this.state;
    for (let i = 0; i < toSelect!.length; i++) {
      const currType = toSelect![i];
      const cond = !Array.isArray(currType) && !Array.isArray(type) && type === currType;
      const arrCond = Array.isArray(currType) && !Array.isArray(type) && currType.includes(type);
      const arrArrCond =
        Array.isArray(currType) &&
        Array.isArray(type) &&
        type.length === currType.length &&
        currType.every((y, i) => type[i] === y);
      if (cond || arrCond || arrArrCond) {
        return i;
      }
    }
    return -1;
  };

  getDefaultFilter = () => {
    return "all";
  };

  getPackagingTypes = (): Array<string> => {
    const { filteredPackaging } = this.state;
    // Get all packaging types
    let packagingTypes: Array<string> = filteredPackaging.map((pack) => {
      return pack.packaging_type;
    });
    packagingTypes.push("all");
    // Remove duplicates
    return Array.from(new Set(packagingTypes)).sort();
  };

  isMandatory = (type: string | Array<string>) => {
    const { currentCombination } = this.state;
    if (!currentCombination) return false;
    return this.isTypeInArray(currentCombination.mandatory, type);
  };

  isOptional = (type: string | Array<string>) => {
    const { activeTab } = this.props;
    if (activeTab === CUSTOM_TAB) return true;
    const { currentCombination } = this.state;
    if (!currentCombination) return false;
    return this.isTypeInArray(currentCombination.optional, type);
  };

  isTypeInArray = (array: Array<string | Array<string>>, type: Array<string> | string) => {
    return array.some((x) => {
      if (!Array.isArray(x) && !Array.isArray(type)) return x === type;
      if (Array.isArray(x) && Array.isArray(type)) return x.length === type.length && x.every((y, i) => type[i] === y);
      if (Array.isArray(x) && !Array.isArray(type)) return x.includes(type);
    });
  };

  handlePageChange = (page: number) => {
    this.setState({ currentPage: page });
  };

  /**
   * Extend onPackagingSearch with current page reset
   * @param e the change event
   */
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      currentPage: 1,
      searchQuery: e.target.value.toLocaleLowerCase(),
    });
  };

  /**
   * Set filter and reset searchquery and current page
   * @param e select event
   */
  handleFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      currentPage: 1,
      searchQuery: "",
      filter: e.currentTarget.value,
    });
  };

  /**
   * Extend onPackagingSelect with current page reset
   * @param packaging the packaging object
   */
  handlePackagingSelect = (packaging: CustomPackagingsDocument) => {
    const { activeTab, selectedPackaging, onPackagingSelect } = this.props;
    const { currentStep } = this.state;
    let toSelect = this.state.toSelect || [];
    const nextState: any = {
      currentPage: 1,
      searchQuery: "",
      filter: "all",
    };
    // Default behavior for custom products
    if (activeTab === CUSTOM_TAB) {
      this.setState({ ...nextState });
      onPackagingSelect(packaging);
      return;
    }
    // Get next combinations
    if (currentStep === 0) {
      // prettier-ignore
      const currentCombination = _.get(PACKAGINGCOMBINATIONS, packaging.packaging_type)
      toSelect = currentCombination.mandatory.concat(currentCombination.optional);
      nextState.currentCombination = currentCombination;
      nextState.toSelect = toSelect;
    }
    let newCurrentStep = -1;
    // Set current step to the next available, if everything is selected set index to invalid index -1
    for (let i = 0; i < toSelect.length; i++) {
      const type = toSelect[i];
      const typeArrCond =
        Array.isArray(type) &&
        !selectedPackaging.some((entry) => type.includes(entry.packaging_type)) &&
        !type.includes(packaging.packaging_type);
      const typeCond =
        !Array.isArray(type) &&
        !selectedPackaging.some((entry) => entry.packaging_type === type) &&
        packaging.packaging_type !== type;
      if (typeArrCond || typeCond) {
        newCurrentStep = i + 1;
        break;
      }
    }
    nextState.currentStep = newCurrentStep;
    this.setState({ ...nextState });
    onPackagingSelect(packaging);
  };

  handlePackagingAmount = (pack: SelectedPackagingsDocument, add: boolean) => {
    const { torsoSelection } = this.state;
    const { activeTab, onPackagingOverwrite, onPackagingAmount, selectedPackaging } = this.props;
    // default behavior for custom tab
    if (activeTab === CUSTOM_TAB) {
      onPackagingAmount(pack, add);
      return;
    }
    if (!add && pack.amount === 1) {
      if (torsoSelection.includes(pack.packaging_type)) {
        this.setState({ currentCombination: null, currentStep: 0, toSelect: null });
        // Remove all selected
        onPackagingOverwrite([]);
        return;
      } else {
        this.setState({ currentStep: this.getIndexForType(pack.packaging_type) + 1 });
        if (this.isMandatory(pack.packaging_type)) {
          onPackagingOverwrite(selectedPackaging.filter((entry) => torsoSelection.includes(entry.packaging_type)));
          return;
        }
      }
    }
    onPackagingAmount(pack, add);
  };

  handlePlaceholderClick = (index: number) => {
    const { torsoSelection, toSelect } = this.state;
    const nextStep = index + 1;
    const filteredPackaging = this.getFilteredPackaging(torsoSelection, toSelect, nextStep);
    this.setState({ currentStep: nextStep, filteredPackaging });
  };

  /**
   * Filters the packaging according to the current product i.e. torso selection
   * @param torsoSelection the torso selection to filter with
   * @param toSelect array with types that can be selected
   * @param currentStep the current step
   * @returns filtered packaging
   */
  getFilteredPackaging = (
    torsoSelection: Array<string>,
    toSelect: Array<string | Array<string>> | null,
    currentStep: number
  ) => {
    const { packaging, preferences, selectedPackaging, activeTab, recipeVolume } = this.props;
    if (currentStep === 0) {
      if (torsoSelection.length === 0) return packaging;
      else {
        return this._advancedFilter.getFilteredTorsoPackaging(activeTab, torsoSelection, preferences, recipeVolume);
      }
    } else {
      const nextType = toSelect![currentStep - 1];
      return this._advancedFilter.getFilteredPackaging(selectedPackaging, nextType);
    }
  };

  doPackagingSearch = (packagingToSearch: Array<CustomPackagingsDocument>) => {
    const { searchQuery } = this.state;
    const packaging = packagingToSearch;
    if (searchQuery === "") {
      return packaging;
    }
    return packaging.filter((entry) => {
      return configuratorUtils.concatPackagingInfo(entry, this.props.t).toLowerCase().includes(searchQuery);
    });
  };

  doPackagingFilter = (packagingToFilter: Array<CustomPackagingsDocument>) => {
    const { filter } = this.state;
    const { selectedPackaging } = this.props;
    // filter selected packaging
    const packaging = packagingToFilter.filter(
      (pack) => !selectedPackaging.some((entry) => pack._id.toString() === entry._id.toString())
    );
    if (filter === "all") return packaging;
    return packaging.filter((entry) => entry.packaging_type === filter);
  };

  getDisplayedPackaging = (): [Array<CustomPackagingsDocument>, string | Array<string>] => {
    const { activeTab, packaging } = this.props;
    const { currentStep, toSelect, filteredPackaging } = this.state;
    if (activeTab === CUSTOM_TAB) {
      const tmpPack = this.doPackagingSearch(this.doPackagingFilter(packaging));
      return [tmpPack, "packaging"];
    } else {
      const type = currentStep === 0 ? "torso" : toSelect![currentStep - 1];
      const packaging = this.doPackagingSearch(this.doPackagingFilter(filteredPackaging));
      return [packaging, type];
    }
  };

  /**
   * Checks if the next torso selection matches the current selected packagings
   * @param nextTorsoSelection the new torso selection
   * @returns True if currently selected torso is still part of next torso selection
   */
  isResetRequired = (nextTorsoSelection: Array<string>) => {
    const { selectedPackaging } = this.props;
    if (selectedPackaging.length === 0) return false;
    return !selectedPackaging.some((pack) => nextTorsoSelection.some((torso) => torso === pack.packaging_type));
  };

  shouldComponentUpdate(
    nextProps: Readonly<PackagingConfigurationProps>,
    nextState: Readonly<PackagingConfigurationState>,
    nextContext: any
  ): boolean {
    const { activeTab, preferences, loadedCachedId, packaging, selectedPackaging, recipeVolume } = this.props;
    const { currentPage, filter, searchQuery, currentStep, filteredPackaging, torsoSelection } = this.state;
    // Update when preferences changes would lead to new torsoSelection
    const nextTorsoSelection = configuratorUtils.getTorsoTypeForProduct(nextProps.activeTab, nextProps.preferences);
    const tabletCapsuleCondition =
      nextProps.activeTab === activeTab &&
      ((activeTab === CAPSULES_TAB &&
        preferences.selectedCapsule._id.toString() !== nextProps.preferences.selectedCapsule._id.toString()) ||
        (activeTab === TABLETS_TAB &&
          preferences.selectedTablet._id.toString() !== nextProps.preferences.selectedTablet._id.toString()));
    return (
      nextState.currentPage !== currentPage ||
      nextState.filter !== filter ||
      nextState.searchQuery !== searchQuery ||
      nextState.currentStep !== currentStep ||
      nextState.filteredPackaging.length !== filteredPackaging.length ||
      nextProps.loadedCachedId !== loadedCachedId ||
      nextProps.packaging.length !== packaging.length ||
      nextProps.selectedPackaging.length !== selectedPackaging.length ||
      nextProps.preferences.amountPerUnit !== preferences.amountPerUnit ||
      nextProps.recipeVolume !== recipeVolume ||
      tabletCapsuleCondition ||
      JSON.stringify(nextState.torsoSelection.sort()) !== JSON.stringify(torsoSelection.sort()) ||
      JSON.stringify([...torsoSelection].sort()) !== JSON.stringify([...nextTorsoSelection].sort()) ||
      JSON.stringify(nextProps.selectedPackaging) !== JSON.stringify(selectedPackaging) ||
      JSON.stringify(nextProps.packaging) !== JSON.stringify(packaging) ||
      JSON.stringify(nextState.filteredPackaging) !== JSON.stringify(filteredPackaging)
    );
  }

  componentDidUpdate(
    prevProps: Readonly<PackagingConfigurationProps>,
    prevState: Readonly<PackagingConfigurationState>,
    snapshot?: any
  ) {
    const { activeTab, preferences, t, selectedPackaging, onPackagingOverwrite, packaging } = this.props;
    // Update when packaging changed
    const packagingCondition =
      packaging.length !== prevProps.packaging.length ||
      JSON.stringify(packaging) !== JSON.stringify(prevProps.packaging);
    // Update when selection changed
    const selectedPackagingCondition =
      selectedPackaging.length !== prevProps.selectedPackaging.length ||
      JSON.stringify(selectedPackaging) !== JSON.stringify(prevProps.selectedPackaging);
    // Update when activeTab stays the same and selectedPackaging is reset (new configuration in capsules tab)
    const tabCondition =
      prevProps.activeTab === activeTab && prevProps.selectedPackaging.length !== 0 && selectedPackaging.length === 0;
    // Update when a (different) cached request was loaded
    const cachedCondition = this.props.loadedCachedId && this.props.loadedCachedId !== prevProps.loadedCachedId;
    // Update when preferences change would lead to different torso selection
    const prevTorso = configuratorUtils.getTorsoTypeForProduct(prevProps.activeTab, prevProps.preferences);
    const torsoSelection = configuratorUtils.getTorsoTypeForProduct(activeTab, preferences);
    const torsoCondition = JSON.stringify([...prevTorso].sort()) !== JSON.stringify([...torsoSelection].sort());
    // Update when amount per unit in preferences changed as this may influence torsoSelection
    const preferencesCondition = prevProps.preferences.amountPerUnit !== preferences.amountPerUnit;
    // Update when selected capsule or tablet changes as the volume most likely changes and with it the torso selection
    const tabletCapsuleCondition =
      prevProps.activeTab === activeTab &&
      ((activeTab === CAPSULES_TAB &&
        preferences.selectedCapsule._id.toString() !== prevProps.preferences.selectedCapsule._id.toString()) ||
        (activeTab === TABLETS_TAB &&
          preferences.selectedTablet._id.toString() !== prevProps.preferences.selectedTablet._id.toString()));
    const volumeCondition = prevProps.recipeVolume !== this.props.recipeVolume;

    if (packagingCondition) {
      this._advancedFilter.packaging = this.props.packaging;
    }
    if (
      tabCondition ||
      cachedCondition ||
      packagingCondition ||
      selectedPackagingCondition ||
      preferencesCondition ||
      torsoCondition ||
      tabletCapsuleCondition ||
      volumeCondition
    ) {
      const [currentCombination, toSelect] = this.getInitialValues(torsoSelection);
      const currentStep = this.getNextStep(toSelect);
      const filteredPackaging = this.getFilteredPackaging(torsoSelection, toSelect, currentStep);

      if (torsoCondition && this.isResetRequired(torsoSelection)) {
        toast.info(t("packagingCleared"));
        onPackagingOverwrite([]);
      }

      this.setState({
        currentCombination,
        toSelect,
        currentStep,
        torsoSelection,
        filteredPackaging,
        filter: "all",
        searchQuery: "",
        currentPage: 1,
      });
    }
  }

  renderSelectedPackaging() {
    const { t, selectedPackaging, activeTab } = this.props;
    const { toSelect } = this.state;
    let displayedSelected: any = [...selectedPackaging];
    let mandatoryTypes = [];
    // Get selected packaging and placeholder to be displayed
    if (activeTab !== CUSTOM_TAB) {
      if (selectedPackaging.length === 0) displayedSelected.push({ packaging_type: "torso" });
      if (toSelect && toSelect.length > 0) {
        mandatoryTypes = this.state.currentCombination.mandatory;
        for (let i = 0; i < toSelect.length; i++) {
          const type = toSelect[i];
          if (!selectedPackaging.find((pack) => pack.packaging_type === type || type.includes(pack.packaging_type))) {
            displayedSelected.push({ packaging_type: type, index: i });
          }
        }
        displayedSelected.sort(
          (a: any, b: any) => this.getIndexForType(a.packaging_type) - this.getIndexForType(b.packaging_type)
        );
      }
    } else {
      displayedSelected.push({ packaging_type: "packaging" });
    }
    const allMandatorySelected = configuratorUtils.isMandatorySelected(mandatoryTypes, selectedPackaging);
    return (
      <>
        <h3>{t("currentSelection")}</h3>
        <div className="row" id="selectedPackaging">
          <div className="col-12">
            <div className="row mt-3">
              {displayedSelected.map((entry: any, index: number) => {
                let props: any = {
                  t: t,
                };
                if (entry && entry._id) {
                  props.packaging = entry;
                  props.onPackagingAmount = this.handlePackagingAmount;
                } else {
                  props.placeholderType = entry.packaging_type;
                  props.disabled = !this.isMandatory(entry.packaging_type) && !allMandatorySelected;
                  props.optional = this.isOptional(entry.packaging_type);
                  if (entry.index !== undefined && !props.disabled)
                    props.onPlaceholderClick = () => this.handlePlaceholderClick(entry.index);
                }
                return (
                  <div className="col-6 col-sm-4 col-md-3 col-xl-2" key={index.toString()}>
                    {props.packaging ? <SelectedPackagingItem {...props} /> : <SelectedPlaceholder {...props} />}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { activeTab, t, recipeVolume } = this.props;
    const { currentPage, searchQuery, filter, currentStep, toSelect } = this.state;
    const [packaging, packType] = this.getDisplayedPackaging()!;
    const volumeInfoText =
      currentStep === 0 && recipeVolume && !recipeVolume.noDefault ? t("torsoVolumeInformation") : "";
    const volumeTextClass = currentStep === 0 && recipeVolume && !recipeVolume.noDefault ? "text-warning" : "";
    return (
      <>
        <span className="text-dark-75 font-size-h3 font-weight-bold">
          {t("configurator:packaging")}
        </span>
        <br />
        <span className="text-dark-75 font-size-md">({t("perUnit")})</span>
        <div className="container-fluid ml-0 mr-0 pr-0 pl-0 mt-5" id="packagingConfiguration">
          {this.renderSelectedPackaging()}
          {(currentStep === 0 || (toSelect && toSelect.length > 0 && currentStep > 0)) && (
            <>
              {activeTab === CUSTOM_TAB ? (
                <h3 id="selectHeading">{t("selectCustomPackaging")}</h3>
              ) : (
                <div className="d-flex">
                  <h3 id="selectHeading" className={volumeTextClass}>
                    {t("selectPackaging", {
                      step: currentStep + 1,
                      pack: Array.isArray(packType)
                        ? packType.map((pack) => t("packaging:" + pack)).join(` ${t("packaging:or")} `)
                        : t("packaging:" + packType),
                    }) + (this.isOptional(packType) ? ` (${t("optional")})` : "")}
                  </h3>
                  {volumeInfoText && (
                    <OverlayTrigger placement="top" overlay={<Tooltip id="torsoVolumeInfo">{volumeInfoText}</Tooltip>}>
                      <i className={`fa fa-info-circle my-auto ml-2 ${volumeTextClass}`} />
                    </OverlayTrigger>
                  )}
                </div>
              )}

              <div className="row mt-5">
                <div className="col" style={{ maxWidth: "500px" }}>
                  <SearchBox
                    title={t("configurator:search")}
                    onSearch={this.handleSearch}
                    additionalClasses={"rounded-lg form-control-sm"}
                    placeholder={t("configurator:searchPlaceholder")}
                    idSuffix="Packaging"
                    value={searchQuery}
                  />
                </div>
                {/* Only show filter for torso selection! */}
                <div className="col" style={{ maxWidth: "150px" }}>
                  {(currentStep === 0 || Array.isArray(packType)) && (
                    <select
                      className="form-control form-control-sm rounded-lg"
                      value={filter}
                      onChange={this.handleFilter}
                    >
                      {this.getPackagingTypes().map((type) => {
                        return (
                          <option key={type} value={type}>
                            {t("packaging:" + type)}
                          </option>
                        );
                      })}
                    </select>
                  )}
                </div>
              </div>
              <div className="row mt-5" id="packagingSelection">
                <div className="col-12">
                  {packaging.length === 0 ? (
                    <h3 className="my-10 text-center text-muted">{t("noPackaging")}</h3>
                  ) : (
                    <div className="row mt-3">
                      {paginate(packaging, currentPage, PAGESIZE).map((entry) => {
                        return (
                          <div className="col-6 col-sm-4 col-md-3 col-xl-2" key={entry._id.toString()}>
                            <PackagingItem packaging={entry} t={t} onPackagingSelect={this.handlePackagingSelect} />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <Pagination
          itemsCount={packaging.length}
          pageSize={PAGESIZE}
          currentPage={this.state.currentPage}
          onPageChange={this.handlePageChange}
        />
      </>
    );
  }
}

export default withTranslation(["configurator", "packaging"])(PackagingConfiguration);
