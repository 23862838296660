import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";

class Compostable2 extends Component {
  state = {};

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <section
          className="wow animate__fadeIn rmb-767 am5b-767 bg-light-gray"
          style={{ visibility: "visible", animationName: "fadeIn" }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-xl-6 col-lg-5 col-md-10 md-margin-5-rem-bottom">
                <div className="position-relative w-95 md-w-100">
                  <img className="border-radius-10px" src="images/tteesstt.png" alt="" data-no-retina />
                </div>
              </div>
              <div className="col-12 col-xl-5 col-lg-6 offset-lg-1 text-lg-start">
                <div style={{ display: "flex", alignItems: "center" }} className={"mb-4"}>
                  <ReactSVG src="images/logo-dark.svg" style={{ width: "180px" }} />
                  <img src="images/delete.png" className={"mx-5"} style={{ width: "30px" }}></img>
                  <img src="images/wellma.png" style={{ width: "180px" }} />
                </div>
                <p className="font-size-lg text-black">
                  {t("update40")}
                </p>{" "}
                <a
                  href="https://wellmagroup.com"
                  className="btn btn-large btn-dark-gray margin-35px-right sm-margin-15px-right mb-3"
                >
                  {t("update41")}
                </a>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(Compostable2);
