import React, { PureComponent } from "react";
import { Card } from "react-bootstrap";
import { WithTranslation, withTranslation } from "react-i18next";
import orderUtils from "../../utils/orderUtils";
import TimelineEntry from "./TimelineEntry";

interface TimelineProps extends WithTranslation {
  timeline: Array<{ type: string; date: Date; oldDate?: Date | null; newDate?: Date }>;
  updateTime: number;
}

class Timeline extends PureComponent<TimelineProps, {}> {
  interval = setInterval(() => null, 60 * 1000);

  componentDidMount() {
    this.interval = setInterval(() => this.forceUpdate(), 60 * 1000);
  }

  /**
   * Resolve time since the page was updated
   * @returns When the last update happened
   */
  resolveLatestUpdate() {
    const { t, updateTime } = this.props;
    return t("timeAgo", {
      time: orderUtils.getLatestUpdate(t, new Date(updateTime)),
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { t, timeline } = this.props;

    return (
      <Card id="timeline" className="card-custom card-stretch gutter-b card-shadowless">
        <Card.Header className="border-0 py-2">
          <div className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark font-size-h2">{t("timeline")}</span>
            <span className="text-muted mt-1 font-weight-bold font-size-sm">
              {t("lastUpdated")}
              {": "}
              <span className="text-dark font-italic">{this.resolveLatestUpdate()}</span>
            </span>
          </div>
        </Card.Header>
        <Card.Body className="py-0 overflow-auto mb-sm-2 mr-sm-2">
          <div className="timeline timeline-2 mt-1 mb-1">
            <div className="timeline-bar" />
            {timeline.map((entry, key) => {
              return (
                <React.Fragment key={key}>
                  <TimelineEntry timelineEntry={entry} />
                  {key !== timeline.length - 1 && <div className="separator separator-solid ml-2" />}
                </React.Fragment>
              );
            })}
          </div>
        </Card.Body>
      </Card>
    );
  }
}

export default withTranslation("order")(Timeline);
