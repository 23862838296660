import React, { Component } from "react";
import Location from "../sections/location";

class Production extends Component {
  state = {};
  render() {
    return <Location />;
  }
}

export default Production;
