import React, { Component } from "react";

import { withTranslation } from "react-i18next";

class ImageSlider extends Component {
  state = {};

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <section className="landing-page-auto-slider bg-white pb-0" id="templates">
          <div className="container">
            <div className="row justify-content-center">
              <div
                className="col-12 col-xl-6 col-lg-7 col-sm-9 text-center margin-5-rem-bottom lg-margin-3-rem-bottom xs-margin-2-rem-bottom wow animate__fadeIn"
                data-wow-delay="0.2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeIn",
                }}
              >
                <h3 className="alt-font font-weight-600 text-extra-dark-gray margin-2-rem-bottom">
                  {t("title50")}
                  <br></br>
                  {t("title51")}
                </h3>
                <div
                  className="margin-15px-top d-inline-block wow animate__fadeInRight"
                  data-wow-delay="0.4s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.4s",
                    animationName: "fadeInRight",
                  }}
                >
                  <a
                    href="/team"
                    className="btn btn-fancy btn-small btn-dark-gray margin-35px-right sm-margin-15px-right"
                  >
                    {t("title52")}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-12 pr-sm-0 wow animate__fadeIn"
                data-wow-delay="0.4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeIn",
                  overflow: "hidden",
                }}
              >
                <div class="people_marquee"></div>
              </div>{" "}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(ImageSlider);
