export const ARCHIVE = "archive";
export const CREATEINVOICE = "create_invoice";
export const CONTRACT = "contract";
export const DECLINED = "declined";
export const FULFILLMENT = "fulfillment";
export const FEEDBACK = "feedback";
export const INVOICE = "invoice";
export const NOTE = "note";
export const OFFER = "offer";
export const OFFERCONFIRMATION = "offerconfirmation";
export const ORDERED = "ordered";
export const ORDERORDERCOMMODITIES = "order_order-commodities";
export const PRODUCTION = "production";
export const PRODUCTIONQUEUE = "productionqueue";
export const REMINDER = "reminder";
export const REORDER = "reorder";
export const REQUEST = "request";
export const REQUESTAPPROVED = "request_approved";
export const REQUESTED = "requested";
export const REQUESTPENDING = "request_pending";
export const REQUESTREVOKED = "request_revoked";
export const REQUESTUPDATED = "requestUpdated";
export const RETURNPRODUCTIONQUEUE = "return_productionqueue";
export const UPDATE = "update";
export const WAITING = "waiting";
export const TARGETDATEUPDATED = "targetDateUpdated";

export const AN = "AN";
export const AT = "AT";

/**
 * Retrieves the correct picture for the given product.
 * @param type: Product type
 * @returns Correct picture
 */
function pictureProductMatching(type: string): string {
  switch (type) {
    case "capsule":
      return "kapsel-weiss.png";
    case "tablet":
      return "tablette-weiss.png";
    case "powder":
      return "pulver-weiss.png";
    case "concentrates":
    case "liquid":
    case "oil":
      return "liquid.png";
    case "softgel":
      return "softgel.png";
    case "service":
      return "logo-plf.png";
    default:
      return "kapseldose.png";
  }
}

/**
 * Resolve how much time has passed since the given date
 * @param t: Translation function
 * @param date: Date that should be resolved
 * @returns Returns time that has passed as string
 */
function getLatestUpdate(t: (keyword: string) => string, date: Date) {
  const latestUpdate = Math.floor(Date.now() - date.getTime()) / 1000;
  let interval = latestUpdate / 31536000;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return "1 " + t("year");
    }
    return Math.floor(interval) + " " + t("years");
  }
  interval = latestUpdate / 2592000;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return "1 " + t("month");
    }
    return Math.floor(interval) + " " + t("months");
  }
  interval = latestUpdate / 86400;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return "1 " + t("day");
    }
    return Math.floor(interval) + " " + t("days");
  }
  interval = latestUpdate / 3600;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return "1 " + t("hour");
    }
    return Math.floor(interval) + " " + t("hours");
  }
  interval = latestUpdate / 60;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return "1 " + t("minute");
    }
    return Math.floor(interval) + " " + t("minutes");
  }
  return t("lessThanOneMinuteAgo");
}

function orderStateOrder(state: string): number {
  switch (state) {
    case ORDERORDERCOMMODITIES:
      return 1;
    case WAITING:
      return 2;
    case PRODUCTIONQUEUE:
      return 3;
    case PRODUCTION:
      return 4;
    case FULFILLMENT:
      return 5;
    case CREATEINVOICE:
      return 6;
    case ARCHIVE:
      return 7;
    default:
      return -1;
  }
}

function orderByNumber(state: number): string | null {
  switch (state) {
    case 1:
      return ORDERORDERCOMMODITIES;
    case 2:
      return WAITING;
    case 3:
      return PRODUCTIONQUEUE;
    case 4:
      return PRODUCTION;
    case 5:
      return FULFILLMENT;
    case 6:
      return CREATEINVOICE;
    case 7:
      return ARCHIVE;
    default:
      return null;
  }
}

function getNextOrderState(state: string) {
  const currentState = orderStateOrder(state);
  return orderByNumber(currentState + 1);
}

// eslint-disable-next-line
export default {
  pictureProductMatching,
  getLatestUpdate,
  orderByNumber,
  getNextOrderState,
};
