import * as React from "react";
import { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import SVG from "react-inlinesvg";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import countryList from "i18n-iso-countries";
import { SelectedPackagingsDocument } from "./CustomTypes";
import ConfiguratorHelpers from "./ConfiguratorHelpers";
import validationUtil from "./validationUtil";
import SimpleConfirmationModal from "../common/SimpleConfirmationModal";
import { language } from "../../utils/languageUtils";
import Joi from "joi";

interface ConfigurationConfirmationProps extends WithTranslation {
  activeTab: string;
  requestComplete: boolean;
  onSendRequest: () => void;
  onPreferenceChange: (path: string, item: any) => void;
  onContactDetailsChange: (path: string, item: any) => void;
  onCouponCodeChange: (value: string) => void;
  onCalculationChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  preferences: any;
  selectedPackaging: Array<SelectedPackagingsDocument>;
  recipe: Array<any>;
  calculations: Array<string>;
  contactDetails: {
    phone: string;
    email: string;
    companyName: string;
    street: string;
    zip: string;
    city: string;
    country: string;
  };
  couponCode: string;
}

const ConfigurationConfirmation: React.FunctionComponent<ConfigurationConfirmationProps> = React.memo((props) => {
  const {
    activeTab,
    t,
    requestComplete,
    onSendRequest,
    onPreferenceChange,
    onCalculationChange,
    preferences,
    recipe,
    selectedPackaging,
    calculations,
    contactDetails,
    onContactDetailsChange,
    onCouponCodeChange,
    couponCode,
  } = props;
  const [show, setShow] = useState(false);
  const [showIssues, setShowIssues] = useState(false);
  const [modalState, setModalState] = useState(0);
  const [title, handleTitleChange] = useState(preferences.title);
  const [subtitle, handleSubtitleChange] = useState(preferences.subtitle);
  const [lCouponCode, handleCouponCode] = useState(couponCode);
  const [localCalculations, setCalculation] = useState(calculations);

  const { email, phone, companyName, street, zip, city, country } = contactDetails;

  useEffect(() => {
    // Reset data reset data if it has changed
    if (title !== preferences.title) handleTitleChange(preferences.title);
    if (subtitle !== preferences.subtitle) handleSubtitleChange(preferences.subtitle);
    if (lCouponCode !== couponCode) handleCouponCode(couponCode);
    setCalculation(calculations);
  }, [calculations, preferences, couponCode]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    // Always open popup with settings window
    if (modalState !== 0) setModalState(0);
    setShow(true);
  };
  const handleCloseIssues = () => setShowIssues(false);
  const handleShowIssues = () => setShowIssues(true);

  let validationResult;
  if (requestComplete)
    validationResult = validationUtil.validateConfiguration(activeTab, preferences, recipe, selectedPackaging, t);

  // Set step for next and back buttons
  const handleNext = () => setModalState(modalState + 1);
  const handleBack = () => setModalState(modalState - 1);

  const handleCalculationChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newCalculation = [...localCalculations];
    newCalculation[index] = Number(parseInt(e.target.value) || "0").toString();
    setCalculation(newCalculation);
  };
  const emailSchema = Joi.string()
    .email({ tlds: { allow: false }, minDomainSegments: 1 })
    .required();
  // Check that at least one calculation is available
  const isEmailInvalid = !!emailSchema.validate(email).error;
  const isComplete =
    requestComplete &&
    title !== "" &&
    email !== "" &&
    !isEmailInvalid &&
    companyName !== "" &&
    street !== "" &&
    zip !== "" &&
    city !== "" &&
    country !== "" &&
    localCalculations.some((cal) => parseInt(cal) > 0) &&
    localCalculations.every((cal) => (parseInt(cal) > 0 ? parseInt(cal) >= 500 : parseInt(cal) === 0));
  const getButtonStyle = (showOverview: boolean) => {
    let style: {};
    showOverview ? (isComplete ? (style = {}) : (style = { cursor: "not-allowed" })) : (style = { display: "None" });
    return style;
  };

  const getLanguage = () => {
    const lang = language();
    if (!["de", "en"].includes(lang.toLowerCase())) return "en";
    return lang;
  };

  const showOverview = modalState === 1;
  return (
    <>
      <button
        onClick={validationResult ? handleShowIssues : handleShow}
        disabled={!requestComplete}
        id="sendRequest"
        style={requestComplete ? {} : { cursor: "not-allowed" }}
        className={`btn ${requestComplete ? "btn-green" : "btn-danger"} font-size-sm`}
      >
        &nbsp;
        {requestComplete ? (
          <>
            {t("configurator:requestPrice")}
            <span className="svg-icon svg-icon-md svg-icon-white">
              <SVG src={process.env.PUBLIC_URL + "/media/svg/icons/Communication/Forward.svg"} />
            </span>
          </>
        ) : (
          t("configurator:requestIncomplete")
        )}
      </button>
      {validationResult && (
        <SimpleConfirmationModal.SimpleConfirmationModal
          size={"lg"}
          swapButtons
          show={showIssues}
          onConfirm={() => {
            handleShow();
            handleCloseIssues();
          }}
          onClose={handleCloseIssues}
          confirmButtonText={t("continue")}
          cancelButtonText={t("resolve")}
          hideConfirm={validationResult.error.length > 0}
          modalDescription={
            <div className="configurator-wrapper">
              {validationResult.error.length !== 0 && (
                <>
                  <i className="fa fa-exclamation-circle text-danger icon-lg mx-auto" />
                  <span className="font-size-h5 font-weight-bold ml-2">{t("errors")}</span>
                  <br />
                  <ul className="pl-10">
                    {validationResult.error.map((info: string, index: number) => (
                      <li key={index.toString()}>
                        <span className="font-size-lg">{info}</span>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {validationResult.warn.length !== 0 && (
                <>
                  <i className="fa fa-exclamation-triangle icon-lg text-warning mx-auto" />
                  <span className="font-size-h5 font-weight-bold ml-2">{t("warnings")}</span>
                  <br />
                  <ul className="pl-10">
                    {validationResult.warn.map((info: string, index: number) => (
                      <li key={index.toString()}>
                        <span className="font-size-lg">{info}</span>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {validationResult.info.length !== 0 && (
                <>
                  <i className="fa fa-info-circle icon-lg mx-auto" />
                  <span className="font-size-h5 font-weight-bold ml-2">{t("hints")}</span>
                  <br />
                  <ul className="pl-10">
                    {validationResult.info.map((info: string, index: number) => (
                      <li key={index.toString()}>
                        <span className="font-size-lg">{info}</span>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          }
          modalTitle={t("configurationIssues")}
        />
      )}
      <Modal
        className="configurator-wrapper"
        show={show}
        id="confirmationModal"
        onHide={handleClose}
        centered
        size={showOverview ? undefined : "lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("configurator:completeRequest")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={modalState !== 0 ? { display: "None" } : {}} id="additionalInformation">
            <span className="text-dark-75 text-success font-weight-bold">
              {t("configurator:configurationInfoText")}
            </span>
            <br></br>
            <br></br>
            <br></br>
            <span className="text-dark-75 text-hover-primary font-size-h5 font-weight-bold">
              {t("configurator:additionalInformation")}
            </span>
            <br />
            <br />
            <div className="row pb-3">
              <div className="col-4">
                <label>{t("configurator:title")}:</label>
              </div>
              <div className="col-8">
                <div className="input-group">
                  <input
                    type="text"
                    id="title"
                    className="form-control"
                    onBlur={(e) => {
                      onPreferenceChange("title", e.target.value.trimStart());
                    }}
                    onChange={(e) => {
                      handleTitleChange(e.target.value.trimStart());
                    }}
                    value={title}
                    style={title === "" ? { border: "1px solid red" } : {}}
                  />
                </div>
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-4">
                <label>{t("configurator:description")}:</label>
              </div>
              <div className="col-8">
                <div className="input-group">
                  <textarea
                    className="form-control"
                    onBlur={(e) => {
                      onPreferenceChange("subtitle", e.target.value.trimStart());
                    }}
                    onChange={(e) => {
                      handleSubtitleChange(e.target.value.trimStart());
                    }}
                    value={subtitle}
                  />
                </div>
              </div>
            </div>
            <div className="separator separator-dashed my-5" />
            <>
              <span className="text-dark-75 text-hover-primary font-size-h5 font-weight-bold">
                {t("configurator:calculations")}
              </span>{" "}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="calculationsInfo">{t("configurator:calculationsInfo")}</Tooltip>}
              >
                <i className="fa fa-info-circle" />
              </OverlayTrigger>
              <br />
              <br />
              {localCalculations.map((value, index) => {
                return (
                  <div key={index.toString()} className="row pb-3">
                    <div className="col-4">
                      <label>
                        {t("configurator:calculation")} {index + 1}:
                      </label>
                    </div>
                    <div className="col-8">
                      <div className="input-group">
                        <input
                          type="number"
                          name="calculations"
                          style={parseInt(value) < 500 && parseInt(value) !== 0 ? { border: "1px solid red" } : {}}
                          className="form-control"
                          value={value}
                          onBlur={(e) => onCalculationChange(e, index)}
                          onChange={(e) => handleCalculationChange(e, index)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">{t("configurator:units")}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
            <div className="separator separator-dashed my-5" />
            <span className="text-dark-75 text-hover-primary font-size-h5 font-weight-bold">
              {t("configurator:contact")}
            </span>
            <br />
            <br />
            <div className="row pb-3">
              <div className="col-4">
                <label>{t("configurator:companyName")}:</label>
              </div>
              <div className="col-8">
                <div className="input-group">
                  <input
                    type="text"
                    id="companyName"
                    className="form-control"
                    placeholder={t("companyName")}
                    value={companyName}
                    onChange={(e) => {
                      onContactDetailsChange("companyName", e.target.value.trimStart());
                    }}
                    style={companyName === "" ? { border: "1px solid red" } : {}}
                  />
                </div>
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-4">
                <label>{t("configurator:street")}:</label>
              </div>
              <div className="col-8">
                <div className="input-group">
                  <input
                    type="text"
                    id="street"
                    placeholder={t("street")}
                    className="form-control"
                    value={street}
                    onChange={(e) => {
                      onContactDetailsChange("street", e.target.value.trimStart());
                    }}
                    style={street === "" ? { border: "1px solid red" } : {}}
                  />
                </div>
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-4">
                <label>{t("configurator:city")}:</label>
              </div>
              <div className="col-3 pr-0">
                <div className="input-group">
                  <input
                    type="text"
                    id="zip"
                    className="form-control"
                    placeholder={t("zip")}
                    value={zip}
                    onChange={(e) => {
                      onContactDetailsChange("zip", e.target.value.trimStart());
                    }}
                    style={zip === "" ? { border: "1px solid red" } : {}}
                  />
                </div>
              </div>
              <div className="col-5 ">
                <div className="input-group">
                  <input
                    type="text"
                    id="city"
                    placeholder={t("city2")}
                    className="form-control"
                    value={city}
                    onChange={(e) => {
                      onContactDetailsChange("city", e.target.value.trimStart());
                    }}
                    style={city === "" ? { border: "1px solid red" } : {}}
                  />
                </div>
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-4">
                <label>{t("configurator:country")}:</label>
              </div>
              <div className="col-8">
                <div className="input-group">
                  <select
                    id="country"
                    className="form-control"
                    placeholder={t("country")}
                    value={country}
                    onChange={(e) => {
                      onContactDetailsChange("country", e.currentTarget.value);
                    }}
                    style={country === "" ? { border: "1px solid red" } : {}}
                  >
                    <option key="default" className="text-muted">
                      {country}
                    </option>
                    {Object.entries(countryList.getNames(getLanguage())).map(
                      (item: any) => item[1] !== country && <option key={item[0]}>{item[1]}</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-4">
                <label>{t("configurator:email")}:</label>
              </div>
              <div className="col-8">
                <div className="input-group">
                  <input
                    type="text"
                    id="email"
                    className="form-control"
                    placeholder={t("email")}
                    value={email}
                    onChange={(e) => {
                      onContactDetailsChange("email", e.target.value.trimStart());
                    }}
                    style={email === "" || isEmailInvalid ? { border: "1px solid red" } : {}}
                  />
                </div>
                {email.trim() !== "" && isEmailInvalid && <span className="text-danger">{t("error:email")}</span>}
              </div>
            </div>
            <div className="row pb-3">
              <div className="col-4">
                <label>{t("configurator:phone")}:</label>
              </div>
              <div className="col-8">
                <div className="input-group">
                  <input
                    type="text"
                    id="phone"
                    className="form-control"
                    placeholder={t("phone2")}
                    value={phone}
                    onChange={(e) => {
                      onContactDetailsChange("phone", e.target.value.trimStart());
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={showOverview ? {} : { display: "None" }} id="overview">
            <span className="text-dark-75 text-hover-primary font-size-h5 font-weight-bold">
              {t("configurator:overview")}
            </span>
            <br />
            <br />
            <ConfiguratorHelpers.ShortOverview
              tab={activeTab}
              preferences={preferences}
              recipe={recipe}
              selectedPackaging={selectedPackaging}
              calculations={calculations}
              contactDetails={contactDetails}
            />
            <div className="separator separator-dashed my-5" />
            <div className="row">
              <div className="col-7">
                <span className="text-dark-75 text-hover-primary font-size-h5 font-weight-bold">
                  {t("configurator:code")}
                </span>
                <br />
                <span className="text-muted font-size-sm">{t("configurator:codeText")}</span>
              </div>
              <div className="col-5">
                <div className="input-group">
                  <input
                    type="text"
                    id="coupon"
                    className="form-control"
                    placeholder={t("codePlaceholder")}
                    value={lCouponCode}
                    onChange={(e) => handleCouponCode(e.target.value.trimStart())}
                    onBlur={(e) => onCouponCodeChange(e.target.value.trimStart())}
                    style={{ maxWidth: "150px" }}
                  />
                </div>
              </div>
            </div>
            <div className="separator separator-dashed my-5" />
            <span className="text-dark-75 text-hover-primary font-size-h5 font-weight-bold">
              {t("configurator:notice")}
            </span>
            <br />
            <br />
            <span className="font-size-lg font-weight-boldest text-break">
              <em>{t("configurator:noticeText")}</em>
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            style={modalState !== 0 ? {} : { display: "None" }}
            onClick={handleBack}
          >
            {t("configurator:back")}
          </button>
          <button
            className={`btn ${isComplete ? "btn-primary" : "btn-danger"} px-10`}
            style={getButtonStyle(!showOverview)}
            disabled={!isComplete}
            onClick={handleNext}
          >
            {t("configurator:next")}
          </button>
          <button
            className={`btn ${isComplete ? "btn-success" : "btn-danger"}`}
            style={getButtonStyle(showOverview)}
            disabled={!isComplete}
            onClick={() => {
              onSendRequest();
              handleClose();
            }}
          >
            {requestComplete ? t("configurator:confirm") : t("configurator:requestIncomplete")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default withTranslation(["configurator", "packaging"])(ConfigurationConfirmation);
