import countryList from "i18n-iso-countries";
import DOMPurify from "dompurify";
import _ from "lodash";
import * as React from "react";
import { useState } from "react";
import { Form, Image, Modal, Nav, OverlayTrigger, Tab, TabContainer, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../utils/helper";
import languageUtils, { language } from "../../utils/languageUtils";
import orderUtils from "../../utils/orderUtils";
import configuratorUtils from "../../utils/configuratorUtils";
import ConfiguratorHelpers from "./ConfiguratorHelpers";
import { ColorsDocument } from "../../model/colors.types";
import config from "../../configs/config.json";
// register languages
countryList.registerLocale(require("i18n-iso-countries/langs/de.json"));
countryList.registerLocale(require("i18n-iso-countries/langs/en.json"));

interface Props {
  type: string;
  commodity: any;
  onRecipeItem: any;
  t: (key: string, options?: any) => string;
  onRecipeAmountChange?: any;
  disabled?: boolean;
  color?: ColorsDocument;
}

export class RecipeItem extends React.PureComponent<Props> {
  render() {
    const { t, type, onRecipeItem, commodity, color, disabled, onRecipeAmountChange } = this.props;
    const title = languageUtils.resolveTranslation(commodity.title);
    const subtitle = languageUtils.resolveTranslation(commodity.subtitle).replaceAll(/<br>|<br\/>|<br \/>/g, " ");
    return (
      <div
        className="custom-item"
        id={commodity._id.toString() + "_" + type}
        style={(disabled && { opacity: "0.3" }) || {}}
      >
        <div className="row">
          <div className="col-lg-2 mr-md-2 mr-xl-0 my-auto">
            <Image
              className="d-none d-lg-inline-block  max-h-60px max-w-60px my-auto"
              src={
                color
                  ? config.mediahubBase.concat(color.image)
                  : toAbsoluteUrl("/media/products/" + orderUtils.pictureProductMatching(commodity.form.en))
              }
              alt=""
            />
          </div>
          <div className="col-10 col-lg-8">
            <div className="pb-2">
              {commodity.amount && (
                <span className="font-weight-boldest" id="amountSpan">
                  {configuratorUtils.formatAmount(commodity.amount, 2)}{" "}
                </span>
              )}
              <span className="font-weight-bold">{title}</span>
              {type !== "add" && (
                <div className="d-inline-flex align-middle">
                  <AddModal
                    onRecipeItem={onRecipeAmountChange}
                    t={t}
                    commodity={commodity}
                    disabled={disabled}
                    edit={true}
                  />
                </div>
              )}
              <br />
              <span
                className="font-weight-light"
                title={subtitle && (subtitle.length < 75 ? "" : subtitle)}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    subtitle && (subtitle.length < 75 ? subtitle : subtitle.slice(0, 70) + "..."),
                    { USE_PROFILES: { html: true } }
                  ),
                }}
              />
              {subtitle && <br />}
            </div>
            <div className="col-10 col-lg-8 px-0" style={{ maxWidth: "95%" }}>
              {commodity.country && (
                <span className="d-inline-flex mb-1 mr-1 align-bottom">
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="recipeItemCountryIcon">{countryList.getName(commodity.country, language())}</Tooltip>
                    }
                  >
                    <Image
                      className="country-icon country-icon-lg"
                      src={"media/countries/" + commodity.country.toLowerCase() + ".png"}
                      alt={commodity.country}
                    />
                  </OverlayTrigger>
                </span>
              )}
              {commodity.properties.map((prop: any, index: number) => {
                return (
                  <span
                    key={index.toString()}
                    className="label label-light-info font-weight-bolder label-inline mb-1 mr-1"
                  >
                    {languageUtils.resolveTranslation(prop)}
                  </span>
                );
              })}
              {commodity.category && languageUtils.resolveTranslation(commodity.category).length > 0 && (
                <span className="label label-light-info font-weight-bolder label-inline mb-1 mr-1">
                  {languageUtils.resolveTranslation(commodity.category)}
                </span>
              )}
              {ConfiguratorHelpers.renderPriceEstimation(commodity.pricing, t)}
              {commodity.stock && (
                <>
                  <span className="label label-light-primary font-weight-bolder label-inline mb-1 mr-1">
                    {t("inStock")}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className=" col-1 my-auto mx-auto">
            {type === "add" ? (
              <AddModal onRecipeItem={onRecipeItem} t={t} commodity={commodity} disabled={disabled} />
            ) : (
              <button
                className="btn btn-light-danger btn-icon btn-lg"
                name={type}
                style={{ float: "right" }}
                onClick={() => onRecipeItem(commodity.ident)}
              >
                <i className="las la-trash icon-2x" />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

interface AddModalProps {
  onRecipeItem: any;
  commodity: any;
  t: (key: string, options?: any) => string;
  disabled?: boolean;
  edit?: boolean;
}

export const AddModal: React.FunctionComponent<AddModalProps> = React.memo((props) => {
  const { t, commodity, disabled, edit } = props;
  const [show, setShow] = useState(false);
  const [oldAmount, oldUnit] =
    "amount" in commodity ? configuratorUtils.formatAmount(commodity.amount.toString()).split(" ") : [null, null];
  const [amount, setAmount] = useState(oldAmount ? oldAmount : "0");
  const [substanceAmount, setSubstanceAmount] = useState("0");
  const [unit, setUnit] = useState(oldUnit ? oldUnit : "mg");
  const [tab, setTab] = useState("basic");
  const [selectedSubstance, setSelectedSubstance] = useState(
    commodity.activesubstance.length > 0 ? commodity.activesubstance[0] : null
  );

  const handleClose = () => {
    setShow(false);
    // Reset amount
    handleTabChange("basic");
  };
  const handleShow = () => setShow(true);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove leading zeros
    let value = e.target.value.replaceAll(/^0+/g, "0");
    if (!value.includes(".")) value = Number(value).toString();
    setAmount(value);
  };
  const handleUnitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setUnit(e.currentTarget.value);
  };

  const handleTabChange = (newTab: string) => {
    if (tab === newTab) return;
    setSubstanceAmount("0");
    setAmount("0");
    setUnit("mg");
    setTab(newTab);
  };

  const handleSubstanceSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let subStance = commodity.activesubstance.find((c: any) => c._id.toString() === e.currentTarget.value);
    setSelectedSubstance(subStance);
    setAmount("0");
    setSubstanceAmount("0");
  };

  const handleSubstanceAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let percentagePerMg = selectedSubstance.value;
    let desiredValue = Number(e.target.value);
    let amount = (desiredValue * 100) / percentagePerMg;
    // Convert substance amount
    let substanceAmount = e.target.value.replaceAll(/^0+/g, "0");
    if (!substanceAmount.includes(".")) substanceAmount = Number(substanceAmount).toString();
    setSubstanceAmount(substanceAmount);
    setAmount(amount.toString());
  };

  let style: any = { float: "right" };
  // Add not allowed cursor if disabled
  if (disabled) style.cursor = "not-allowed";

  const renderTab = (key: string) => {
    return (
      <li key={key} className="nav-item mx-0" data-toggle="tab" style={{ minWidth: "120px" }}>
        <Nav.Link
          id={key}
          eventKey={key}
          onClick={(e: any) => {
            e.preventDefault();
            handleTabChange(key);
          }}
          active={tab === key}
          className={"p-3 rounded-0 rounded-bottom " + (tab === key && " active")}
          disabled={key === "advanced" && commodity.activesubstance.length === 0}
        >
          <div className="d-md-flex flex-grow-1 flex-md-column text-center">
            <span className="nav-text font-size-lg font-weight-bold ">{t(key)}</span>
          </div>
        </Nav.Link>
      </li>
    );
  };

  return (
    <>
      {edit ? (
        <OverlayTrigger placement="auto" overlay={<Tooltip id="editAmount">{t("editAmount")}</Tooltip>}>
          <button
            className="btn btn-icon btn-sm  text-hover-dark p-0 justify-content-start"
            name="edit"
            onClick={handleShow}
            disabled={disabled}
          >
            <i className="la la-pencil" style={{ color: "#828282" }} />
          </button>
        </OverlayTrigger>
      ) : (
        <button
          className={"btn btn-light btn-icon btn-lg"}
          name="add"
          style={style}
          onClick={handleShow}
          disabled={disabled}
        >
          <i className="las la-plus" />
        </button>
      )}
      <Modal className="configurator-wrapper" show={show} onHide={handleClose} centered id={"commodityModal"}>
        <Modal.Header closeButton>
          <Modal.Title>{edit ? t("editCommodity") : t("addCommodity")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-0 pt-0">
          <TabContainer id="modalAmountTabs" activeKey={tab}>
            <ul className="nav nav-pills nav-light" role="tablist">
              {["basic", "advanced"].map((key) => renderTab(key))}
            </ul>
            <div className="pt-9 pb-2">
              <Tab.Content>
                <Tab.Pane eventKey="basic">
                  <div className="row px-7">
                    <div className="col input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text" style={{ height: "calc(1.5em + .75rem + 2px)" }}>
                          {t("amount")}
                        </span>
                      </div>
                      <Form.Control name="amount" type="number" min="0" value={amount} onChange={handleChange} />
                      <div className="input-group-append">
                        <select
                          className="form-control p-0 pl-2 rounded-left-0"
                          name="units"
                          id="units"
                          onChange={handleUnitChange}
                          value={unit}
                        >
                          <option value="kg">kg</option>
                          <option value="g">g</option>
                          <option value="mg">mg</option>
                          <option value="ug">&#181;g</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>

                {commodity.activesubstance.length > 0 && (
                  <Tab.Pane eventKey="advanced">
                    <div>
                      <div className="form-group mb-15">
                        <div className="input-group px-7">
                          <div className="pr-3 my-auto">
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="addCommodityInfo">{t("addCommodityInfo")}</Tooltip>}
                            >
                              <i className="fa fa-info-circle" />
                            </OverlayTrigger>
                          </div>
                          <div className="input-group-prepend">
                            <select
                              className="form-control rounded-right-0"
                              name="activesubstance"
                              id="activesubstance"
                              value={selectedSubstance._id.toString()}
                              disabled={commodity.activesubstance.length === 1}
                              onChange={handleSubstanceSelect}
                            >
                              {commodity.activesubstance.map((activeSub: any) => (
                                <option key={activeSub._id.toString()} value={activeSub._id.toString()}>
                                  {_.upperFirst(languageUtils.resolveTranslation(activeSub.name))}
                                </option>
                              ))}
                            </select>
                          </div>
                          <Form.Control
                            id="substanceAmount"
                            type="number"
                            min="0"
                            value={substanceAmount}
                            onChange={handleSubstanceAmountChange}
                          />
                          <div className="input-group-append">
                            <select
                              className="form-control p-0 pl-2 rounded-left-0"
                              name="substanceUnits"
                              id="substanceUnits"
                              onChange={handleUnitChange}
                              value={unit}
                            >
                              <option value="kg">kg</option>
                              <option value="g">g</option>
                              <option value="mg">mg</option>
                              <option value="ug">&#181;g</option>
                            </select>
                          </div>
                        </div>
                        <div>
                          <span className="px-7 text-dark-50 font-size-lg d-inline-block">
                            {t("commodityContains", {
                              percent: selectedSubstance.value,
                              name: languageUtils.resolveTranslation(selectedSubstance.name),
                            })}
                          </span>
                        </div>
                      </div>

                      <div className="pr-7">
                        <h5 className="text-right mb-0">
                          {t("requiredAmount") + configuratorUtils.convertAndFormatAmount(amount, unit, 2)}
                        </h5>
                      </div>
                    </div>
                  </Tab.Pane>
                )}
              </Tab.Content>
            </div>
          </TabContainer>
        </Modal.Body>
        <Modal.Footer>
          {edit ? (
            <button
              className="btn btn-primary"
              disabled={amount === "" || parseFloat(amount) === 0}
              onClick={() => {
                props.onRecipeItem(commodity._id.toString(), +configuratorUtils.convertAmount(amount, unit, "mg"));
                handleClose();
              }}
            >
              {t("edit")}
            </button>
          ) : (
            <button
              className="btn btn-primary"
              disabled={amount === "" || parseFloat(amount) === 0}
              onClick={() => {
                props.onRecipeItem(commodity, +configuratorUtils.convertAmount(amount, unit, "mg"));
                handleClose();
              }}
            >
              {t("add")}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
});
