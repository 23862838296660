import _ from "lodash";
import { ObjectId } from "bson";
import * as React from "react";
import { useTranslation } from "react-i18next";
import configuratorUtils from "../../utils/configuratorUtils";
import languageUtils from "../../utils/languageUtils";
import { CAPSULES_TAB, CUSTOM_TAB, SOFTGELS_TAB, TABLETS_TAB } from "./ConfiguratorTabs";
import dbService from "../../services/dbService";
import { CustomCommoditiesDocument } from "./CustomTypes";
import { CommoditypropertiesDocument } from "../../model/commodityproperties.types";
import { CommoditycategoriesDocument } from "../../model/commoditycategories.types";
import { CompositionsDocument } from "../../model/compositions.types";
import { ContractData } from "../../model/requests.types";
import { ActivesubstancesDocument } from "../../model/activesubstances.types";

//
//  LOAD DATA FUNCTIONS
//  (sorted by the order the functions are called from Configurator.componentDidMount as a start point)
//
/**
 * Load all data and prepare the state object
 * @param tab
 * @returns a fully prepared object to be loaded into configurator state
 */
async function loadConfiguratorState(tab: string): Promise<any> {
  // flat to indicate whether a existing request was loaded or not
  const packs = dbService.getPackaging();
  const caps = dbService.getCapsules();
  const comms = loadCommodities();
  const cols = dbService.getColors();
  const tabs = dbService.getTablets();
  const packagings = await packs;
  const capsules = await caps;
  const commodities = await comms;
  const tablets = await tabs;
  const colors = await cols;
  // Sort capsules
  capsules.sort(sortCapsules);

  const state: any = {
    packaging: packagings,
    capsules: capsules,
    commodities: commodities,
    tablets: tablets,
    colors: colors,
  };
  // Add default preferences or existing values
  const defaultPreferences = getDefaultPreferences(tab);
  if (capsules.length > 0) defaultPreferences.selectedCapsule = capsules[0];
  if (tablets.length > 0) defaultPreferences.selectedTablet = tablets[0];
  state.preferences = defaultPreferences;

  return state;
}

/**
 * Load and transform all commodities with information like properties, categories and composition
 * @returns transformed and extended commodities
 */
async function loadCommodities() {
  let extendedCommodities = [];
  const comms = dbService.getCommodities();
  const commProps = dbService.getCommodityProperties();
  const commCats = dbService.getCommodityCategories();
  const comps = dbService.getCompositions();
  const actSubs = dbService.getActiveSubstances();
  const commodities: Array<CustomCommoditiesDocument> = await comms;
  const commodityProperties: Array<CommoditypropertiesDocument> = await commProps;
  const commodityCategories: Array<CommoditycategoriesDocument> = await commCats;
  const compositions: Array<CompositionsDocument> = await comps;
  const activeSubstances: Array<ActivesubstancesDocument> = await actSubs;
  for (let commodity of commodities) {
    let tempCommodity = {
      // prettier-ignore
      ..._.pick(commodity, ["_id", "activesubstance", "title", "subtitle", "stock", "suppliers", "pricing", "type", "disabled", "density", "toxic_amount", "color", "country", "internal_code"]),
    };
    const tempForm = configuratorUtils.getDocFromCollection(compositions, commodity.form);
    if (tempForm) _.set(tempCommodity, "form", tempForm.name);
    else _.set(tempCommodity, "form", commodity.form);
    // prettier-ignore
    const tempCat = configuratorUtils.getDocFromCollection(commodityCategories, commodity.category);
    if (tempCat) _.set(tempCommodity, "category", tempCat.name);
    else _.set(tempCommodity, "category", commodity.category);

    const tempProps = [];
    for (let comProp of commodity.properties) {
      const tempProp = configuratorUtils.getDocFromCollection(commodityProperties, comProp);
      if (tempProp) tempProps.push(tempProp.name);
    }
    _.set(tempCommodity, "properties", tempProps);

    const tempSubstances = [];
    for (let activeSub of commodity.activesubstance) {
      const activeSubDoc = configuratorUtils.getDocFromCollection(activeSubstances, activeSub._id);
      const tmpActiveSub = {
        ...activeSubDoc,
        value: activeSub.value,
      };
      if (tmpActiveSub) tempSubstances.push(tmpActiveSub);
    }
    _.set(tempCommodity, "activesubstance", tempSubstances);
    extendedCommodities.push(tempCommodity);
  }
  return extendedCommodities;
}

//
// HELPER FUNCTIONS
//

/**
 * Returns default preferences object for configurator
 * @param activeTab tab as string
 * @returns Object with default preferences for configurator state
 */
function getDefaultPreferences(activeTab: string): any {
  let amountPerUnit = "0";
  switch (activeTab) {
    case CAPSULES_TAB:
      amountPerUnit = "120";
      break;
    case TABLETS_TAB:
      amountPerUnit = "365";
      break;
    case SOFTGELS_TAB:
      amountPerUnit = "365";
      break;
    case CUSTOM_TAB:
      amountPerUnit = "1";
  }
  return {
    amountPerUnit: amountPerUnit,
    title: "Customer product",
    subtitle: "",
  };
}

/**
 * Returns default contract data list
 * @returns List with default contract data objects
 */
function getDefaultContractData(): Array<ContractData> {
  const currentDate = new Date();
  return [1, 2, 3].map((x) => {
    const tmpDate = new Date(currentDate);
    return { id: new ObjectId(), date: new Date(tmpDate.setMonth(currentDate.getMonth() + x)), value: 1000 };
  }) as Array<ContractData>;
}

/**
 * Sort capsules
 * @param a one capsule object
 * @param b another capsule object
 * @returns result of loacle compare
 */
function sortCapsules(a: any, b: any) {
  return `${a.capsule_size}${languageUtils.resolveTranslation(a.capsule_material)}`.localeCompare(
    `${b.capsule_size}${languageUtils.resolveTranslation(b.capsule_material)}`
  );
}

/**
 * Render price estimation tag
 * @param pricing the pricing category high medium or low
 * @param t tanslation function
 * @param additionalClasses additional class names for the span
 * @returns a pricing label
 */
function renderPriceEstimation(pricing: string, t: (key: string, options?: any) => string, additionalClasses?: string) {
  switch (pricing) {
    case "high":
      return (
        <span
          className={`label label-light-danger font-weight-bolder label-inline mb-1 mr-1 ${
            additionalClasses ? additionalClasses : ""
          }`}
        >
          {t("highPrice")}
        </span>
      );
    case "low":
      return (
        <span
          className={`label label-light-primary font-weight-bolder label-inline mb-1 mr-1 ${
            additionalClasses ? additionalClasses : ""
          }`}
        >
          {t("lowPrice")}
        </span>
      );
    case "medium":
      return (
        <span
          className={`label label-light-success font-weight-bolder label-inline mb-1 mr-1 ${
            additionalClasses ? additionalClasses : ""
          }`}
        >
          {t("mediumPrice")}
        </span>
      );
  }
}

const ShortOverview: React.FunctionComponent<{
  tab: string;
  preferences: any;
  recipe: any;
  selectedPackaging: any;
  calculations: Array<string>;
  contactDetails: {
    phone: string;
    email: string;
    companyName: string;
    street: string;
    zip: string;
    city: string;
    country: string;
  };
}> = ({ tab, preferences, recipe, selectedPackaging, calculations, contactDetails }) => {
  const { t } = useTranslation(["configurator", "packaging"]);
  const prefDesc = configuratorUtils.getPreferencesDescription(preferences, tab, t);
  const { email, phone, companyName, street, zip, city, country } = contactDetails;

  return (
    <div>
      <div className="row pb-3">
        <div className="col-4 font-size-lg">{t("configurator:title")}</div>
        <div className="col-8 font-size-lg font-weight-bolder">
          <span className="text-break">{prefDesc[0]}</span>
        </div>
      </div>
      {prefDesc[1] !== "" && (
        <div className="row pb-3">
          <div className="col-4 font-size-lg">{t("configurator:description")}</div>
          <div className="col-8 font-size-lg font-weight-bolder">
            <span className="text-break">{prefDesc[1]}</span>
          </div>
        </div>
      )}
      {![CUSTOM_TAB, SOFTGELS_TAB].includes(tab) ? (
        <>
          {prefDesc[2] !== "" && (
            <div className="row pb-3">
              <div className="col-4 font-size-lg">{t("configurator:" + tab)} </div>
              <div className="col-8 font-size-lg font-weight-bolder">
                <span className="text-break">{prefDesc[2]}</span>
              </div>
            </div>
          )}
          {recipe && recipe.length !== 0 && (
            <div className="row pb-3">
              <div className="col-4 font-size-lg">{t("configurator:recipe")}</div>
              <div className="col-8 font-size-lg font-weight-bolder">
                {recipe.map((rec: any) => {
                  return (
                    <div key={rec._id}>
                      <span className="text-break">{`${configuratorUtils.formatAmount(
                        rec.amount,
                        2
                      )} - ${languageUtils.resolveTranslation(rec.title)}`}</span>
                      <br />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {prefDesc[2] !== "" && recipe.length === 1 && (
            <div className="row pb-3">
              <div className="col-4 font-size-lg">{t("configurator:productSelection")}</div>
              <div className="col-8 font-size-lg font-weight-bolder">
                <span className="text-break">{`${prefDesc[2]}, ${languageUtils.resolveTranslation(
                  recipe[0].title
                )}`}</span>
              </div>
            </div>
          )}
        </>
      )}
      {selectedPackaging && selectedPackaging.length !== 0 && (
        <div className="row pb-3">
          <div className="col-4 font-size-lg">{t("configurator:packaging")}</div>
          <div className="col-8 font-size-lg font-weight-bolder">
            {selectedPackaging.map((pack: any) => {
              return (
                <div key={pack._id}>
                  <span className="text-break">{`${pack.amount} x ${configuratorUtils.concatPackagingInfo(
                    pack,
                    t,
                    ", "
                  )}`}</span>
                  <br />
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="row pb-3">
        <div className="col-4 font-size-lg">{t("configurator:requestedCalculations")}</div>
        <div className="col-8 font-size-lg font-weight-bolder">
          <span className="text-break">
            {calculations
              .concat()
              .sort()
              .filter((cal) => parseInt(cal) !== 0)
              .join(", ") + ` ${t("configurator:units")}`}
          </span>
        </div>
      </div>
      <div className="row pb-3">
        <div className="col-4 font-size-lg">{t("companyName")}</div>
        <div className="col-8 font-size-lg font-weight-bolder">{companyName}</div>
      </div>
      <div className="row pb-3">
        <div className="col-4 font-size-lg">{t("address")}</div>
        <div className="col-8 font-size-lg font-weight-bolder">{`${street}, ${zip} ${city}, ${country}`}</div>
      </div>
      <div className="row pb-3">
        <div className="col-4 font-size-lg">{t("email")}</div>
        <div className="col-8 font-size-lg font-weight-bolder">{email}</div>
      </div>
      {phone.trim() !== "" && (
        <div className="row pb-3">
          <div className="col-4 font-size-lg">{t("phone")}</div>
          <div className="col-8 font-size-lg font-weight-bolder">{phone}</div>
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line
export default {
  loadConfiguratorState,
  getDefaultPreferences,
  getDefaultContractData,
  renderPriceEstimation,
  ShortOverview,
};
