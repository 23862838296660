import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import { withTranslation } from "react-i18next";

class Footer extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <footer className="footer-dark bg-extra-dark-gray">
        <div className="footer-top padding-five-tb lg-padding-eight-tb md-padding-50px-tb">
          <div className="container">
            <div className="row">
              {/* start footer column */}
              <div className="col-12 col-lg-3 col-sm-6 md-margin-40px-bottom xs-margin-25px-bottom">
                <span className="alt-font font-weight-500 d-block text-white margin-20px-bottom xs-margin-10px-bottom">
                  {t("title30")}
                </span>
                <ul>
                  <li>
                    <a href="/"> {t("title23")}</a>
                  </li>
                  <li>
                    <a href="/team">{t("title24")}</a>
                  </li>
                  <li>
                    <a href="/production">{t("title25")}</a>
                  </li>
                  <li>
                    <a href="/certifications">{t("title26")}</a>
                  </li>{" "}
                  <li>
                    <a href="/portfolio">{t("title27")}</a>
                  </li>
                  <li>
                    <a href="/configurator">{t("title28")}</a>
                  </li>
                </ul>
              </div>
              {/* end footer column */}
              {/* start footer column */}
              <div className="col-12 col-lg-3 col-sm-6 md-margin-40px-bottom xs-margin-25px-bottom">
                <span className="alt-font font-weight-500 d-block text-white margin-20px-bottom xs-margin-10px-bottom">
                  &nbsp;
                </span>
                <ul>
                  <li>
                    <a href="/privacy">{t("title31")}</a>
                  </li>
                  <li>
                    <a href="/imprint">{t("title32")}</a>
                  </li>{" "}
                  {/*  <li>
                    <a href="/agb">Geschäftsbedingungen</a>
                  </li>{" "} */}
                  <li>
                    <a href="/avb">{t("title33")}</a>
                  </li>{" "}
                  <li>
                    <a href="/aeb">{t("title34")}</a>
                  </li>
                </ul>
              </div>
              {/* end footer column */}
              {/* start footer column */}
              <div className="col-12 col-lg-3 col-sm-6 xs-margin-25px-bottom">
                <span className="alt-font font-weight-500 d-block text-white margin-20px-bottom xs-margin-10px-bottom">
                  {t("title35")}
                </span>

                <div>
                  <i className="feather icon-feather-phone-call icon-very-small margin-10px-right text-white" />
                  +49 (0)209 51956620
                </div>
                <div>
                  <i className="feather icon-feather-mail icon-very-small margin-10px-right text-white" />
                  <a href="mailto:info@private-label-factory.com">info@private-label-factory.com</a>
                </div><br/>
                <span className="alt-font font-weight-500 d-block text-white margin-20px-bottom xs-margin-10px-bottom">
                  {t("title36")}
                </span>
                <p className="w-85 margin-15px-bottom">
                  Private Label Factory Deutschland GmbH, Philipp-Reis-Straße 7, 45659 Recklinghausen, Germany.
                </p>
              </div>
              <div className="col-12 col-lg-3 col-sm-6 xs-margin-25px-bottom">
                <img src="/img/mail/adz.png" style={{ height: 250}}/>
              </div>
              {/* end footer column */}
              {/* start footer column */}

              {/* end footer column */}
            </div>
          </div>
        </div>
        <div className="footer-bottom padding-40px-tb border-top border-color-white-transparent">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-3 text-center text-md-left sm-margin-20px-bottom">
                <a href="index.html" className="footer-logo">
                  {/*  <img
                    src="images/logo-white.png"
                    data-at2x="images/logo-white@2x.png"
                    alt=""
                  /> */}
                  <ReactSVG src="images/logo-light.svg" style={{ width: "160px" }} fill="white" />
                </a>
              </div>
              <div className="col-12 col-md-6 text-center last-paragraph-no-margin sm-margin-20px-bottom">
                <p>© {new Date().getFullYear()} Private Label Factory Deutschland GmbH </p>
              </div>
              <div className="col-12 col-md-3 text-center text-md-right">
                <div className="social-icon-style-12">
                  <ul className="extra-small-icon light">
                    <li>
                      <a
                        className="xing"
                        href="https://www.xing.com/pages/privatelabelfactorydeutschlandgmbh"
                        target="_blank"
                      >
                        <i className="fab fa-xing" />
                      </a>
                    </li>
                    <li>
                      <a className="linkedin" href="https://www.linkedin.com/company/37827432/" target="_blank">
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default withTranslation("general")(Footer);
