import React, { Component } from "react";
import { CapsulesDocument } from "../../model/capsules.types";
import configuratorUtils from "../../utils/configuratorUtils";

interface CapsuleSelectionProps {
  capsules: Array<CapsulesDocument>;
  t: (key: string, options?: any) => string;
  preferences: any;
  onPreferenceChange: (path: string, item: any) => void;
}

class CapsuleSelection extends Component<CapsuleSelectionProps> {
  shouldComponentUpdate(
    nextProps: Readonly<CapsuleSelectionProps>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    return JSON.stringify(this.props.preferences) !== JSON.stringify(nextProps.preferences);
  }

  renderCapsuleOptions() {
    const { capsules } = this.props;
    return (
      <>
        {capsules.map(capsule => {
          return (
            <option
              key={capsule.capsule_size + capsule.capsule_material.en + capsule.capsule_color.en}
              value={capsule._id}
            >
              {configuratorUtils.buildCapsuleString(capsule)}
            </option>
          );
        })}
      </>
    );
  }

  render() {
    const { t, onPreferenceChange, capsules, preferences } = this.props;
    return (
      <>
        <span className="text-dark-75 font-size-h3 font-weight-bold mr-3">{t("preferences")}</span>
        <div className="form-group row mt-10" id="capsuleSelection">
          <div className="col-lg-4">
            <label><b>{t("capsuleType")}</b></label>
            <select
              className="form-control"
              style={{backgroundColor: "white"}}
              id="selectCapsule"
              value={preferences.selectedCapsule ? preferences.selectedCapsule._id : ""}
              onChange={e => {
                onPreferenceChange(
                  "selectedCapsule",
                  capsules.find(cap => cap._id.toString() === e.target.value.toString())
                );
              }}
            >
              {this.renderCapsuleOptions()}
            </select>
          </div>
          <div className="col-lg-4">
            <label><b>{t("perUnit")}</b></label>
            <div className="input-group">
              <input
                type="number"
                id="perUnit"
                min="0"
                className="form-control"
                value={preferences.amountPerUnit}
                onChange={e => {
                  onPreferenceChange("amountPerUnit", Number(parseInt(e.target.value) || "0").toString());
                }}
              />
              <div className="input-group-append">
                <span className="input-group-text">{t("capsules")}</span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CapsuleSelection;
