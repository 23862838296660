import i18next from "i18next";

export const language = () => i18next.language.slice(0, 2) || window.localStorage.i18nextLng.slice(0, 2);

export const fullLanguage = () => i18next.language || window.localStorage.i18nextLng;

/**
 * Resolve the translation of a database object.
 * @param translations: Object containing the different translations (like DE, EN etc.)
 * @returns Translated string.
 */
function resolveTranslation(translations: any) {
  return translations[language()] ? translations[language()] : translations.de;
}

/**
 * Resolve the flag of the language based on the currently selected one.
 * @returns Path to the language flag
 */
function resolveLanguageFlag() {
  if (language() === "de") {
    return "/media/svg/flags/017-germany.svg";
  }
  if (language() === "en") {
    return "/media/svg/flags/226-us.svg";
  } else {
    return "/media/svg/flags/017-germany.svg";
  }
}

// eslint-disable-next-line
export default { resolveLanguageFlag, resolveTranslation };
