import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class FinalSummary extends Component {
  state = {};

  render() {
    const { t } = this.props;
    return (
      <section className="big-section bg-white">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-12 col-xl-6 col-md-6 sm-margin-30px-bottom wow animate__fadeIn"
              data-wow-delay="0.1s"
              style={{
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeIn",
              }}
            >
              <h3 className="alt-font font-weight-600 text-extra-dark-gray mb-0">{t("title46")}</h3>
            </div>
            <div
              className="col-12 col-xl-6  col-md-6 wow animate__fadeIn"
              data-wow-delay="0.2s"
              style={{
                visibility: "visible",
                animationDelay: "0.2s",
                animationName: "fadeIn",
              }}
            >
              <p className="text-dark font-size-lg">{t("text31")}</p>
              <a
                href="/configurator"
                className="btn btn-fancy btn-small btn-dark-gray margin-35px-right sm-margin-15px-right"
              >
                {t("button1")}
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation("general")(FinalSummary);
